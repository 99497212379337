import React, { useState } from "react";
import { Card, Button } from "react-bootstrap";
import { motion } from "framer-motion";

const ArtistBioCard = ({ artist }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Handler to toggle expanded state
  const handleToggle = () => setIsExpanded(!isExpanded);

  // Safety checks for artist data
  const artistName = artist?.[0]?.Artist || "Unknown Artist";
  const artistDescription =
    artist?.[0]?.bibliography || "Description not available.";

  // Animation variants
  const variants = {
    hidden: { opacity: 0, height: 0, scale: 0.95 },
    visible: { opacity: 1, height: "auto", scale: 1 },
  };

  return (
    <Card
      className="bg-appColor7 shadow-sm d-flex align-items-center justify-content-center"
      style={{ margin: "10px" }}
    >
      <Card.Body className="d-flex flex-column align-items-center justify-content-center">
        <Card.Title as={"h3"} className="text-appColor1 mb-3">
          {artistName} <span className="text-black">Biography</span>
        </Card.Title>
        <motion.div
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={variants}
          transition={{ duration: 0.5 }}
          style={{
            overflow: "hidden",
            whiteSpace: "pre-wrap",
            textOverflow: "ellipsis",
          }}
        >
          <Card.Text
            style={{
              maxHeight: isExpanded ? "none" : "4.5em",
              overflow: "hidden",
            }}
          >
            {artistDescription}
          </Card.Text>
        </motion.div>
        <Button
          variant="link"
          onClick={handleToggle}
          className="p-0 mt-2"
          style={{ fontSize: "14px" }}
        >
          {isExpanded ? "Read less" : "Read more"}
        </Button>
      </Card.Body>
    </Card>
  );
};

export default ArtistBioCard;
