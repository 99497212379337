// hooks/useErrorBoundary.js
import { useState, useEffect } from "react";
import { toast } from "react-toastify";

const useErrorBoundary = () => {
  const [hasError, setHasError] = useState(false);

  const handleError = (error, errorInfo) => {
    // You can log the error to an external service here
    console.log("Error caught in useErrorBoundary:", error, errorInfo);
    setHasError(true);
    toast.error("Something Went Wrong");
  };

  return { hasError, handleError };
};

export default useErrorBoundary;
