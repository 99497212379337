import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { makeRequest } from "../../services/apiRequests";
import { handleApiError } from "../../services/apiUtils";

/* Testimonial Section */
const getTestimonialList = createAsyncThunk(
  "testimonial/getTestimonialList",
  async ({ payload, config, resetForm, navigate }, { rejectWithValue }) => {
    try {
      const { data, status } = await makeRequest(
        "GET",
        "/user/get-testimonial",
        config
      );
      if (status === 200) {
        // toast.success(data.message);
        return data.data;
      } else {
        toast.error(data.message);
        return rejectWithValue(data.message);
      }
    } catch (error) {
      const errorMessage = handleApiError(error);
      //   toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

const testimonialSlice = createSlice({
  name: "testimonial",
  initialState: {
    testimonialList: null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      /* Get Testimonial List */
      .addCase(getTestimonialList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getTestimonialList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.testimonialList = action.payload;
      })
      .addCase(getTestimonialList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const {} = testimonialSlice.actions;

export const testimonialController = { getTestimonialList };

export default testimonialSlice.reducer;
