// src/routes/commonRoutes.js
import React from "react";
import { Route } from "react-router-dom";
import { ROUTES } from "./constants";

/* Common Pages */
import HomePage from "../pages/common/HomePage/HomePage";
import AboutPage from "../pages/common/AboutPage/AboutPage";
import NewsPage from "../pages/common/NewsPage/NewsPage";
import NewsDetailPage from "../pages/common/NewsDetailPage/NewsDetailPage";
import PressPage from "../pages/common/PressPage/PressPage";
import AwardsPage from "../pages/common/AwardsPage/AwardsPage";
import TestimonialsPage from "../pages/common/TestimonialsPage/TestimonialsPage";
import SubmitLyricsPage from "../pages/common/SubmitLyricsPage/SubmitLyricsPage";
import SoundtracksPage from "../pages/common/SoundtracksPage/SoundtracksPage";
import ContactUsPage from "../pages/common/ContactUsPage/ContactUsPage";
import NotFoundPage from "../pages/common/NotFoundPage/NotFoundPage";
import LyricsPage from "../pages/common/LyricsPage/LyricsPage";
import ArtistsPage from "../pages/common/ArtistsPage/ArtistsPage";
import TermsOfUsePage from "../pages/common/TermsOfUsePage/TermsOfUsePage";
import PrivacyPolicyPage from "../pages/common/PrivacyPolicyPage/PrivacyPolicyPage";
import ArtistsAlbumsPage from "../pages/common/ArtistsAlbumsPage/ArtistsAlbumsPage";
import AlbumsPage from "../pages/common/AlbumsPage/AlbumsPage";
import SearchResultPage from "../pages/common/SearchResultPage/SearchResultPage";
import MaintenancePage from "../pages/common/MaintenancePage/MaintenancePage";

const commonRoutes = [
  <Route key="home" path={ROUTES.COMMON.HOME} element={<HomePage />} />,
  <Route key="home" path={ROUTES.COMMON.HOME_} element={<HomePage />} />,
  <Route key="about" path={ROUTES.COMMON.ABOUT} element={<AboutPage />} />,
  <Route key="news" path={ROUTES.COMMON.NEWS} element={<NewsPage />} />,
  <Route
    key="news-details"
    path={ROUTES.COMMON.NEWS_DETAILS}
    element={<NewsDetailPage />}
  />,
  <Route key="press" path={ROUTES.COMMON.PRESS} element={<PressPage />} />,
  <Route key="awards" path={ROUTES.COMMON.AWARDS} element={<AwardsPage />} />,
  <Route
    key="testimonials"
    path={ROUTES.COMMON.TESTIMONIALS}
    element={<TestimonialsPage />}
  />,
  <Route
    key="submit-lyrics"
    path={ROUTES.COMMON.SUBMIT_LYRICS}
    element={<SubmitLyricsPage />}
  />,
  <Route
    key="soundtracks"
    path={ROUTES.COMMON.SOUNDTRACKS}
    element={<SoundtracksPage />}
  />,
  <Route
    key="contact-us"
    path={ROUTES.COMMON.CONTACT_US}
    element={<ContactUsPage />}
  />,
  <Route
    key="privacy-policy"
    path={ROUTES.COMMON.PRIVACY_POLICY}
    element={<PrivacyPolicyPage />}
  />,
  <Route
    key="terms-of-use"
    path={ROUTES.COMMON.TERMS_OF_USE}
    element={<TermsOfUsePage />}
  />,
  <Route
    key="artists"
    path={ROUTES.COMMON.ARTISTS}
    element={<ArtistsPage />}
  />,
  <Route key="albums" path={ROUTES.COMMON.ALBUMS} element={<AlbumsPage />} />,
  <Route
    key="artists-albums"
    path={ROUTES.COMMON.ARTISTS_ALBUMS}
    element={<ArtistsAlbumsPage />}
  />,
  <Route
    key="lyrics-details"
    path={ROUTES.COMMON.LYRICS_DETAILS}
    element={<LyricsPage />}
  />,
  <Route
    key="search-result"
    path={ROUTES.COMMON.SEARCH_RESULT}
    element={<SearchResultPage />}
  />,
  <Route
    key="maintenance"
    path={ROUTES.COMMON.MAINTENANCE}
    element={<MaintenancePage />}
  />,
  <Route
    key="not-found"
    path={ROUTES.COMMON.NOT_FOUND}
    element={<NotFoundPage />}
  />,
];

export default commonRoutes;
