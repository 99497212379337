import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import styles from "./ErrorPage.module.css";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";

const ErrorPage = ({ error }) => {
  const navigate = useNavigate();

  useEffect(() => {
    console.error("Error occurred:", error);
    const timer = setTimeout(() => {
      navigate("/", { replace: true });
    }, 3000);

    return () => clearTimeout(timer);
  }, [error, navigate]);

  return (
    <div className={styles.errorPage}>
      <p className={styles.errorMsg}>
        Something went wrong. Redirecting to the home page...
      </p>
      {process.env.NODE_ENV === "development" && (
        <div className={styles.errorDetails}>
          <ErrorAlert error={error} />
        </div>
      )}
    </div>
  );
};

ErrorPage.propTypes = {
  error: PropTypes.object.isRequired,
};

export default ErrorPage;
