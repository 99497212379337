// sessionTimeoutService.js

import { handleAuthError } from "./authHelpers";

let logoutTimer;

// timeout = 60 * 1000 (1 minute)
const setLogoutTimer = (logoutCallback, timeout = 60 * 60 * 1000) => {
  clearLogoutTimer();
  logoutTimer = setTimeout(() => {
    logoutCallback();
  }, timeout);
  attachEventListeners();
};

const clearLogoutTimer = () => {
  if (logoutTimer) {
    clearTimeout(logoutTimer);
  }
  removeEventListeners();
};

const resetLogoutTimer = () => {
  clearLogoutTimer();
  setLogoutTimer(logoutCallback);
};

const attachEventListeners = () => {
  document.addEventListener("mousemove", resetLogoutTimer);
  document.addEventListener("keydown", resetLogoutTimer);
  document.addEventListener("click", resetLogoutTimer);
};

const removeEventListeners = () => {
  document.removeEventListener("mousemove", resetLogoutTimer);
  document.removeEventListener("keydown", resetLogoutTimer);
  document.removeEventListener("click", resetLogoutTimer);
};

const logoutCallback = () => {
  // Implement your logout logic here
  console.log("Auto-logout After Inactivity");
  handleAuthError("Session Timeout... Please Sign In Again... ");
};

const sessionTimeoutService = {
  setLogoutTimer,
  clearLogoutTimer,
  logoutCallback,
};

export default sessionTimeoutService;
