import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { Row, Pagination, Col, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import CommonLayout from "../../../layouts/CommonLayout";
import { StaticImages } from "../../../utils/constants";
import PageInfoHeader from "../../../components/PageInfoHeader/PageInfoHeader";
import AppSpinner from "../../../components/Spinners/AppSpinner";
import AddToChromeCard from "../../../components/AppCards/AddToChromeCard";
import SEOManager from "../../../utils/SEOManager";
import { albumController } from "../../../features/album/albumSlice";
import { generateDynamicRoute } from "../../../utils/generateDynamicRoute";
import { ROUTES } from "../../../routes/constants";
import AlbumDetailsCard from "../../../components/AppCards/AlbumDetailsCard ";
import ErrorAlert from "../../../components/ErrorAlert/ErrorAlert";
import { decodeFromUrl, formatForUrl } from "../../../utils/helpers";

export const ads = [
  StaticImages.GoogleAds.Ad1,
  StaticImages.GoogleAds.Ad4,
  <AddToChromeCard />,
  StaticImages.GoogleAds.Ad17,
  StaticImages.GoogleAds.Ad18,
];

export const hotAlbumImages = [
  StaticImages.HotAlbumPlay.Img1,
  StaticImages.HotAlbumPlay.Img2,
  StaticImages.HotAlbumPlay.Img3,
  StaticImages.HotAlbumPlay.Img4,
];

const AlbumsPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { albumDetails } = location.state || {};

  const { albumId, albumName } = useParams();
  const { albumList, isAlbumLoading, error } = useSelector(
    (state) => state.album
  );

  const [currentPage, setCurrentPage] = useState(1);

  const fetchAlbums = async (page) => {
    if (albumId && page > 0) {
      try {
        await dispatch(
          albumController.getAlbumsList({
            payload: { albumId: albumId, page },
            config: {},
          })
        );
      } catch (err) {
        console.error("Error Fetching Albums:", err);
      }
    }
  };

  useEffect(() => {
    fetchAlbums(currentPage);
  }, [albumId, currentPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  const handlePageClick = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const totalPages = albumList?.totalPages || 0;

  const renderPaginationItems = () => {
    const items = [];
    let ellipsisBefore = false;
    let ellipsisAfter = false;

    for (let number = 1; number <= totalPages; number++) {
      if (
        number === 1 ||
        number === totalPages ||
        (number >= currentPage - 2 && number <= currentPage + 2)
      ) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => handlePageClick(number)}
          >
            {number}
          </Pagination.Item>
        );
        ellipsisBefore = ellipsisAfter = false;
      } else if (number < currentPage - 2 && !ellipsisBefore) {
        items.push(<Pagination.Ellipsis key="ellipsisBefore" />);
        ellipsisBefore = true;
      } else if (number > currentPage + 2 && !ellipsisAfter) {
        items.push(<Pagination.Ellipsis key="ellipsisAfter" />);
        ellipsisAfter = true;
      }
    }
    return items;
  };

  const renderArtists = (artists) => {
    return artists.map((artist, index) => (
      <span key={artist.id}>
        {artist.name}
        {index < artists.length - 1 ? ", " : ""}
      </span>
    ));
  };

  return (
    <CommonLayout>
      <SEOManager
        title={`Get Songs & Songs Lyrics from ${albumName} | Lyrics Web`}
        description={`Discover song lyrics by album at LyricsWeb. Browse through a vast collection of music albums, find your favorite songs, and enjoy the lyrics from various artists.`}
        keywords={`lyrics, song lyrics, music albums, artists, latest songs, popular song lyrics, Music lyrics search, Song lyric finder, Song lyrics search, Famous lyrics, Top artists, Lyrics meaning, Best song lyrics, ${decodeFromUrl(
          albumName
        )} songs, songs of ${decodeFromUrl(albumName)}`}
        canonical={`https://www.lyricsweb.com/album/${formatForUrl(
          albumName
        )}/${albumId}`}
        author={`${decodeFromUrl(albumName)}`}
        language="en"
        ogTitle={`Get Songs & Songs Lyrics from ${albumName} | Lyrics Web`}
        ogDescription={`Discover song lyrics by album at LyricsWeb. Browse through a vast collection of music albums, find your favorite songs, and enjoy the lyrics from various artists.`}
        ogImage="/path/to/artist-og-image.png"
        ogUrl={`https://www.lyricsweb.com/artist/${formatForUrl(
          albumName
        )}/${albumId}`}
        ogType="website"
        ogLocale="en_US"
        structuredData={{
          "@context": "https://schema.org",
          "@type": "MusicAlbum",
          name: `${albumName}`,
          byArtist: {
            "@type": "MusicGroup",
            name: "Artist Name",
          },
          datePublished: "2023-01-01", // Modify accordingly
          genre: "Pop", // Modify genre accordingly
          sameAs: `https://www.lyricsweb.com/album/${formatForUrl(
            albumName
          )}/${albumId}`,
          tracks:
            albumList &&
            albumList?.map((album) => ({
              "@type": "MusicRecording",
              name: album?.name || "Unknown Album Name",
              url:
                `https://lyricsweb.com/lyrics/${formatForUrl(album?.name)}/${
                  album?.id
                }` || "https://example.com/default-album-url",
            })),
        }}
        robots="index, follow"
      />

      <div className="my-2">
        <PageInfoHeader
          text={`${decodeFromUrl(albumName)} Songs`}
          imageUrl={StaticImages.Banner.FilterArtist}
        />
      </div>

      <h3 className="fw-bold my-3 text-center">
        Found Songs of{" "}
        <span className={`text-appColor1`}>{decodeFromUrl(albumName)}</span>{" "}
        Album
      </h3>

      <div>
        {isAlbumLoading ? (
          <div
            style={{ minHeight: "50vh" }}
            className="d-flex justify-content-center align-items-center"
          >
            <AppSpinner size={70} />
          </div>
        ) : error ? (
          <div>
            <ErrorAlert error={error} />
          </div>
        ) : albumList?.length > 0 ? (
          <>
            <Row className="m-0 p-0">
              <Col lg={9} md={9} sm={12} xs={12}>
                <div className="mx-3">
                  <div>{albumId && <AlbumDetailsCard albumId={albumId} />}</div>
                  <Table responsive borderless hover className="text-center">
                    <thead>
                      <tr>
                        {" "}
                        {/* <th className="bg-appColor6"></th> */}
                        <th className="bg-appColor6">Song</th>
                        <th className="bg-appColor6">Artist (s)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {albumList &&
                        albumList?.map((entry, index) => (
                          <tr key={index}>
                            {/* <td className="bg-appColor7">
                              <Image
                                src={entry?.images[0]?.url || ""}
                                height={40}
                                width={40}
                                alt="Album Image"
                                rounded
                              />
                            </td> */}
                            <td className="bg-appColor7">
                              <NavLink
                                to={`${generateDynamicRoute(
                                  ROUTES.COMMON.LYRICS_DETAILS,
                                  {
                                    isrcKey: entry?.id,
                                    songName: formatForUrl(entry?.name),
                                  }
                                )}`}
                                className={"text-decoration-none"}
                                // to={`/lyrics/${entry.id}/${entry.name}`}
                              >
                                {entry.name}
                              </NavLink>
                            </td>
                            <td className="bg-appColor7">
                              {renderArtists(entry?.artists)}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
                <div className="d-flex justify-content-center my-4">
                  <Pagination>
                    <Pagination.Prev
                      onClick={() => handlePageClick(currentPage - 1)}
                      disabled={currentPage === 1}
                    />
                    {renderPaginationItems()}
                    <Pagination.Next
                      onClick={() => handlePageClick(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    />
                  </Pagination>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <div className="text-center fw-bolder fs-3 my-4">
            No Albums Found.
          </div>
        )}
      </div>
    </CommonLayout>
  );
};

export default AlbumsPage;
