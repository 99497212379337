import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { setAuthToken } from "../../utils/authHelpers";
import { ROUTES } from "../../routes/constants";
import { makeRequest } from "../../services/apiRequests";
import { handleApiError } from "../../services/apiUtils";

/* Auth Section */
const loginUser = createAsyncThunk(
  "auth/loginUser",
  async ({ payload, config, resetForm, navigate }, { rejectWithValue }) => {
    try {
      const { data, status } = await makeRequest(
        "POST",
        "/user/login-user",
        payload,
        config
      );
      if (status === 200 && data) {
        toast.success(data.message);
        setAuthToken(data.data.token);
        navigate(ROUTES.COMMON.HOME);
        resetForm();
        return data.data;
      } else {
        toast.error(data.message);
        return rejectWithValue(data.message);
      }
    } catch (error) {
      handleApiError(error);
      return rejectWithValue(error.message);
    }
  }
);

const createUser = createAsyncThunk(
  "auth/createUser",
  async ({ payload, config, resetForm, navigate }, { rejectWithValue }) => {
    try {
      const { data, status } = await makeRequest(
        "POST",
        "/user/create-user",
        payload,
        config
      );
      if (status === 201) {
        toast.success(data.message);
        navigate(ROUTES.AUTH.LOGIN);
        resetForm();
        return data.data;
      } else {
        toast.error(data.message);
        return rejectWithValue(data.message);
      }
    } catch (error) {
      handleApiError(error);
      return rejectWithValue(error.message);
    }
  }
);

const verifyUser = createAsyncThunk(
  "auth/verifyUser",
  async ({ payload, config, navigate }, { rejectWithValue }) => {
    try {
      const { data, status } = await makeRequest(
        "PUT",
        "/user/verify-user",
        payload,
        config
      );
      if (status === 200) {
        toast.success(data.message);
        navigate(ROUTES.AUTH.LOGIN);
        return data;
      } else {
        toast.error(data.message);
        return rejectWithValue(data.message);
      }
    } catch (error) {
      handleApiError(error);
      return rejectWithValue(error.message);
    }
  }
);

const forgetPassword = createAsyncThunk(
  "auth/forgetPassword",
  async ({ payload, config, resetForm }, { rejectWithValue }) => {
    try {
      const { data, status } = await makeRequest(
        "POST",
        "/user/forget-password",
        payload,
        config
      );
      if (status === 200) {
        toast.success(data.message);
        resetForm();
        return data.data;
      } else {
        toast.error(data.message);
        return rejectWithValue(data.message);
      }
    } catch (error) {
      handleApiError(error);
      return rejectWithValue(error.message);
    }
  }
);

const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async ({ payload, config, resetForm, navigate }, { rejectWithValue }) => {
    try {
      const { data, status } = await makeRequest(
        "PUT",
        "/user/reset-password",
        payload,
        config
      );
      if (status === 200) {
        toast.success(data.message);
        navigate(ROUTES.AUTH.LOGIN);
        resetForm();
        return data.data;
      } else {
        toast.error(data.message);
        return rejectWithValue(data.message);
      }
    } catch (error) {
      handleApiError(error);
      return rejectWithValue(error.message);
    }
  }
);

const changeUserPassword = createAsyncThunk(
  "auth/changeUserPassword",
  async ({ payload, config, resetForm }, { rejectWithValue }) => {
    try {
      const { data, status } = await makeRequest(
        "PUT",
        "/user/change-user-password",
        payload,
        config
      );
      if (status === 200) {
        toast.success(data.message);
        resetForm();
        return data.data;
      } else {
        toast.error(data.message);
        return rejectWithValue(data.message);
      }
    } catch (error) {
      handleApiError(error);
      return rejectWithValue(error.message);
    }
  }
);

const editUserProfile = createAsyncThunk(
  "auth/editUserProfile",
  async ({ payload, config }, { rejectWithValue }) => {
    try {
      const { data, status } = await makeRequest(
        "PUT",
        "/user/edit-user",
        payload,
        config
      );
      if (status === 200) {
        toast.success(data.message);
        return data;
      } else {
        toast.error(data.message);
        return rejectWithValue(data.message);
      }
    } catch (error) {
      handleApiError(error);
      return rejectWithValue(error.message);
    }
  }
);

const getUserProfile = createAsyncThunk(
  "auth/getUserProfile",
  async ({ config, navigate }, { rejectWithValue }) => {
    try {
      const { data, status } = await makeRequest(
        "GET",
        "/user/user-profile",
        {},
        config
      );
      if (status === 200) {
        return data;
      } else {
        toast.error(data.message);
        return rejectWithValue(data.message);
      }
    } catch (error) {
      handleApiError(error);
      return rejectWithValue(error.message);
    }
  }
);

const uploadProfilePicture = createAsyncThunk(
  "auth/uploadProfilePicture",
  async ({ payload, config }, { rejectWithValue }) => {
    try {
      const { data, status } = await makeRequest(
        "POST",
        "/user/upload-profile-picture",
        payload,
        config
      );
      if (status === 200) {
        toast.success(data.message);
        return data;
      } else {
        toast.error(data.message);
        return rejectWithValue(data.message);
      }
    } catch (error) {
      handleApiError(error);
      return rejectWithValue(error.message);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    isLoading: false,
    error: null,
  },
  reducers: {
    logout: (state) => {
      state.user = null;
      setAuthToken(null);
    },
  },
  extraReducers: (builder) => {
    builder
      /* Login User */
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      /* Create User */
      .addCase(createUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createUser.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      /* Verify User */
      .addCase(verifyUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(verifyUser.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(verifyUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      /* Forget Password */
      .addCase(forgetPassword.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(forgetPassword.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(forgetPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      /* Reset Password */
      .addCase(resetPassword.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      /* Change User Password */
      .addCase(changeUserPassword.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(changeUserPassword.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(changeUserPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      /* User Profile */
      .addCase(getUserProfile.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.user = action.payload;
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      /* Edit User Profile */
      .addCase(editUserProfile.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(editUserProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.user = action.payload;
      })
      .addCase(editUserProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      /* Upload Profile Picture */
      .addCase(uploadProfilePicture.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(uploadProfilePicture.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.user = action.payload;
      })
      .addCase(uploadProfilePicture.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { logout } = authSlice.actions;

export const authController = {
  loginUser,
  createUser,
  verifyUser,
  forgetPassword,
  resetPassword,
  changeUserPassword,
  getUserProfile,
  editUserProfile,
  uploadProfilePicture,
};

export default authSlice.reducer;
