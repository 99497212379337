import React from "react";
import { Card } from "react-bootstrap";
import PropTypes from "prop-types";

import { LyricFindLink, StaticImages } from "../../utils/constants";

function LyricsViewer({ lyricsData }) {
  const { title, artist, lyrics, copyright, writer } = lyricsData;

  const styles = {
    cardStyle: {
      height: "max-content",
      position: "relative",
      color: "black",
      // backgroundColor: "#eff5ff",
      fontFamily: "Courier New",
    },
    cardBg: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundImage: `url(${StaticImages.App.LWStatic})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      opacity: 0.3,
    },
    cardBg2: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundImage: `url(${StaticImages.App.PDFViewerBg1})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      opacity: 0.9,
      pointerEvents: "none",
    },
    lyricsInfo: {
      position: "relative",
      zIndex: 1,
      padding: "0rem",
      textAlign: "center",
    },
    lyricsContainer: {
      padding: "20px 0px",
      borderRadius: "10px",
      fontFamily: "Courier New",
      whiteSpace: "pre-wrap",
      lineHeight: "1.5",
      margin: "20px auto",
      maxWidth: "600px",
    },
    lyricsLine: {
      margin: "0",
    },
  };

  const isContentAvailable =
    title && artist?.name && lyrics && copyright && writer;

  return (
    <Card>
      <Card.Body style={styles.cardStyle} className="pb-0 bg-appColor6">
        <div style={styles.lyricsInfo}>
          {isContentAvailable ? (
            <>
              <h2 className="fw-bold text-appColor1 my-4">{title} Lyrics</h2>
              <h3 className="fw-bold my-4">Artist: {artist.name}</h3>
              <div style={styles.lyricsContainer}>
                {lyrics.split("\n").map((line, index) => (
                  <p key={index} style={styles.lyricsLine}>
                    {line}
                  </p>
                ))}
              </div>
              <Card className="bg-appColor1 text-appColor4 p-4 mx-5 my-0">
                <div>
                  <div className="fw-bold">{copyright}</div>
                  <div className="fw-bold">
                    <span>Written by: </span>
                    {writer}
                  </div>
                  <div className="fw-bold">
                    <span>Lyrics Licensed & Provided by</span>{" "}
                    <a
                      href={LyricFindLink}
                      target="_blank"
                      rel="noreferrer"
                      className="text-appColor4"
                    >
                      LyricFind
                    </a>
                  </div>
                </div>
              </Card>
            </>
          ) : (
            <div className="fw-bold fs-4 my-4">
              Song Lyrics Currently Unavailable
            </div>
          )}
        </div>
      </Card.Body>
    </Card>
  );
}

LyricsViewer.propTypes = {
  lyricsData: PropTypes.shape({
    title: PropTypes.string.isRequired,
    artist: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    lyrics: PropTypes.string.isRequired,
  }).isRequired,
};

export default LyricsViewer;
