import React from "react";
import { Dropdown } from "react-bootstrap";
import { FaUserCircle } from "react-icons/fa";

import styles from "./MainNavigation.module.css";
import { dropdownItems } from "../../utils/constants";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../routes/constants";
import AppDefaultBtn from "../AppButtons/AppDefaultBtn";

const AuthDropdown = ({ isAuthenticated, handleDropdownItemClick }) =>
  isAuthenticated ? (
    <Dropdown drop="down-centered">
      <Dropdown.Toggle
        variant="link"
        id="dropdown-basic"
        className={`${styles.profileNavToggle} bg-transparent`}
      >
        <FaUserCircle className="fs-3 text-white" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <div className="mx-2">
          {dropdownItems.map((item, index) => (
            <Dropdown.Item
              key={index}
              className={`${styles.profileNavItem} ${item.className || ""}`}
              onClick={() => handleDropdownItemClick(item.path)}
            >
              {item.icon} {item.label}
            </Dropdown.Item>
          ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  ) : (
    <NavLink to={ROUTES.AUTH.LOGIN}>
      <AppDefaultBtn btnTxt="Login" size="sm" variant="appColor1" />
    </NavLink>
  );

export default AuthDropdown;
