// src/utils/authUtils.js

import { toast } from "react-toastify";
import { getAuthToken } from "../utils/authHelpers";
import axios from "axios";
import { ROUTES } from "../routes/constants";

/**
 * Generates the Axios configuration object with the Authorization header.
 *
 * @param {string} contentType - The content type for the request, defaults to "application/json".
 * @returns {Object} - Axios configuration object with the Authorization header.
 */
export function getAuthConfig(contentType = "application/json") {
  const token = getAuthToken();
  return {
    headers: {
      Authorization: `${token}`,
      "Content-Type": contentType,
    },
  };
}

/**
 * Generates the Axios configuration object without the Authorization header.
 *
 * @param {string} contentType - The content type for the request, defaults to "application/json".
 * @returns {Object} - Axios configuration object without the Authorization header.
 */
export function getConfig(contentType = "application/json") {
  return {
    headers: {
      "Content-Type": contentType,
    },
  };
}

/**
 * Handle the various API error responses.
 *
 * @param {Object} error - The error object from the API request.
 * @returns {Object|null} - The error response data or null if no response.
 */
export const handleApiError = (error, navigate) => {
  console.log("API Error Details:", error);

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const { status, data } = error.response;

    switch (status) {
      case 400:
        toast.error(data.message || "Bad Request");
        break;
      case 401:
        toast.error(data.message || "Unauthorized. Please log in again.");
        navigate(ROUTES.AUTH.LOGIN);
        // Additional handling for unauthorized errors, e.g., logout user
        break;
      case 403:
        toast.error(data.message || "Forbidden. You do not have permission.");
        break;
      case 404:
        toast.error(data.message || "Resource Not Found");
        break;
      case 408:
        toast.error(data.message || "Request Timeout");
        break;
      case 429:
        toast.error(
          data.message || "Too Many Requests. Please try again later."
        );
        break;
      case 500:
        toast.error(
          data.message || "Internal Server Error. Please try again later."
        );
        break;
      case 502:
        toast.error(data.message || "Bad Gateway. Please try again later.");
        break;
      case 503:
        toast.error(
          data.message || "Service Unavailable. Please try again later."
        );
        break;
      case 504:
        toast.error(data.message || "Gateway Timeout. Please try again later.");
        break;
      default:
        toast.error(
          data.message ||
            "An unexpected error occurred. Please try again later."
        );
        break;
    }

    return data;
  } else if (error.request) {
    // The request was made but no response was received
    toast.error(
      "No Response Received from the Server. Please Check your Internet Connection and try again."
    );
    console.error("Error Request Data:", error.request);
  } else if (axios.isCancel(error)) {
    // Request was canceled
    toast.info("Request canceled");
    console.error("Request Canceled:", error.message);
  } else {
    // Something happened in setting up the request that triggered an Error
    toast.error(
      error.message || "An error occurred while setting up the request."
    );
  }

  return null;
};
