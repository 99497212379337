import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Row, Pagination, Col, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import CommonLayout from "../../../layouts/CommonLayout";
import { StaticImages } from "../../../utils/constants";
import PageInfoHeader from "../../../components/PageInfoHeader/PageInfoHeader";
import { artistController } from "../../../features/artist/artistSlice";
import AppSpinner from "../../../components/Spinners/AppSpinner";
import AddToChromeCard from "../../../components/AppCards/AddToChromeCard";
import SEOManager from "../../../utils/SEOManager";
import ErrorAlert from "../../../components/ErrorAlert/ErrorAlert";

export const ads = [
  StaticImages.GoogleAds.Ad1,
  StaticImages.GoogleAds.Ad4,
  <AddToChromeCard />,
  StaticImages.GoogleAds.Ad17,
  StaticImages.GoogleAds.Ad18,
];

export const hotAlbumImages = [
  StaticImages.HotAlbumPlay.Img1,
  StaticImages.HotAlbumPlay.Img2,
  StaticImages.HotAlbumPlay.Img3,
  StaticImages.HotAlbumPlay.Img4,
];

const ArtistsAlbumsPage = () => {
  const dispatch = useDispatch();
  const { artistId } = useParams();
  const params = useParams();
  console.log(params);

  const { artistAlbums, isLoading, error } = useSelector(
    (state) => state.artist
  );

  // console.log(artistAlbums);

  const [currentPage, setCurrentPage] = useState(1);

  const fetchArtistsAlbums = useCallback(
    async (page) => {
      if (artistId && page > 0) {
        try {
          await dispatch(
            artistController.getArtistAlbumsSpotify({
              payload: { artist_id: artistId, page },
              config: {},
            })
          );
        } catch (err) {
          console.error("Error Fetching Artists Albums:", err);
        }
      }
    },
    [artistId, dispatch]
  );

  useEffect(() => {
    fetchArtistsAlbums(currentPage);
  }, [artistId, currentPage, fetchArtistsAlbums]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  // console.log(artistAlbums);

  const handlePageClick = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= artistAlbums?.pagination?.totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const totalPages = artistAlbums?.pagination?.totalPages || 0;

  const renderPaginationItems = () => {
    const items = [];
    let ellipsisBefore = false;
    let ellipsisAfter = false;

    for (let number = 1; number <= totalPages; number++) {
      if (
        number === 1 ||
        number === totalPages ||
        (number >= currentPage - 2 && number <= currentPage + 2)
      ) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => handlePageClick(number)}
          >
            {number}
          </Pagination.Item>
        );
        ellipsisBefore = ellipsisAfter = false;
      } else if (number < currentPage - 2 && !ellipsisBefore) {
        items.push(<Pagination.Ellipsis key="ellipsisBefore" />);
        ellipsisBefore = true;
      } else if (number > currentPage + 2 && !ellipsisAfter) {
        items.push(<Pagination.Ellipsis key="ellipsisAfter" />);
        ellipsisAfter = true;
      }
    }
    return items;
  };

  return (
    <CommonLayout>
      <SEOManager
        title={`LyricsWeb - Albums '${artistAlbums?.artist_name?.toUpperCase()}'`}
      />

      <div className="my-2">
        <PageInfoHeader
          text={`Found ${artistAlbums?.total || 0} Albums of Artist ${
            artistAlbums?.artist_name || ""
          }`}
          imageUrl={StaticImages.Banner.FilterArtist}
        />
      </div>

      <div>
        {isLoading ? (
          <div
            style={{ minHeight: "50vh" }}
            className="d-flex justify-content-center align-items-center"
          >
            <AppSpinner size={70} />
          </div>
        ) : error ? (
          <div>
            <ErrorAlert error={error} />
          </div>
        ) : artistAlbums?.albums?.length > 0 ? (
          <>
            <Row className="m-0 p-0">
              <Col lg={9} md={9} sm={12} xs={12}>
                <div className="mx-3">
                  <Table responsive borderless className="text-center">
                    <thead>
                      <tr>
                        <th className="bg-appColor6">Artist Albums Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {artistAlbums &&
                        artistAlbums?.albums &&
                        artistAlbums?.albums.map((entry, index) => (
                          <tr key={index}>
                            <td className="bg-appColor7">{entry.album_name}</td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
                <div className="d-flex justify-content-center my-4">
                  <Pagination>
                    <Pagination.Prev
                      onClick={() => handlePageClick(currentPage - 1)}
                      disabled={currentPage === 1}
                    />
                    {renderPaginationItems()}
                    <Pagination.Next
                      onClick={() => handlePageClick(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    />
                  </Pagination>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <div className="text-center fw-bolder fs-3 my-4">
            No Artists Albums Found.
          </div>
        )}
      </div>
    </CommonLayout>
  );
};

export default ArtistsAlbumsPage;
