// src/components/RadioButtonGroup.js
import React from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import styles from "./MainNavigation.module.css"; // Add styles if needed

const SearchRadioGroup = ({ options, selectedOption, onChange }) => {
  return (
    <div className={styles.radioContainer}>
      {options.map((option) => (
        <Form.Check
          key={option.id}
          inline
          label={option.label}
          type="radio"
          id={option.id}
          name="searchOption"
          className={styles.radioButton}
          onChange={() => onChange(option.id)}
          checked={selectedOption === option.id}
        />
      ))}
    </div>
  );
};

SearchRadioGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedOption: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SearchRadioGroup;
