import React from "react";
import { Card } from "react-bootstrap";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import CommonLayout from "../../../layouts/CommonLayout";
import PageInfoHeader from "../../../components/PageInfoHeader/PageInfoHeader";
import { passwordRegex, StaticImages } from "../../../utils/constants";
import appStyles from "../../../App.module.css";
import AppForm from "../../../components/AppForm/AppForm";
import { ROUTES } from "../../../routes/constants";
import { getAuthConfig } from "../../../services/apiUtils";
import { authController } from "../../../features/auth/authSlice";
import SEOManager from "../../../utils/SEOManager";

const changePassFields = [
  {
    name: "oldPassword",
    label: "Old Password",
    type: "password",
    placeholder: "Enter Old Password",
  },
  {
    name: "newPassword",
    label: "New Password",
    type: "password",
    placeholder: "Enter New Password",
  },
  {
    name: "confirmPassword",
    label: "Confirm Password",
    type: "password",
    placeholder: "Confirm New Password",
  },
];

const changePassValidationSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .required("New Password is required")
    .matches(
      passwordRegex,
      "Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character"
    ),
  newPassword: yup
    .string()
    .required("New Password is required")
    .matches(
      passwordRegex,
      "Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character"
    ),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .matches(
      passwordRegex,
      "Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character"
    ),
});

function ChangePasswordPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, error } = useSelector((state) => state.auth);

  const onSubmit = async (data, resetForm) => {
    try {
      const { confirmPassword, ...formData } = data;
      let payload = { ...formData };
      await dispatch(
        authController.changeUserPassword({
          payload,
          config: getAuthConfig(),
          resetForm,
          navigate,
        })
      ).unwrap();
    } catch (error) {
      console.error("Error Changing Password:", error);
    } finally {
    }
  };

  const submitButtonProps = {
    btnTxt: "Change Password",
    className: `${appStyles.appDefaultBtn} ${
      isLoading ? appStyles.appDefaultBtnDisabled : ""
    } w-50`,
    isLoading: isLoading,
    isLoadingText: "Loading...",
  };

  const cancelButtonProps = {
    btnTxt: "Profile",
    className: `${appStyles.appDefaultBtnDark} w-50`,
    onClick: () => navigate(ROUTES.AUTH.PROFILE),
  };

  return (
    <>
      <CommonLayout>
        <SEOManager
          title="Change Password - LyricsWeb"
          ogUrl="www.lyricsweb.com/auth/change-password"
        />

        {/* Page Info Header */}
        <div className="my-2">
          <PageInfoHeader
            text="Change Password"
            imageUrl={StaticImages.Banner.News}
          />
        </div>
        <div className="my-5">
          <Card className={`p-5`}>
            <div className="my-5">
              <AppForm
                fields={changePassFields}
                validationSchema={changePassValidationSchema}
                onSubmit={onSubmit}
                submitButtonProps={submitButtonProps}
                cancelButtonProps={cancelButtonProps}
              />
            </div>
          </Card>
        </div>
      </CommonLayout>
    </>
  );
}

export default ChangePasswordPage;
