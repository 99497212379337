// Function to generate a URL from a route template and parameters
export const generateDynamicRoute = (routeTemplate, params) => {
  let route = routeTemplate;
  const queryParams = new URLSearchParams();

  for (const key in params) {
    const value = params[key];
    const regex = new RegExp(`:${key}\\??`, "g");
    if (route.match(regex)) {
      route = route.replace(regex, value || "");
    } else {
      queryParams.append(key, value);
    }
  }

  // Remove any remaining optional parameters if not provided
  route = route.replace(/\/:.*\?/g, "");

  // Append query parameters if any
  const queryString = queryParams.toString();
  if (queryString) {
    route += `?${queryString}`;
  }

  return route;
};
