// src/components/SEOManager/SEOManager.js

import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

const SEOManager = ({
  page = "",
  title = "Lyrics Web - Your Source for Latest Song Lyrics.",
  description = "Discover the latest song lyrics, albums by artist, trending music news, and artist information at Lyrics Web. Stay updated with music insights all in one place!",
  keywords = "lyrics, song lyrics, music albums, artists, latest songs, popular song lyrics, Music lyrics search, Song lyric finder, Song lyrics search, Famous lyrics, Top artists, Lyrics meaning, Best song lyrics",
  favicon = "/favicon-32x32.png",
  canonical = "",
  author = "LyricsWeb",
  language = "en",
  ogTitle = "Lyrics Web - Your Source for Latest Song Lyrics.",
  ogDescription = "Explore a vast collection of song lyrics from various artists and albums. Stay updated with the latest music trends on LyricsWeb.",
  ogImage = "/favicon-32x32.png",
  ogUrl = "",
  ogType = "website",
  ogLocale = "en_US",
  structuredData = {
    "@context": "https://schema.org/",
    "@type": "WebSite",
    name: "Lyrics Web",
    url: "https://lyricsweb.com/",
    potentialAction: {
      "@type": "SearchAction",
      target: "https://lyricsweb.com/search?q={search_term_string}",
      "query-input": "required name=search_term_string",
    },
  },
  robots = "index, follow",
  hreflangs = [],
  pagination = {
    next: null,
    prev: null,
  },
}) => {
  const currentUrl = typeof window !== "undefined" ? window.location.href : "";

  return (
    <Helmet>
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta name="description" content={description} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="keywords" content={keywords} />
      <link rel="icon" type="image/png" href={favicon} />

      <link rel="canonical" href={canonical || currentUrl} />
      <meta name="robots" content={robots} />
      <meta name="author" content={author} />
      <html lang={language} />

      {/* Open Graph Tags */}
      <meta property="og:title" content={ogTitle || title} />
      <meta property="og:description" content={ogDescription || description} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:url" content={ogUrl || currentUrl} />
      <meta property="og:type" content={ogType} />
      {ogLocale && <meta property="og:locale" content={ogLocale} />}

      {/* Structured Data */}
      {structuredData.name && (
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      )}

      {/* Hreflang Tags */}
      {hreflangs.map(({ lang, url }) => (
        <link key={lang} rel="alternate" hrefLang={lang} href={url} />
      ))}

      {/* Pagination Tags */}
      {pagination.next && <link rel="next" href={pagination.next} />}
      {pagination.prev && <link rel="prev" href={pagination.prev} />}
    </Helmet>
  );
};

SEOManager.propTypes = {
  page: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  favicon: PropTypes.string,
  canonical: PropTypes.string,
  author: PropTypes.string,
  language: PropTypes.string,
  ogTitle: PropTypes.string,
  ogDescription: PropTypes.string,
  ogImage: PropTypes.string,
  ogUrl: PropTypes.string,
  ogType: PropTypes.string,
  ogLocale: PropTypes.string,
  structuredData: PropTypes.object,
  robots: PropTypes.string,
  hreflangs: PropTypes.arrayOf(
    PropTypes.shape({
      lang: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
  pagination: PropTypes.shape({
    next: PropTypes.string,
    prev: PropTypes.string,
  }),
};

export default SEOManager;
