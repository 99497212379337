import { MdLogout, MdOutlinePassword, MdOutlineSettings } from "react-icons/md";
import { ROUTES } from "../routes/constants";

// App Static Links

export const StaticLinks = {
  App: {
    LyricFindLink: "https://www.lyricfind.com/",
    LWFBUrl:
      "https://www.facebook.com/profile.php?id=61565559758909&mibextid=kFxxJD",
    LWInstaUrl:
      "https://www.instagram.com/lyricswebofficial?igsh=MTNvYWFpcW1kaWE5aA==",
  },
};
export const LyricFindLink = "https://www.lyricfind.com/";

export const GoogleAdSense = {
  client: "ca-pub-2299535349128385",
  slot: {
    layout_ad_1: "7937073727",
    layout_ad_2: "6623992059",
    home_1: "7429908899",
    home_2: "1749937304",
    home_3: "6425816970",
    artist_1: "7954521062",
    artist_2: "3097881831",
    artist_3: "7762949375",
  },
};

export const StaticImages = {
  App: {
    // App Logo & Static
    AppLogoLyrics: "/assets/static/AppLogoLyrics.png",
    AppAddToChromeBg: "/assets/static/AddToChrome.png",
    ChromeIcon: "/assets/static/ChromeIcon.png",
    PDFViewerBg: "/assets/static/PDFViewerBg.png",
    PDFViewerBg1: "/assets/static/PDFViewerBg1.png",
    PDFViewerFooter: "/assets/static/PDFViewerFooter.png",
    TestimonialIcon: "/assets/static/TestimonialIcon.jpg",
    LWStatic: "/assets/static/LWStatic.png",
    AppMaintenance: "/assets/static/AppMaintenance.jpg",
  },
  Award: {
    // Award
    Img1: "/assets/static/Awards1.png",
    Img2: "/assets/static/Awards2.png",
    Img3: "/assets/static/Awards3.png",
    Img4: "/assets/static/Awards4.png",
    Img5: "/assets/static/Awards5.png",
    Img6: "/assets/static/Awards6.png",
    Img7: "/assets/static/Awards7.png",
    Img8: "/assets/static/Awards8.png",
    Img9: "/assets/static/Awards9.png",
  },
  Banner: {
    // Banner
    Home: "/assets/static/Banner.png",
    AboutUs: "/assets/static/BannerAboutUs.png",
    FilterArtist: "/assets/static/BannerFilterArtist.png",
    Testimonials: "/assets/static/BannerTestimonials.png",
    Awards: "/assets/static/BannerAwards.png",
    News: "/assets/static/BannerNews.png",
    ContactUs: "/assets/static/BannerContactUs.png",
    Albums: "/assets/static/BannerAlbums.png",
    Lyrics: "/assets/static/BannerLyrics.png",
    LyricsDetails: "/assets/static/BannerLyricsDetails.png",
  },
  GoogleAds: {
    // Google Ads
    Ad1: "/assets/static/GoogleAD300x600.png",
    Ad2: "/assets/static/GoogleAD1_160x600.png",
    Ad3: "/assets/static/GoogleAD2_160x600.png",
    Ad4: "/assets/static/GoogleAD1_300x600.png",
    Ad5: "/assets/static/GoogleAD1_300x400.png",
    Ad6: "/assets/static/GoogleAD1_140x135.png",
    Ad7: "/assets/static/GoogleAD2_140x135.png",
    Ad8: "/assets/static/GoogleAD3_140x135.png",
    Ad9: "/assets/static/GoogleAD4_140x135.png",
    Ad10: "/assets/static/GoogleAD5_140x135.png",
    Ad11: "/assets/static/GoogleAD6_140x135.png",
    Ad12: "/assets/static/GoogleAD7_140x135.png",
    Ad13: "/assets/static/GoogleAD8_140x135.png",
    Ad14: "/assets/static/GoogleAD9_140x135.png",
    Ad15: "/assets/static/GoogleAD10_140x135.png",
    Ad16: "/assets/static/GoogleAD1_900x180.png",
    Ad17: "/assets/static/GoogleAD2_300x600.png",
    Ad18: "/assets/static/GoogleAD3_300x600.png",
  },
  PopularArtist: {
    // Popular Artist
    Img1: "/assets/static/PopularArtist1.png",
    Img2: "/assets/static/PopularArtist2.png",
    Img3: "/assets/static/PopularArtist3.png",
    Img4: "/assets/static/PopularArtist4.png",
    Img5: "/assets/static/PopularArtist5.png",
    Img6: "/assets/static/PopularArtist6.png",
  },
  HotAlbum: {
    // Hot Albums Images
    Img1: "/assets/static/HotAlbumImg1.png",
    Img2: "/assets/static/HotAlbumImg2.png",
    Img3: "/assets/static/HotAlbumImg3.png",
  },
  HotAlbumPlay: {
    // Hot Album Play
    Img1: "/assets/static/HotAlbumPlayImg1.png",
    Img2: "/assets/static/HotAlbumPlayImg2.png",
    Img3: "/assets/static/HotAlbumPlayImg3.png",
    Img4: "/assets/static/HotAlbumPlayImg4.png",
  },
  News: {
    // News Images
    Img1: "/assets/static/News1.png",
    Img2: "/assets/static/News2.png",
    Img3: "/assets/static/News3.png",
    Img4: "/assets/static/News4.png",
  },
  Pages: {
    // News Detail Images
    NewsDetailImg1: "/assets/static/NewsDetail1.png",
    // Home
    HomeSignUpImg: "/assets/static/HomeSignUpImg.png",
    HomeAboutUsImg: "/assets/static/HomeAboutUs.png",
    // About
    AboutAboutUsImg1: "/assets/static/AboutAboutUs1.png",
    AboutAboutUsImg2: "/assets/static/AboutAboutUs2.png",
    // Contact Us
    GetInTouchImg: "/assets/static/GetInTouchImg.png",
  },
  Spotify: {
    Icon: "/assets/spotify/Spotify_Icon_RGB_Green.png",
    Logo: "/assets/spotify/Spotify_Logo_RGB_Green.png",
  },
};

export const ServerErrorMessage =
  "Server Maintenance... Please Try Again Later";

// navbar links header / footer
export const mainNavLinks = [
  { label: "A", path: "/a" },
  { label: "B", path: "/b" },
  { label: "C", path: "/c" },
  { label: "D", path: "/d" },
  { label: "E", path: "/e" },
  { label: "F", path: "/f" },
  { label: "G", path: "/g" },
  { label: "H", path: "/h" },
  { label: "I", path: "/i" },
  { label: "J", path: "/j" },
  { label: "K", path: "/k" },
  { label: "L", path: "/l" },
  { label: "M", path: "/m" },
  { label: "N", path: "/n" },
  { label: "O", path: "/o" },
  { label: "P", path: "/p" },
  { label: "Q", path: "/q" },
  { label: "R", path: "/r" },
  { label: "S", path: "/s" },
  { label: "T", path: "/t" },
  { label: "U", path: "/u" },
  { label: "V", path: "/v" },
  { label: "W", path: "/w" },
  { label: "X", path: "/x" },
  { label: "Y", path: "/y" },
  { label: "Z", path: "/z" },
  // { label: "#", path: "/#" },
];

export const dropdownItems = [
  {
    icon: <MdOutlineSettings className="fs-4 mx-2" />,
    label: "Profile",
    className: "",
    path: ROUTES.AUTH.PROFILE,
  },
  {
    icon: <MdOutlinePassword className="fs-4 mx-2" />,
    label: "Change Password",
    className: "",
    path: ROUTES.AUTH.CHANGE_PASSWORD,
  },
  {
    icon: <MdLogout className="fs-4 mx-2" />,
    label: "Logout",
    className: "text-danger",
    path: ROUTES.AUTH.LOGOUT,
  },
];

export const footerNavLinks = [
  {
    label: "Home",
    path: "/home",
  },
  {
    label: "About",
    path: "/about",
  },
  {
    label: "News",
    path: "/news",
  },
  // {
  //   label: "Press",
  //   path: "/press",
  // },
  {
    label: "Awards",
    path: "/awards",
  },
  {
    label: "Testimonials",
    path: "/testimonials",
  },
  // {
  //   label: "Submit Lyrics",
  //   path: "/submit-lyrics",
  // },
  // {
  //   label: "Soundtracks",
  //   path: "/soundtracks",
  // },
  {
    label: "Contact Us",
    path: "/contact-us",
  },
];

export const passwordRegex =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[0-9a-zA-Z!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,}$/;

export const mobileRegex = /^[0-9]{10}$/;
