// src/routes.js
import React from "react";
import { Routes } from "react-router-dom";
import authRoutes from "./authRoutes";
import commonRoutes from "./commonRoutes";

const AppRoutes = () => (
  <Routes>
    {/* Common Routes */}
    {commonRoutes}
    {/* Auth Routes */}
    {authRoutes}
  </Routes>
);

export default AppRoutes;
