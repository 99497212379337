import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Form, Row, Stack } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import AppDefaultBtn from "../AppButtons/AppDefaultBtn";
import appStyles from "../../App.module.css";

const AppForm = ({
  fields,
  validationSchema,
  onSubmit,
  submitButtonProps,
  cancelButtonProps,
}) => {
  let location = useLocation();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    reset,
    setError,
    clearErrors,
    getValues,
  } = methods;

  const onSubmitHandler = (data) => {
    onSubmit(data, reset);
  };

  const cancelButtonDefaultFunc = () => {
    console.log("Cancel Button Default on" + location.pathname);
  };

  const newPassword = useWatch({
    control: methods.control,
    name: "newPassword",
  });

  const confirmPassword = useWatch({
    control: methods.control,
    name: "confirmPassword",
  });

  useEffect(() => {
    if (newPassword && confirmPassword && newPassword !== confirmPassword) {
      setError("confirmPassword", {
        type: "manual",
        message: "New Password & Confirm Password Should Match",
      });
    } else {
      clearErrors("confirmPassword");
    }
  }, [newPassword, confirmPassword, setError, clearErrors]);

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmitHandler)}>
        {fields.map((field, index) => (
          <Form.Group controlId={field.name} className="mb-4" key={index}>
            {field.type === "select" ? (
              <Form.Control
                as="select"
                {...register(field.name)}
                isInvalid={errors[field.name]}
              >
                <option value="">{field.placeholder}</option>
                {field.options.map((option, idx) => (
                  <option key={idx} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            ) : (
              <Form.Control
                type={field.type}
                placeholder={field.placeholder}
                {...register(field.name)}
                isInvalid={errors[field.name]}
                as={field.as}
                rows={field.rows}
              />
            )}
            <Form.Control.Feedback type="invalid">
              {errors[field.name]?.message}
            </Form.Control.Feedback>
          </Form.Group>
        ))}

        <Row>
          <Col lg={6} md={6} sm={12} xs={12}>
            {" "}
            <Stack direction="horizontal" gap={3}>
              <AppDefaultBtn
                type={submitButtonProps.type || "submit"}
                btnTxt={submitButtonProps.btnTxt || "Submit"}
                className={`${
                  submitButtonProps.className || appStyles.appDefaultBtn
                } ${!isValid ? appStyles.appDefaultBtnDisabled : ""}`}
                disabled={!isValid}
                loading={submitButtonProps.isLoading}
                loadingText={submitButtonProps.isLoadingText}
              >
                {submitButtonProps.children || "Send"}
              </AppDefaultBtn>
              <AppDefaultBtn
                type={cancelButtonProps.type || "button"}
                btnTxt={cancelButtonProps.btnTxt || "Cancel"}
                className={
                  cancelButtonProps.className || appStyles.appDefaultBtnDark
                }
                onClick={cancelButtonProps.onClick || cancelButtonDefaultFunc}
              >
                {cancelButtonProps.children || "Cancel"}
              </AppDefaultBtn>
            </Stack>
          </Col>
          <Col lg={6} md={6} sm={0} xs={0}></Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

AppForm.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string,
      placeholder: PropTypes.string,
      as: PropTypes.string,
      rows: PropTypes.number,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
  validationSchema: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitButtonProps: PropTypes.shape({
    type: PropTypes.string,
    btnTxt: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    onClick: PropTypes.func,
  }),
  cancelButtonProps: PropTypes.shape({
    type: PropTypes.string,
    btnTxt: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    onClick: PropTypes.func,
  }),
};

export default AppForm;
