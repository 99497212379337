// src/components/ProtectedRoute.js

import React from "react";
import { Navigate } from "react-router-dom";
import { getAuthToken, isTokenValid } from "../utils/authHelpers";
import { ROUTES } from "./constants";

const ProtectedRoute = ({ element: Component }) => {
  const authToken = getAuthToken();
  const isAuthenticated = authToken && isTokenValid(authToken);

  return isAuthenticated ? (
    <Component />
  ) : (
    <Navigate to={ROUTES.AUTH.LOGIN} replace />
  );
};

export default ProtectedRoute;
