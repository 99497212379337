import React from "react";
import { Col, Row } from "react-bootstrap";

import appStyles from "../../../App.module.css";
import CommonLayout from "../../../layouts/CommonLayout";
import PageInfoHeader from "../../../components/PageInfoHeader/PageInfoHeader";
import { StaticImages } from "../../../utils/constants";
import HotAlbumCardSm from "../../../components/AppCards/HotAlbumCardSm";
import AppDefaultBtn from "../../../components/AppButtons/AppDefaultBtn";

const albumData = [
  {
    imageUrl: StaticImages.App.AppLogoLyrics,
    description: "Description 1",
    year: "2023",
    views: "150000",
    playlist: "100",
  },
  {
    imageUrl: StaticImages.App.AppLogoLyrics,
    description: "Description 2",
    year: "2024",
    views: "277000",
    playlist: "127",
  },
  {
    imageUrl: StaticImages.App.AppLogoLyrics,
    description: "Description 3",
    year: "2022",
    views: "300000",
    playlist: "140",
  },
  {
    imageUrl: StaticImages.App.AppLogoLyrics,
    description: "Description 4",
    year: "2021",
    views: "250000",
    playlist: "115",
  },
  {
    imageUrl: StaticImages.App.AppLogoLyrics,
    description: "Description 5",
    year: "2020",
    views: "400000",
    playlist: "160",
  },
  {
    imageUrl: StaticImages.App.AppLogoLyrics,
    description: "Description 6",
    year: "2025",
    views: "500000",
    playlist: "200",
  },
];

function SoundtracksPage() {
  return (
    <>
      <CommonLayout>
        {/* Page Info Header */}
        <div className="my-2">
          <PageInfoHeader
            text={`Albums`}
            imageUrl={StaticImages.Banner.Albums}
          />
        </div>
        {/* Main Content */}
        <div className="p-4">
          <div className="fs-3 fw-bold my-5">
            Hot <span className={appStyles.textColorHeading}>Albums</span>
          </div>
          <Row className="m-0 p-0">
            {albumData &&
              albumData.map((album, index) => (
                <Col lg={6} md={6} sm={12} xs={12} className="m-0 p-0">
                  <div className="m-2">
                    <HotAlbumCardSm
                      imageUrl={album.imageUrl}
                      description={album.description}
                      year={album.year}
                      views={album.views}
                      playlist={album.playlist}
                    />
                  </div>
                </Col>
              ))}
          </Row>
          <Row className="justify-content-center mt-5">
            <Col xs="auto">
              <AppDefaultBtn
                btnTxt="Load More"
                className={`${appStyles.appDefaultBtn}`}
              />
            </Col>
          </Row>
        </div>
      </CommonLayout>
    </>
  );
}

export default SoundtracksPage;
