import React from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import AppDefaultBtn from "../AppButtons/AppDefaultBtn";
import styles from "./HomeSignUp.module.css";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes/constants";
import { StaticImages } from "../../utils/constants";

function HomeSignUp() {
  const navigate = useNavigate();
  return (
    <>
      <Card className={`${styles.homeCardBody}`}>
        <Row>
          <Col lg={5} md={5} sm={12} xs={12}>
            <Card.Body className="p-5">
              <Card.Title as={"h2"} className="text-uppercase fw-bold">
                We Need You!
              </Card.Title>
              <Card.Text>
                Become a part of our music-loving community to share your
                contributions and provide support and feedback to artists from
                around the globe!
              </Card.Text>
              <div className="d-flex justify-content-around">
                <AppDefaultBtn
                  btnTxt="SIGN UP"
                  onClick={() => navigate(ROUTES.AUTH.SIGNUP)}
                />
                <AppDefaultBtn
                  btnTxt="SIGN IN"
                  variant="appColor2"
                  onClick={() => navigate(ROUTES.AUTH.LOGIN)}
                />
              </div>
            </Card.Body>
          </Col>
          <Col lg={7} md={7} sm={12} xs={12}>
            <Image
              src={StaticImages.Pages.HomeSignUpImg}
              className="homeSignUpImage"
              height={"100%"}
              width={"100%"}
              alt="Home Sign Up Card"
            />

            {/* <AppLazyImage
              src={HomeSignUpImg}
              className="homeSignUpImage"
              height={"100%"}
              width={"100%"}
            /> */}
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default HomeSignUp;
