import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Image, Row, Spinner, Alert } from "react-bootstrap";
import { albumController } from "../../features/album/albumSlice";

const AlbumDetailsCard = ({ albumId }) => {
  const dispatch = useDispatch();
  const { albumDetails, isLoading, error } = useSelector(
    (state) => state.album
  );

  const fetchAlbumDetails = async () => {
    if (albumId) {
      try {
        await dispatch(
          albumController.getAlbumDetails({
            payload: { albumId: albumId },
            config: {},
          })
        );
      } catch (err) {
        console.error("Error Fetching Albums:", err);
      }
    }
  };

  useEffect(() => {
    if (albumId) {
      fetchAlbumDetails();
    }
  }, [dispatch, albumId]);

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  return (
    <Card className="bg-appColor7 shadow-sm" style={{ margin: "10px" }}>
      <Card.Body>
        {isLoading ? (
          <div className="text-center my-3">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : error ? (
          <Alert variant="danger" className="text-center">
            {error}
          </Alert>
        ) : (
          albumDetails && (
            <Row className="d-flex justify-content-center">
              <Col lg={4} md={4} sm={12} xs={12}>
                <Image
                  src={albumDetails?.images[1]?.url}
                  alt={albumDetails?.name}
                  rounded
                  fluid
                />
              </Col>
              <Col lg={8} md={8} sm={12} xs={12} className="my-2">
                <Card.Title className="fw-bold">
                  {albumDetails?.name}
                </Card.Title>
                <Card.Text>
                  <div>
                    <strong>Release Date: </strong>
                    {formatDate(albumDetails?.release_date)}
                  </div>
                  <div>
                    <strong>Total Tracks: </strong>
                    {albumDetails?.tracks.length}
                  </div>
                </Card.Text>
              </Col>
            </Row>
          )
        )}
      </Card.Body>
    </Card>
  );
};

export default AlbumDetailsCard;
