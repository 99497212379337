import React from "react";
import { useNavigate } from "react-router-dom";

import CommonLayout from "../../../layouts/CommonLayout";
import AppDefaultBtn from "../../../components/AppButtons/AppDefaultBtn";
import appStyles from "../../../App.module.css";
import PageInfoHeader from "../../../components/PageInfoHeader/PageInfoHeader";
import { StaticImages } from "../../../utils/constants";
import { ROUTES } from "../../../routes/constants";

function NotFoundPage() {
  const navigate = useNavigate();
  return (
    <>
      <CommonLayout>
        <div className="my-2">
          <PageInfoHeader
            text={`Requested Page Not Found`}
            imageUrl={StaticImages.Banner.FilterArtist}
          />
        </div>
        <div className="d-flex justify-content-center align-items-center pt-5">
          <AppDefaultBtn
            btnTxt="Back to Home"
            onClick={() => navigate(ROUTES.COMMON.HOME)}
            className={`${appStyles.appDefaultBtn} w-50`}
          />
        </div>
      </CommonLayout>
    </>
  );
}

export default NotFoundPage;
