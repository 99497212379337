// src/layouts/CommonLayout.js
import React from "react";
import { Col, Row } from "react-bootstrap";

import MainNavigation from "../components/MainNavigation/MainNavigation";
import MainFooter from "../components/MainFooter/MainFooter";
import styles from "./CommonLayout.module.css";
import GoogleAd from "../components/Advertisements/GoogleAd";
import { GoogleAdSense } from "../utils/constants.js";

const CommonLayout = ({ children }) => {
  return (
    <>
      <main>
        <Row className="m-0 p-0">
          <Col
            lg={{ span: 2, order: 1 }}
            md={{ span: 2, order: 1 }}
            sm={{ span: 12, order: 3 }}
            xs={{ span: 12, order: 3 }}
            className={`d-flex justify-content-center align-items-baseline ${styles.advertisementColumn}`}
          >
            <GoogleAd
              client={GoogleAdSense.client}
              slot={GoogleAdSense.slot.layout_ad_1}
              style={{ width: "100%", minHeight: "250px" }}
            />
          </Col>
          <Col
            lg={{ span: 8, order: 2 }}
            md={{ span: 8, order: 2 }}
            sm={{ span: 12, order: 1 }}
            xs={{ span: 12, order: 1 }}
            className="m-0 p-0"
          >
            <MainNavigation />
            {children}
          </Col>
          <Col
            lg={{ span: 2, order: 3 }}
            md={{ span: 2, order: 3 }}
            sm={{ span: 12, order: 4 }}
            xs={{ span: 12, order: 4 }}
            className={`d-flex justify-content-center align-items-baseline ${styles.advertisementColumn}`}
          >
            <GoogleAd
              client={GoogleAdSense.client}
              slot={GoogleAdSense.slot.layout_ad_2}
              style={{ width: "100%", minHeight: "250px" }}
            />
          </Col>
        </Row>
      </main>
      <MainFooter />
    </>
  );
};

export default CommonLayout;
