import React from "react";
import { Col, Row } from "react-bootstrap";

import CommonLayout from "../../../layouts/CommonLayout";
import { StaticImages } from "../../../utils/constants";
import appStyles from "../../../App.module.css";
import AwardCard from "../../../components/AppCards/AwardCard";
import SEOManager from "../../../utils/SEOManager";
import PageInfoHeader from "../../../components/PageInfoHeader/PageInfoHeader";

const awards = [
  StaticImages.Award.Img1,
  StaticImages.Award.Img2,
  StaticImages.Award.Img3,
  StaticImages.Award.Img4,
  StaticImages.Award.Img5,
  StaticImages.Award.Img6,
  StaticImages.Award.Img7,
  StaticImages.Award.Img8,
  StaticImages.Award.Img9,
];

function AwardsPage() {
  return (
    <>
      <CommonLayout>
        <SEOManager
          title="Awards - LyricsWeb"
          ogUrl="www.lyricsweb.com/awards"
        />
        {/* Page Info Header */}
        <div className="my-2">
          <PageInfoHeader
            text={`Awards We Received`}
            imageUrl={StaticImages.Banner.LyricsDetails}
          />
        </div>
        {/* Main Content */}
        <div className="p-4">
          <div>
            <Row>
              <Col lg={9} md={9} sm={12} xs={12}>
                <h1 className="fw-bold my-3">
                  Awards{" "}
                  <span className={appStyles.textColorHeading}>
                    We Have Received
                  </span>
                </h1>
              </Col>
            </Row>
            <Row>
              <Col lg={9} md={9} sm={12} xs={12}>
                <div>
                  <div className="">
                    Though newly established, LyricsWeb is quickly gaining
                    recognition for its comprehensive database and user-friendly
                    interface. Our commitment to providing the best resources
                    for music lovers is already making waves in the online music
                    community, and we are dedicated to achieving excellence and
                    delivering an outstanding experience to our users.
                  </div>
                  <div className="my-3 d-flex justify-content-center align-items-center">
                    <Row className="mx-auto">
                      {awards &&
                        awards.map((imageUrl, index) => (
                          <Col
                            lg={4}
                            md={4}
                            sm={6}
                            xs={6}
                            key={index}
                            className="my-4"
                          >
                            <AwardCard imageUrl={imageUrl} />
                          </Col>
                        ))}
                    </Row>
                  </div>
                </div>
              </Col>
              <Col
                lg={3}
                md={3}
                sm={12}
                xs={12}
                className="d-flex justify-content-center align-items-centers"
              >
                ads
              </Col>
            </Row>
          </div>
        </div>
      </CommonLayout>
    </>
  );
}

export default AwardsPage;
