import React from "react";
import { Card, Image } from "react-bootstrap";
import { motion } from "framer-motion";

function AwardCard({ imageUrl }) {
  const cardVariants = {
    initial: { opacity: 0.75, scale: 1 },
    hover: { opacity: 1, scale: 1.1 },
    tap: { scale: 0.95 },
  };

  return (
    <motion.div
      variants={cardVariants}
      initial="initial"
      whileHover="hover"
      whileTap="tap"
      className="d-inline-block"
    >
      <Card style={{ height: "140px", width: "140px", border: "none" }}>
        <Image
          src={imageUrl}
          alt="Award Image"
          style={{
            objectFit: "cover",
            height: "100%",
            width: "100%",
          }}
        />
      </Card>
    </motion.div>
  );
}

export default AwardCard;
