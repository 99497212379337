import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PropTypes from "prop-types";
import "react-lazy-load-image-component/src/effects/blur.css";

const AppLazyImage = ({
  src,
  srcSet = "",
  alt = "No Image Available",
  className = "",
  height = "auto",
  width = "auto",
  effect = "blur",
  onClick = null,
  style = {},
  ...restProps
}) => {
  return (
    <LazyLoadImage
      src={src}
      srcSet={srcSet}
      alt={alt}
      className={className}
      height={height}
      width={width}
      effect={effect}
      onClick={onClick}
      style={style}
      {...restProps}
    />
  );
};

AppLazyImage.propTypes = {
  src: PropTypes.string.isRequired,
  srcSet: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  effect: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
};

export default AppLazyImage;
