import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";

import artistReducer from "./features/artist/artistSlice";
import albumReducer from "./features/album/albumSlice";
import authReducer from "./features/auth/authSlice";
import commentReducer from "./features/comment/commentSlice";
import feedbackReducer from "./features/feedback/feedbackSlice";
import lyricsReducer from "./features/lyrics/lyricsSlice";
import newsReducer from "./features/news/newsSlice";
import searchReducer from "./features/search/searchSlice";
import testimonialReducer from "./features/testimonial/testimonialSlice";

/**
 * Configuration object for Redux Persist.
 * Specifies the key, storage method, whitelist, and version for persisted data.
 * @type {Object}
 */
const persistConfig = {
  key: "auth",
  storage,
  whitelist: ["auth"],
  version: 1,
};

/**
 * Combines all the reducers into a single root reducer.
 * Each reducer manages its own slice of the state.
 * @type {Function}
 */
const rootReducer = combineReducers({
  artist: artistReducer,
  album: albumReducer,
  auth: authReducer,
  comment: commentReducer,
  feedback: feedbackReducer,
  lyrics: lyricsReducer,
  news: newsReducer,
  search: searchReducer,
  testimonial: testimonialReducer,
});

/**
 * Creates a persisted reducer using the persistConfig and rootReducer.
 * The persisted reducer enables state persistence across page reloads.
 * @type {Function}
 */
const persistedReducer = persistReducer(persistConfig, rootReducer);

/**
 * Configures the Redux store with the persisted reducer and necessary middleware.
 * Disables serializable state invariant middleware to handle non-serializable data.
 * Enables Redux DevTools in development mode for easier debugging.
 * @returns {Object} The configured Redux store.
 */
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== "production", // Enable Redux DevTools in development
});

/**
 * Creates the persistor, which manages the rehydration and persistence of the store.
 * @returns {Object} The persistor for the Redux store.
 */
export const persistor = persistStore(store);
