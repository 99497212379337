import { toast } from "react-toastify";
import { ROUTES } from "../routes/constants";
import { jwtDecode } from "jwt-decode";

export const setAuthToken = (authToken) => {
  localStorage.setItem("auth-token", authToken);
};

// getAuthToken from localStorage for logged in users
export const getAuthToken = () => {
  let authToken = localStorage.getItem("auth-token");
  // let config = {
  //   headers: {
  //     Authorization: authToken,
  //   },
  // };
  return authToken;
};

// remove authToken from localStorage when logged out & invalid token users
export const removeAuthToken = () => {
  localStorage.removeItem("auth-token");
  localStorage.clear();
};

export const isTokenValid = (token) => {
  if (!token) return false;

  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // in seconds
    return decodedToken.exp > currentTime;
  } catch (error) {
    return false;
  }
};

// handle authentication error and navigate to the signin if status 401 from api is returned
export const handleAuthError = (errorMessage) => {
  toast.error(errorMessage);
  removeAuthToken();
  window.location.href = ROUTES.AUTH.LOGIN; // Redirect to login page
};
