import React from "react";
import { Card, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

import { formatForUrl } from "../../utils/helpers";
import { StaticImages } from "../../utils/constants";

function HotAlbumCardSm({ imageUrl, albumData, type }) {
  const navigate = useNavigate();

  const handleNavigate = (album) => {
    let link =
      type === "track"
        ? `/lyrics/${formatForUrl(album?.title)}/${album?.isrc}`
        : `/album/${formatForUrl(album?.title)}/${album?.albumId}`;

    navigate(link);
  };

  const cardVariants = {
    initial: { opacity: 0.75, scale: 1 },
    hover: { opacity: 1, scale: 1.05 },
    tap: { scale: 0.98 },
  };

  return (
    <motion.div
      variants={cardVariants}
      initial="initial"
      whileHover="hover"
      whileTap="tap"
    >
      <Card
        className="mb-3 d-flex flex-column justify-content-between"
        style={{ backgroundColor: "var(--appColor6)" }}
        onClick={() => handleNavigate(albumData)}
      >
        <Image
          src={imageUrl}
          alt="Album cover"
          width="100%"
          height="auto"
          className="card-img-top"
          style={{
            maxHeight: "200px",
            objectFit: "cover",
          }}
        />
        <Card.Body className="d-flex flex-column text-center">
          <Card.Title
            className="fw-bold text-truncate"
            style={{ fontSize: "14px" }}
          >
            {albumData.title}
          </Card.Title>
          <Card.Text style={{ fontSize: "12px" }} className="text-truncate">
            <span className="fw-bold">Artist:</span> {albumData.artists}
          </Card.Text>
          <div>
            Powered by{" "}
            <Image
              src={StaticImages.Spotify.Logo}
              alt="Spotify Logo cover"
              height={20}
            />
          </div>
        </Card.Body>
      </Card>
    </motion.div>
  );
}

export default HotAlbumCardSm;
