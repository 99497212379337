import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../routes/constants";
import { generateDynamicRoute } from "../../utils/generateDynamicRoute";
import { motion } from "framer-motion";
import { formatForUrl } from "../../utils/helpers";

const SearchTrackList = ({ songs }) => {
  const listItemVariants = {
    initial: { opacity: 0.8, scale: 1 },
    hover: { opacity: 1, scale: 1.05 },
    tap: { scale: 0.95 },
  };

  return (
    <ListGroup className="bg-appColor1">
      {/* <ListGroup.Item
        variant={"light"}
        className="p-3 fw-bold bg-appColor1 text-light"
      >
        Songs
      </ListGroup.Item> */}
      {songs.map((song) => (
        <NavLink
          key={song?.id}
          to={`${generateDynamicRoute(ROUTES.COMMON.LYRICS_DETAILS, {
            songName: formatForUrl(song.name),
            isrcKey: song.id,
          })}`}
          style={{ textDecoration: "none" }}
        >
          <motion.div
            variants={listItemVariants}
            initial="initial"
            whileHover="hover"
            whileTap="tap"
          >
            <ListGroup.Item action variant={"light"} className="fw-bold">
              {song?.name}
            </ListGroup.Item>
          </motion.div>
        </NavLink>
      ))}
    </ListGroup>
  );
};

SearchTrackList.propTypes = {
  songs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default SearchTrackList;
