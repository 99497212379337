import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "react-bootstrap";
import { IconContext } from "react-icons"; // Import IconContext for setting default icon properties
import { BsFillPersonFill } from "react-icons/bs"; // Example: Importing an icon from react-icons

const AppDefaultBtn = ({
  variant = "light",
  size = "md",
  className,
  btnTxt,
  loading = false,
  loadingText = "Loading...",
  disabled = false,
  onClick,
  type = "button",
  buttonStyle = {},
  icon: IconComponent, // New prop for icon component
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const mergedButtonStyle = {
    ...buttonStyle,
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Button
      variant={variant}
      size={size}
      className={`${className}`}
      style={mergedButtonStyle}
      disabled={disabled || loading}
      onClick={!loading ? onClick : undefined}
      type={type}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {loading ? (
        <>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />{" "}
          <span className="ml-2">{loadingText}</span>
        </>
      ) : (
        <>
          {IconComponent && ( // Render the icon if IconComponent is provided
            <IconContext.Provider value={{ className: "icon-class" }}>
              <IconComponent />
            </IconContext.Provider>
          )}
          {IconComponent && <span className="ml-2">{btnTxt}</span>}{" "}
          {/* Display button text */}
          {!IconComponent && btnTxt} {/* Display button text without icon */}
        </>
      )}
    </Button>
  );
};

AppDefaultBtn.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  btnTxt: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  scope: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  buttonStyle: PropTypes.object, // Accept custom button styles as prop
  icon: PropTypes.elementType, // PropTypes for icon component
};

export default AppDefaultBtn;
