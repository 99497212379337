import React, { useCallback, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import appStyles from "../../../App.module.css";
import CommonLayout from "../../../layouts/CommonLayout";
import PageInfoHeader from "../../../components/PageInfoHeader/PageInfoHeader";
import { StaticImages } from "../../../utils/constants";
import NewsCard from "../../../components/AppCards/NewsCard";
import { getConfig } from "../../../services/apiUtils";
import { newsController } from "../../../features/news/newsSlice";
import SEOManager from "../../../utils/SEOManager";
import AppSpinner from "../../../components/Spinners/AppSpinner";
import { generateDynamicRoute } from "../../../utils/generateDynamicRoute";
import { ROUTES } from "../../../routes/constants";

function NewsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { newsList, isLoading, error } = useSelector((state) => state.news);

  const fetchNews = useCallback(async () => {
    const { status } = await dispatch(
      newsController.getNewsList({
        config: getConfig(),
        navigate,
      })
    ).unwrap();
  }, [navigate, dispatch]);

  useEffect(() => {
    fetchNews();
  }, [fetchNews]);

  return (
    <>
      <CommonLayout>
        <SEOManager title="News - LyricsWeb" ogUrl="www.lyricsweb.com/news" />
        {/* Page Info Header */}
        <div className="my-2">
          <PageInfoHeader
            text="In The Media"
            imageUrl={StaticImages.Banner.News}
          />
        </div>{" "}
        {/* Main Content */}
        <div>
          <Row className="m-0 p-0">
            <Col lg={9} md={9} sm={12} xs={12}>
              <h1 className="fw-bold my-3">
                News{" "}
                <span className={appStyles.textColorHeading}>Articles</span>
              </h1>
            </Col>
          </Row>
          <Row className="m-0 p-0">
            <Col lg={9} md={9} sm={12} xs={12}>
              <div>
                Explore a curated collection of press articles, blog posts, and
                reviews from across the globe spotlighting the Lyrics Web
                network.
              </div>
              <div className="my-4 d-lg-flex justify-content-center align-items-center d-sm-block">
                <Row>
                  {isLoading && (
                    <div className="d-flex justify-content-center align-items-center">
                      <AppSpinner size={70} />
                    </div>
                  )}
                  {newsList &&
                    newsList.map((news, index) => (
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        key={index}
                        className="my-3"
                      >
                        <NewsCard
                          imageUrl={news.coverImg}
                          title={news.title}
                          description={news.description}
                          artistName={news.author}
                          publishedDate={news.publishDate}
                          news={news}
                          buttonText="Read More"
                          onButtonClick={() => {
                            // navigate(`/news/${news._id}`);
                            navigate(
                              `${generateDynamicRoute(
                                ROUTES.COMMON.NEWS_DETAILS,
                                { newsId: news._id }
                              )}`
                            );
                          }}
                        />
                      </Col>
                    ))}
                </Row>
              </div>
            </Col>
            <Col lg={3} md={3} sm={12} xs={12}></Col>
          </Row>
        </div>
      </CommonLayout>
    </>
  );
}

export default NewsPage;
