import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Stack } from "react-bootstrap";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

import CommonLayout from "../../../layouts/CommonLayout";
import PageInfoHeader from "../../../components/PageInfoHeader/PageInfoHeader";
import { passwordRegex, StaticImages } from "../../../utils/constants";
import appStyles from "../../../App.module.css";
import AppForm from "../../../components/AppForm/AppForm";
import { ROUTES } from "../../../routes/constants";
import AppDefaultBtn from "../../../components/AppButtons/AppDefaultBtn";
import { getConfig, handleApiError } from "../../../services/apiUtils";
import { authController } from "../../../features/auth/authSlice";
import SEOManager from "../../../utils/SEOManager";

const loginFormFields = [
  {
    name: "email",
    label: "Email",
    type: "text",
    placeholder: "Enter your Email",
  },
  {
    name: "password",
    label: "Password",
    type: "password",
    placeholder: "Enter your Password",
  },
];

const loginFormSchema = yup.object().shape({
  email: yup.string().required("Email is Required"),
  password: yup
    .string()
    .required("Password is Required")
    .matches(
      passwordRegex,
      "Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character"
    ),
});

function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, error } = useSelector((state) => state.auth);

  const onSubmit = async (data, resetForm) => {
    try {
      await dispatch(
        authController.loginUser({
          payload: data,
          config: getConfig(),
          resetForm,
          navigate,
        })
      ).unwrap();
    } catch (error) {
      console.error("Error Logging In:", error);
    } finally {
    }
  };

  const submitButtonProps = {
    btnTxt: "Login",
    className: `${appStyles.appDefaultBtn} ${
      isLoading ? appStyles.appDefaultBtnDisabled : ""
    } w-50`,
    isLoading: isLoading,
    isLoadingText: "Loading...",
  };

  const cancelButtonProps = {
    btnTxt: "Sign Up",
    className: `${appStyles.appDefaultBtnDark} w-50`,
    onClick: () => navigate(ROUTES.AUTH.SIGNUP),
  };

  if (error) {
    handleApiError(error);
  }

  return (
    <CommonLayout>
      <SEOManager
        title="Login - LyricsWeb"
        ogUrl="www.lyricsweb.com/auth/login"
      />

      <div className="my-2">
        <PageInfoHeader text="Login" imageUrl={StaticImages.Banner.News} />
      </div>
      <div className="my-5">
        <Card className={`p-5`} style={{ backgroundColor: "var(--appColor5)" }}>
          <Card.Text as={"h1"} className="fw-bold text-uppercase">
            <div>
              Welcome to the{" "}
              <span className={`${appStyles.textColorHeading}`}>Content</span>
            </div>
            <div className={`${appStyles.textColorHeading}`}>
              Management Hub
            </div>
          </Card.Text>
          <Card.Subtitle as={"h6"}>
            Login to unlock the power of content management. Gain access to your
            account settings, securely update your password, and dive into the
            world of content creation and curation.
          </Card.Subtitle>
          <div className="my-5">
            <AppForm
              fields={loginFormFields}
              validationSchema={loginFormSchema}
              onSubmit={onSubmit}
              submitButtonProps={submitButtonProps}
              cancelButtonProps={cancelButtonProps}
            />
            <Stack direction="horizontal" className="mt-2">
              <AppDefaultBtn
                variant={"link"}
                btnTxt="Forgot Password ?"
                onClick={() => navigate(ROUTES.AUTH.FORGOT_PASSWORD)}
                className={`fw-bold text-uppercase text-black`}
              />
            </Stack>
          </div>
        </Card>
      </div>
    </CommonLayout>
  );
}

export default LoginPage;
