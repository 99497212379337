// withSessionTimeout.js

import React, { useEffect } from "react";
import sessionTimeoutService from "./sessionTimeoutService";

const withSessionTimeout = (WrappedComponent) => {
  return (props) => {
    useEffect(() => {
      // Set up session timeout for the entire app
      sessionTimeoutService.setLogoutTimer(
        sessionTimeoutService.logoutCallback
      );

      // Clear session timeout when the app is unmounted
      return () => {
        sessionTimeoutService.clearLogoutTimer();
      };
    }, []);

    return <WrappedComponent {...props} />;
  };
};

export default withSessionTimeout;
