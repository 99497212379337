import React from "react";
import { Badge, Image } from "react-bootstrap";
import SearchResultItem from "./SearchResultItem";
import { StaticImages } from "../../utils/constants";
import SpotifyPoweredLogo from "../Attributions/SpotifyPoweredLogo";

function SearchResultSection({
  title,
  items,
  searchType,
  setSearchResults,
  dispatch,
  routeing,
}) {
  if (!items || items.length === 0) return null;
  return (
    <div>
      <div className="d-flex flex-column align-items-end">
        {/* <Badge pill bg="appColor1" className="px-4 py-2 fst-italic">
          {title}
        </Badge> */}
        <div className=" d-flex justify-content-center align-items-center">
          <span className="float-end text-appColor1 fst-italic">- {title}</span>
          <span>
            <SpotifyPoweredLogo
              poweredByText=" "
              logoSrc={StaticImages.Spotify.Icon}
              logoHeight={15}
            />
          </span>
        </div>
        <ul className="list-unstyled">
          {items.slice(0, 3).map((item) => (
            <SearchResultItem
              key={item?.id}
              result={item}
              searchType={searchType}
              setSearchResults={setSearchResults}
              dispatch={dispatch}
              routeing={routeing}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}

export default SearchResultSection;
