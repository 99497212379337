import React, { useCallback, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import CommonLayout from "../../../layouts/CommonLayout";
import PageInfoHeader from "../../../components/PageInfoHeader/PageInfoHeader";
import { StaticImages } from "../../../utils/constants";
import appStyles from "../../../App.module.css";
import TestimonialCard from "../../../components/AppCards/TestimonialCard";
import { getConfig } from "../../../services/apiUtils";
import { testimonialController } from "../../../features/testimonial/testimonialSlice";
import SEOManager from "../../../utils/SEOManager";
import AppSpinner from "../../../components/Spinners/AppSpinner";

function TestimonialsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { testimonialList, isLoading, error } = useSelector(
    (state) => state.testimonial
  );

  const fetchTestimonials = useCallback(async () => {
    const { status } = await dispatch(
      testimonialController.getTestimonialList({
        config: getConfig(),
      })
    ).unwrap();
  }, [dispatch]);

  useEffect(() => {
    fetchTestimonials();
  }, [fetchTestimonials]);

  return (
    <>
      <CommonLayout>
        <SEOManager
          title="Testimonials - LyricsWeb"
          ogUrl="www.lyricsweb.com/testimonials"
        />
        {/* Page Info Header */}
        <div className="my-2">
          <PageInfoHeader
            text="Testimonials"
            imageUrl={StaticImages.Banner.Testimonials}
          />
        </div>

        {/* Main Content */}
        <div className="p-4">
          <Row className="m-0 p-0">
            <Col lg={9} md={9} sm={12} xs={12} className="m-0 p-0">
              <div>
                <h1 className="fw-bold my-3">
                  Our{" "}
                  <span className={appStyles.textColorHeading}>
                    Happy Clients
                  </span>
                </h1>
                <div className="">
                  Discover what our users have to say about their experience
                  with lyricsweb.com. Read through their testimonials below and
                  if you have a story of your own to share, we'd love to hear
                  it!
                </div>
                <div className="my-2">
                  <Row className="m-0 p-0">
                    {isLoading && (
                      <div className="my-5 d-flex justify-content-center align-items-center">
                        <AppSpinner size={70} />
                      </div>
                    )}

                    {testimonialList &&
                      testimonialList.map((testimonial, index) => (
                        <Col lg={12} md={12} sm={12} xs={12} key={index}>
                          <TestimonialCard
                            avatar={testimonial.avatar}
                            name={testimonial.fullName}
                            description={testimonial.description}
                            rating={testimonial.rating}
                          />
                        </Col>
                      ))}
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={3} md={3} sm={12} xs={12} className="m-0 p-0"></Col>
          </Row>
        </div>
      </CommonLayout>
    </>
  );
}

export default TestimonialsPage;
