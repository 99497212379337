// src/constants.js

export const ROUTES = {
  AUTH: {
    LOGIN: "/auth/login",
    LOGOUT: "/auth/logout",
    SIGNUP: "/auth/signup",
    VERIFY: "/auth/verify/:verifyToken",
    FORGOT_PASSWORD: "/auth/forgot-password",
    RESET_PASSWORD: "/auth/reset-password/:resetToken",
    PROFILE: "/auth/profile",
    CHANGE_PASSWORD: "/auth/change-password",
  },
  COMMON: {
    HOME: "/",
    HOME_: "/home",
    ABOUT: "/about",
    NEWS: "/news",
    PRESS: "/press",
    AWARDS: "/awards",
    TESTIMONIALS: "/testimonials",
    SUBMIT_LYRICS: "/submit-lyrics",
    SOUNDTRACKS: "/soundtracks",
    CONTACT_US: "/contact-us",
    PRIVACY_POLICY: "/privacy-policy",
    TERMS_OF_USE: "/terms-of-use",
    MAINTENANCE: "/maintenance",
    // dynamic routes
    SEARCH_RESULT: "/search-result",
    NEWS_DETAILS: "/news/:newsId",
    ARTISTS: "/artist/:artistName?/:artistId?",
    ALBUMS: "/album/:albumName?/:albumId?",
    ARTISTS_ALBUMS: "/artist/album/:albumId?/:albumName?",
    LYRICS_DETAILS: "/lyrics/:songName?/:isrcKey?",
    NOT_FOUND: "*",
  },
};
