import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { makeRequest } from "../../services/apiRequests";
import { handleApiError } from "../../services/apiUtils";

/* Search Section */
const getSearchResultsQuery = createAsyncThunk(
  "search/getSearchResultsQuery",
  async ({ payload, config }, { rejectWithValue }) => {
    try {
      const { data, status } = await makeRequest(
        "GET",
        "/user/all-spot",
        payload,
        config
      );

      if (status === 200) {
        return data.data; // Return entire data for processing
      } else {
        return rejectWithValue({
          status,
          message: data.message,
        });
      }
    } catch (error) {
      const errorMessage = handleApiError(error);
      console.error(errorMessage);
      return rejectWithValue(error);
    }
  }
);

const searchSlice = createSlice({
  name: "search",
  initialState: {
    searchResultAll: null,
    artists: [],
    albums: [],
    tracks: [],
    isLoading: false,
    error: null,
    errorStatus: null,
    errorMessage: null,
  },
  reducers: {
    resetSearch: (state) => {
      state.searchResultAll = null;
      state.artists = [];
      state.albums = [];
      state.tracks = [];
      state.error = null;
      state.errorStatus = null;
      state.errorMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      /* Get Search Results from Query */
      .addCase(getSearchResultsQuery.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.errorStatus = null;
        state.errorMessage = null;
      })
      .addCase(getSearchResultsQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        const { tracks, artists, albums } = action.payload;
        state.searchResultAll = action.payload; // Store entire result
        state.tracks = tracks || []; // Extract tracks
        state.artists = artists || []; // Extract artists
        state.albums = albums || []; // Extract albums
      })
      .addCase(getSearchResultsQuery.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.errorStatus = action.error?.status || null; // Store HTTP status if available
        state.errorMessage = action.payload || "An unknown error occurred"; // Store error message
      });
  },
});

export const { resetSearch } = searchSlice.actions;

export const searchController = { getSearchResultsQuery };

export default searchSlice.reducer;
