import React, { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";

import appStyles from "../../../App.module.css";
import CommonLayout from "../../../layouts/CommonLayout";
import PageInfoHeader from "../../../components/PageInfoHeader/PageInfoHeader";
import { StaticImages } from "../../../utils/constants";
import NewsDetailCard from "../../../components/AppCards/NewsDetailCard";
import { getAuthConfig } from "../../../services/apiUtils";
import { newsController } from "../../../features/news/newsSlice";
import AppSpinner from "../../../components/Spinners/AppSpinner";
import SEOManager from "../../../utils/SEOManager";

function NewsDetailPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { newsId } = useParams();
  const { specificNews, isLoading, error } = useSelector((state) => state.news);

  const fetchNewsDetails = useCallback(async () => {
    const { status } = await dispatch(
      newsController.getNewsDetails({
        payload: { newsId },
        config: getAuthConfig(),
        navigate,
      })
    ).unwrap();
  }, [navigate, dispatch, newsId]);

  useEffect(() => {
    fetchNewsDetails();
  }, [fetchNewsDetails]);

  // Animation variants
  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  const spinnerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.4, ease: "easeInOut" } },
  };

  return (
    <CommonLayout>
      <SEOManager
        title="News Details - LyricsWeb"
        ogUrl={`www.lyricsweb.com/news/${newsId}`}
      />
      {/* Page Info Header */}
      <div className="my-2">
        <PageInfoHeader
          text="In The Media"
          imageUrl={StaticImages.Banner.News}
        />
      </div>

      {/* Main Content */}
      <div className="p-4">
        <Row className="m-0 p-0">
          <Col lg={9} md={9} sm={12} xs={12}>
            <h1 className="fw-bold my-3">
              News <span className={appStyles.textColorHeading}>Articles</span>
            </h1>
            <div className="my-5 d-flex justify-content-center align-items-center">
              {isLoading ? (
                <motion.div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "100vh" }}
                  initial="hidden"
                  animate="visible"
                  variants={spinnerVariants}
                >
                  <AppSpinner size={60} color="#000" />
                </motion.div>
              ) : (
                specificNews && (
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={cardVariants}
                  >
                    <NewsDetailCard
                      imageUrl={specificNews?.coverImg}
                      title={specificNews?.title}
                      description={specificNews?.description}
                      artistName={specificNews?.author}
                      publishedDate={specificNews?.publishDate}
                    />
                  </motion.div>
                )
              )}
            </div>
          </Col>
          {/* Uncomment and animate the advertisements if needed */}
          {/* <Col lg={3} md={3} sm={12} xs={12}>
           
          </Col> */}
        </Row>
      </div>
    </CommonLayout>
  );
}

export default NewsDetailPage;
