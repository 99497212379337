import React from "react";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { resetLyricsList } from "../../features/lyrics/lyricsSlice";
import { Col, Image, Row } from "react-bootstrap";
import styles from "./MainNavigation.module.css";
import { formatForUrl } from "../../utils/helpers";
import { StaticImages } from "../../utils/constants";

const SearchResultItem = ({
  result,
  searchType,
  setSearchResults,
  dispatch,
  routeing,
}) => {
  let linkPath = "";

  if (searchType === "track") {
    linkPath = `/lyrics/${formatForUrl(result?.name)}/${
      result?.external_ids?.isrc || `not-found`
    }`;
  } else if (searchType === "artist") {
    linkPath = `/artist/${formatForUrl(result?.name)}/${result?.id}`;
  } else if (searchType === "album") {
    linkPath = `/album/${formatForUrl(result?.name)}/${result?.id}`;
  } else if (searchType === "all") {
    linkPath = `${routeing}/${formatForUrl(result?.name)}/${result?.id}`;
  }

  return (
    <div>
      <NavLink
        key={result?.id}
        to={linkPath}
        onClick={() => {
          setSearchResults([]);
          dispatch(resetLyricsList());
        }}
        className="text-decoration-none"
      >
        <motion.div
          className={styles.searchResultItem}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.2, ease: "easeOut" }}
          whileHover={{
            backgroundColor: "var(--appColor6)", // Change this color as per your design
          }}
        >
          <Row className="m-0 p-0 align-items-center">
            <Col lg={3} md={3} sm={3} xs={3}>
              <Image
                src={result?.album?.images[2]?.url || result?.images[0]?.url}
                className="w-100"
                style={{ objectFit: "cover", aspectRatio: "1 / 1" }}
                alt="Search Item Image"
                rounded
              />
            </Col>
            <Col lg={9} md={9} sm={9} xs={9}>
              <span className={styles.searchResultItemTitle}>
                {result?.name}
              </span>

              <br />
              <span
                className={`${styles.searchResultItemArtist} float-end text-black`}
                style={{ fontSize: "12px" }}
              >
                {searchType === "track" || searchType === "album"
                  ? `- ${result?.artists?.[0]?.name}`
                  : ""}
                {result?.artists?.[0]?.name && searchType === "all"
                  ? `- ${result?.artists?.[0]?.name}`
                  : ""}
              </span>
            </Col>
          </Row>
        </motion.div>
      </NavLink>
    </div>
  );
};

export default SearchResultItem;
