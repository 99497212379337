import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { Row, Pagination, Col, Table, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FaHeart, FaMusic } from "react-icons/fa6";

import CommonLayout from "../../../layouts/CommonLayout";
import { GoogleAdSense, StaticImages } from "../../../utils/constants";
import PageInfoHeader from "../../../components/PageInfoHeader/PageInfoHeader";
import { artistController } from "../../../features/artist/artistSlice";
import AppSpinner from "../../../components/Spinners/AppSpinner";
import AddToChromeCard from "../../../components/AppCards/AddToChromeCard";
import SEOManager from "../../../utils/SEOManager";
import { ROUTES } from "../../../routes/constants";
import { generateDynamicRoute } from "../../../utils/generateDynamicRoute";
import ArtistDetailsCard from "../../../components/AppCards/ArtistDetailsCard";
import ErrorAlert from "../../../components/ErrorAlert/ErrorAlert";
import ArtistBioCard from "../../../components/AppCards/ArtistBioCard";
import { decodeFromUrl, formatForUrl } from "../../../utils/helpers";
import GoogleAd from "../../../components/Advertisements/GoogleAd";

export const ads = [
  StaticImages.GoogleAds.Ad1,
  StaticImages.GoogleAds.Ad4,
  <AddToChromeCard />,
  StaticImages.GoogleAds.Ad17,
  StaticImages.GoogleAds.Ad18,
];

const ArtistsPage = () => {
  const dispatch = useDispatch();
  const { artistId, artistName } = useParams();

  const {
    artistList,
    artistAlbums,
    artistSongs,
    artistDetails,
    artistBio,
    isLoading,
    isLoadingSongs,
    error,
  } = useSelector((state) => state.artist);

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 33; // Set the total number of pages to 33

  const fetchArtists = async (page) => {
    if (artistName && page > 0) {
      try {
        if (artistName.length === 1) {
          await dispatch(
            artistController.getArtistByLetter({
              payload: {
                type: "track",
                letter: artistName.toUpperCase(),
                page,
                limit: "40",
              },
              config: {},
            })
          ).unwrap();
        } else {
          dispatch(
            artistController.getArtistAlbumsSpotify({
              payload: { artistId: artistId, page },
              config: {},
            })
          );
          dispatch(
            artistController.getArtistDetailsSpotify({
              payload: { artistId: artistId },
              config: {},
            })
          );
          dispatch(
            artistController.getArtistBio({
              payload: { artistId: artistId },
              config: {},
            })
          );
          dispatch(
            artistController.getArtistSongsSpotify({
              payload: { artistId: artistId },
              config: {},
            })
          );
        }
      } catch (err) {
        console.error("Error Fetching Artists:", err);
      }
    }
  };

  useEffect(() => {
    fetchArtists(currentPage);
  }, [artistId, currentPage, artistName]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1); // Reset current page to 1 when artistId changes
  }, [artistId]);

  const handlePageClick = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const renderPaginationItems = () => {
    const items = [];
    let ellipsisBefore = false;
    let ellipsisAfter = false;

    for (let number = 1; number <= totalPages; number++) {
      if (
        number === 1 ||
        number === totalPages ||
        (number >= currentPage - 2 && number <= currentPage + 2)
      ) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => handlePageClick(number)}
          >
            {number}
          </Pagination.Item>
        );
        ellipsisBefore = ellipsisAfter = false;
      } else if (number < currentPage - 2 && !ellipsisBefore) {
        items.push(<Pagination.Ellipsis key={`ellipsisBefore-${number}`} />);
        ellipsisBefore = true;
      } else if (number > currentPage + 2 && !ellipsisAfter) {
        items.push(<Pagination.Ellipsis key={`ellipsisAfter-${number}`} />);
        ellipsisAfter = true;
      }
    }
    return items;
  };

  return (
    <CommonLayout>
      <SEOManager
        title={
          artistName.length === 1
            ? `Lyrics Web - Discover Song Lyrics by Alphabetical order ${decodeFromUrl(
                artistName
              )}`
            : `Get Albums of ${decodeFromUrl(
                artistName
              )} - Explore Song Lyrics on Lyrics Web`
        }
        description={
          artistName.length === 1
            ? `Discover lyrics from albums starting with the ${artistName}. Join the Lyrics Web community to explore, understand, & connect with fellow music enthusiasts!`
            : `Explore all albums by ${artistName} and dive into their lyrics with the Lyrics Web community. Discover song meanings, explore tracks, & connect with fellow music enthusiasts!`
        }
        keywords={`lyrics, song lyrics, music albums, artists, latest songs, popular song lyrics, Music lyrics search, Song lyric finder, Song lyrics search, Famous lyrics, Top artists, Lyrics meaning, Best song lyrics, ${artistName} music albums, music albums by ${artistName}`}
        canonical={
          artistName.length === 1
            ? `https://www.lyricsweb.com/artist/${formatForUrl(artistName)}`
            : `https://www.lyricsweb.com/artist/${formatForUrl(
                artistName
              )}/${artistId}`
        }
        author={decodeFromUrl(artistName)}
        language="en"
        ogTitle={
          artistName.length === 1
            ? `Lyrics Web - Discover Song Lyrics by Alphabetical order ${decodeFromUrl(
                artistName
              )}`
            : `Get Albums of ${decodeFromUrl(
                artistName
              )} - Explore Song Lyrics on Lyrics Web`
        }
        ogDescription={
          artistName.length === 1
            ? `Discover lyrics from albums starting with the ${artistName}. Join the Lyrics Web community to explore, understand, & connect with fellow music enthusiasts!`
            : `Explore all albums by ${artistName} and dive into their lyrics with the Lyrics Web community. Discover song meanings, explore tracks, & connect with fellow music enthusiasts!`
        }
        ogImage="/path/to/artist-og-image.png"
        ogUrl={
          artistName.length === 1
            ? `https://www.lyricsweb.com/artist/${formatForUrl(artistName)}`
            : `https://www.lyricsweb.com/artist/${formatForUrl(
                artistName
              )}/${artistId}`
        }
        ogType="profile"
        ogLocale="en_US"
        structuredData={{
          "@context": "https://schema.org",
          "@type": "MusicGroup",
          name: `${artistName}`,
          genre: "Pop", // Modify genre accordingly
          sameAs:
            artistName.length === 1
              ? `https://www.lyricsweb.com/artist/${formatForUrl(artistName)}`
              : `https://www.lyricsweb.com/artist/${formatForUrl(
                  artistName
                )}/${artistId}`,
          image: "https://example.com/image-of-artist.jpg",
          album:
            artistName && artistName.length === 1
              ? Array.isArray(artistList?.artists) &&
                artistList?.artists?.length > 0
                ? artistList?.artists?.map((artist) => ({
                    "@type": "MusicRecording",
                    name: artist?.name || "Unknown Album Name",
                    url:
                      `https://lyricsweb.com/artist/${formatForUrl(
                        artist?.name
                      )}/${artist?.id}` ||
                      "https://example.com/default-album-url",
                    followers: artist?.followers ? `${artist?.followers}` : "0",
                  }))
                : []
              : Array.isArray(artistAlbums) && artistAlbums.length > 0
              ? artistAlbums.map((album) => ({
                  "@type": "MusicRecording",
                  name: album?.name || "Unknown Album Name",
                  url:
                    `https://lyricsweb.com/artist/${formatForUrl(
                      album?.name
                    )}/${album?.id}` || "https://example.com/default-album-url",
                  total_tracks: album?.total_tracks
                    ? `${album.total_tracks}`
                    : "0",
                }))
              : [],
        }}
        robots="index, follow"
      />

      <div className="my-2">
        <PageInfoHeader
          text={
            artistName
              ? `${decodeFromUrl(artistName)} Songs / Albums`
              : `Found ${
                  artistId?.length === 1 ? "Artists" : "Albums"
                } Starting With ${artistId?.toUpperCase() || ""}`
          }
          imageUrl={StaticImages.Banner.FilterArtist}
        />
      </div>

      <div>
        {isLoading ? (
          <div
            style={{ minHeight: "50vh" }}
            className="d-flex justify-content-center align-items-center"
          >
            <AppSpinner size={70} />
          </div>
        ) : error ? (
          <div>
            <ErrorAlert error={error} />
          </div>
        ) : artistList?.artists?.length > 0 || artistAlbums?.length > 0 ? (
          <>
            <Row className="m-0 p-0">
              <Col lg={9} md={9} sm={12} xs={12}>
                {artistName?.length === 1 ? (
                  <div className="mx-3">
                    <Table responsive borderless hover className="text-center">
                      <thead>
                        <tr>
                          <th className="bg-appColor6"></th>
                          <th className="bg-appColor6">Artist Name</th>
                          <th className="bg-appColor6">Followers</th>
                        </tr>
                      </thead>
                      <tbody>
                        {artistList?.artists?.map((entry, index) => (
                          <tr key={index}>
                            <td className="bg-appColor7">
                              <Image
                                src={entry?.images[0]?.url}
                                height={40}
                                width={40}
                                alt="Artist Image"
                                rounded
                              />
                            </td>
                            <td className="bg-appColor7">
                              <NavLink
                                to={{
                                  pathname: generateDynamicRoute(
                                    ROUTES.COMMON.ARTISTS,
                                    {
                                      artistId: entry?.id,
                                      artistName: formatForUrl(entry?.name),
                                    }
                                  ),
                                }}
                                className={`text-decoration-none`}
                              >
                                {entry?.name}
                              </NavLink>
                            </td>
                            <td className="bg-appColor7">
                              {entry?.followers} <FaHeart color="red" />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <div className="mx-3">
                    <div className="my-2">
                      <ArtistDetailsCard artist={artistDetails} />
                    </div>
                    <div className="my-2">
                      {artistBio && artistBio.length > 0 && (
                        <ArtistBioCard artist={artistBio} />
                      )}
                    </div>
                    <h3 className="fw-bold my-3 text-center">
                      Found Albums of{" "}
                      <span className={`text-appColor1`}>
                        {decodeFromUrl(artistName)}
                      </span>{" "}
                      Artist
                    </h3>

                    <Table responsive borderless hover className="text-center">
                      <thead>
                        <tr>
                          <th className="bg-appColor6"></th>
                          <th className="bg-appColor6">Albums</th>
                          <th className="bg-appColor6"># Songs</th>
                        </tr>
                      </thead>
                      <tbody>
                        {artistAlbums?.map((entry, index) => (
                          <tr key={index}>
                            <td className="bg-appColor7">
                              <Image
                                src={entry?.images[0]?.url}
                                height={40}
                                width={40}
                                alt="Artist Albums Image"
                                rounded
                              />
                            </td>
                            <td className="bg-appColor7 text-decoration-none">
                              <NavLink
                                to={{
                                  pathname: generateDynamicRoute(
                                    ROUTES.COMMON.ALBUMS,
                                    {
                                      albumId: entry?.id,
                                      albumName: formatForUrl(entry?.name),
                                    }
                                  ),
                                }}
                                className={`text-decoration-none`}
                              >
                                {entry.name}
                              </NavLink>
                            </td>
                            <td className="bg-appColor7 text-decoration-none">
                              {entry.total_tracks} <FaMusic />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
                <div className="d-flex justify-content-center my-4">
                  <Pagination>
                    <Pagination.Prev
                      onClick={() => handlePageClick(currentPage - 1)}
                      disabled={currentPage === 1}
                    />
                    {renderPaginationItems()}
                    <Pagination.Next
                      onClick={() => handlePageClick(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    />
                  </Pagination>
                </div>
              </Col>
              <Col lg={3} md={3} sm={12} xs={12}>
                <GoogleAd
                  client={GoogleAdSense.client}
                  slot={GoogleAdSense.slot.artist_1}
                />
                <GoogleAd
                  client={GoogleAdSense.client}
                  slot={GoogleAdSense.slot.artist_2}
                />
                <GoogleAd
                  client={GoogleAdSense.client}
                  slot={GoogleAdSense.slot.artist_3}
                />
              </Col>
            </Row>
          </>
        ) : (
          <div className="text-center fw-bolder fs-3 my-4">
            No Artists Found.
          </div>
        )}
      </div>

      {artistName?.length > 1 && (
        <>
          <h3 className="fw-bold my-3 text-center">
            Songs of{" "}
            <span className={`text-appColor1`}>
              {decodeFromUrl(artistName)}
            </span>
          </h3>
          {isLoadingSongs ? (
            <div
              style={{ minHeight: "50vh" }}
              className="d-flex justify-content-center align-items-center"
            >
              <AppSpinner size={70} />
            </div>
          ) : artistSongs && artistSongs.length > 0 ? (
            <Table responsive borderless hover className="text-center">
              <thead>
                <tr>
                  <th className="bg-appColor6">Songs</th>
                  <th className="bg-appColor6">Artist</th>
                  <th className="bg-appColor6">Duration</th>
                </tr>
              </thead>
              <tbody>
                {artistSongs.map((entry, index) => (
                  <tr key={index}>
                    <td className="bg-appColor7 text-decoration-none">
                      <NavLink
                        className={`text-decoration-none`}
                        to={{
                          pathname: generateDynamicRoute(
                            ROUTES.COMMON.LYRICS_DETAILS,
                            {
                              isrcKey: entry.id,
                              songName: formatForUrl(entry.name),
                            }
                          ),
                        }}
                      >
                        {entry.name}
                      </NavLink>
                    </td>
                    <td className="bg-appColor7">
                      {entry.artists.map((artist, i) => (
                        <span key={i}>
                          {artist.name}
                          {i < entry.artists.length - 1 && ", "}
                        </span>
                      ))}
                    </td>
                    <td className="bg-appColor7">
                      {new Date(entry.duration_ms).toISOString().substr(14, 5)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="text-center fw-bolder fs-3 my-4">
              No Songs Found.
            </div>
          )}
        </>
      )}
    </CommonLayout>
  );
};

export default ArtistsPage;
