import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { footerNavLinks, StaticLinks } from "../../utils/constants";
import { FaFacebookF, FaInstagram, FaXTwitter } from "react-icons/fa6";
import styles from "./MainFooter.module.css";
import { NavLink } from "react-router-dom";

export default function MainFooter() {
  return (
    <>
      <Container fluid className={`${styles.AppFooter}`}>
        <Row className={`${styles.AppFooterNavigation} m-0 p-5`}>
          <Col lg={9} md={9} sm={12} xs={12} className="m-2">
            <div className="d-flex flex-wrap justify-content-around">
              {footerNavLinks &&
                footerNavLinks.map((link, index) => (
                  <NavLink
                    key={index}
                    to={`${link.path}`}
                    className={styles.footerLink}
                  >
                    <li className="list-inline-item footerLink">
                      {link.label.toUpperCase()}
                    </li>
                  </NavLink>
                ))}
            </div>
          </Col>
          <Col
            lg={3}
            md={3}
            sm={12}
            xs={12}
            className="m-2 fs-4 d-flex justify-content-center align-content-center"
          >
            <NavLink
              to={StaticLinks.App.LWFBUrl}
              target="_blank"
              className={`mx-2 ${styles.footerLink}`}
            >
              <FaFacebookF />
            </NavLink>
            <NavLink
              to={StaticLinks.App.LWInstaUrl}
              target="_blank"
              className={`mx-2 ${styles.footerLink}`}
            >
              <FaInstagram />
            </NavLink>
            <NavLink to="/" className={`mx-2 ${styles.footerLink}`}>
              <FaXTwitter />
            </NavLink>
          </Col>
        </Row>
        <Row className={`${styles.AppFooterCopyright} m-0 p-2 fw-bold`}>
          <Col
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="d-flex justify-content-center align-items-center text-center"
          >
            All rights reserved to Global Media Holdings Ltd. 2024
          </Col>
          <Col
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="d-flex justify-content-center align-items-center"
          >
            <ul className="list-inline mb-0 mx-2 text-center">
              <NavLink to={"/terms-of-use"} className={styles.footerLink}>
                <li className="list-inline-item text-white mx-2">
                  Terms of Use
                </li>
              </NavLink>
              <NavLink to={"/privacy-policy"} className={styles.footerLink}>
                <li className="list-inline-item text-white mx-2">
                  Privacy Policy
                </li>
              </NavLink>
            </ul>
          </Col>
        </Row>
      </Container>
    </>
  );
}
