import React from "react";

import appStyles from "../../../App.module.css";
import CommonLayout from "../../../layouts/CommonLayout";
import PageInfoHeader from "../../../components/PageInfoHeader/PageInfoHeader";
import { StaticImages } from "../../../utils/constants";

function SubmitLyricsPage() {
  return (
    <>
      <CommonLayout>
        {/* Page Info Header */}
        <div className="my-2">
          <PageInfoHeader
            text={`Lyrics`}
            imageUrl={StaticImages.Banner.Lyrics}
          />
        </div>
        {/* Main Content */}
        <div className="fs-3 fw-bold my-5">
          Hot <span className={appStyles.textColorHeading}>Albums</span>
        </div>
      </CommonLayout>
    </>
  );
}

export default SubmitLyricsPage;
