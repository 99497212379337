import React from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import { FaStar, FaStarHalf, FaRegStar } from "react-icons/fa6";
import { motion } from "framer-motion";
import { StaticImages } from "../../utils/constants";

function TestimonialCard({ avatar, name, rating, description }) {
  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        stars.push(<FaStar key={i} className="text-warning" />);
      } else if (i - rating === 0.5) {
        stars.push(<FaStarHalf key={i} className="text-warning" />);
      } else {
        stars.push(<FaRegStar key={i} className="text-warning" />);
      }
    }
    return stars;
  };

  const cardVariants = {
    initial: { opacity: 0.8, scale: 1 },
    hover: { opacity: 1, scale: 1.05 },
    tap: { scale: 0.98 },
  };

  return (
    <motion.div
      variants={cardVariants}
      initial="initial"
      whileHover="hover"
      whileTap="tap"
      className="my-3 mx-1"
    >
      <Card
        className="bg-appColor6"
        style={{ fontSize: "14px", fontStyle: "italic" }}
      >
        <Card.Body className="p-4">
          <Card.Text>{`" ${description} "`}</Card.Text>
          <Row>
            <Col lg={8}>
              <div>
                <Image
                  src={avatar || StaticImages.App.TestimonialIcon}
                  alt="testimonial"
                  height={"25px"}
                  width={"25px"}
                  style={{ borderRadius: "50%" }}
                />
                <span className="fw-bold text-uppercase mx-2">{name}</span>
              </div>
            </Col>
            <Col lg={4} className="px-2">
              <div>{renderStars(rating)}</div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </motion.div>
  );
}

export default TestimonialCard;
