// components/ErrorAlert/ErrorAlert.jsx
import React from "react";
import { Alert, Card } from "react-bootstrap";
import PropTypes from "prop-types";

const ErrorAlert = ({ error }) => {
  if (!error) return null;

  const { status, message, details, response = {}, code, config = {} } = error;

  const defaultMessage = "An unexpected error occurred.";

  return (
    <Alert variant="danger">
      <div className="fw-bold">Error Occurred :</div>
      {status && <div>Status: {status}</div>}
      {message && <div>Message: {message}</div>}
      {details && <div>Details: {details}</div>}
      {!message && !details && !status && <div>{defaultMessage}</div>}
      {code && <div>Code: {code}</div>}
      {response.data && response.data.error && (
        <div>Error Data: {response.data.error}</div>
      )}
      {config && config.url && <div>Request URL: {config.url}</div>}
    </Alert>
  );
};

ErrorAlert.propTypes = {
  error: PropTypes.shape({
    status: PropTypes.number,
    message: PropTypes.string,
    details: PropTypes.string,
    response: PropTypes.shape({
      data: PropTypes.shape({
        error: PropTypes.string,
      }),
    }),
    code: PropTypes.string,
    config: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
};

export default ErrorAlert;
