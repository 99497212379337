import React from "react";
import { Card } from "react-bootstrap";

import styles from "./HomeAboutUs.module.css";

function HomeAboutUs() {
  return (
    <div>
      <Card className={`${styles.homeAboutUsCardBg} p-5 mb-3`}>
        <Card.Text as={"h1"} className={`my-5 text-white`}>
          <div>
            Welcome to Lyrics Web - Your Ultimate Source for Song Lyrics and
            Music News
          </div>
          {/* <div className="mt-3">Welcome to Lyricsweb!</div> */}
        </Card.Text>
        {/* <Card.Text as={"h1"} className={`my-2 text-white`}>
          Welcome to Lyricsweb!{" "}
        </Card.Text> */}
        <Card.Text className={"w-75"}>
          Lyricsweb.com is your ultimate hub for song lyrics, artist info, and
          album details. Whether you need the perfect lyric, want to learn about
          your favorite artist, or are curious about the latest albums, we’ve
          got you covered. Explore our vast collection and dive into the music
          you love today!
          <br />
        </Card.Text>
        {/* <AppDefaultBtn btnTxt="SUBMIT NEW LYRICS" className={"w-50"} /> */}
      </Card>
    </div>
  );
}

export default HomeAboutUs;
