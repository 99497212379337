import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import CommonLayout from "../../../layouts/CommonLayout";
import PageInfoHeader from "../../../components/PageInfoHeader/PageInfoHeader";
import { StaticImages } from "../../../utils/constants";
import {
  resetSearch,
  searchController,
} from "../../../features/search/searchSlice";
import { getConfig } from "../../../services/apiUtils";
import SearchItemCard from "../../../components/AppCards/SearchItemCard";
import { Col, Row } from "react-bootstrap";
import AppSpinner from "../../../components/Spinners/AppSpinner";
import ErrorAlert from "../../../components/ErrorAlert/ErrorAlert";
import SearchTrackList from "../../../components/AppLists/SearchTrackList";
import SEOManager from "../../../utils/SEOManager";
import { ROUTES } from "../../../routes/constants";

// Helper function to parse query string
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function SearchResultPage() {
  const dispatch = useDispatch();
  const query = useQuery();
  const searchQuery = query.get("searchQuery"); // get the search query parameter

  // Fetch search results based on the query
  useEffect(() => {
    if (searchQuery) {
      let payload = { query: searchQuery };
      dispatch(
        searchController.getSearchResultsQuery({
          payload,
          config: getConfig(),
        })
      );
    }
    // Cleanup on component unmount
    return () => {
      dispatch(resetSearch());
    };
  }, [searchQuery, dispatch]);

  // Access the search results from the Redux store
  const {
    artists,
    albums,
    tracks,
    isLoading,
    error,
    errorStatus,
    errorMessage,
  } = useSelector((state) => state.search);

  return (
    <CommonLayout>
      <SEOManager
        title={`"${searchQuery}" Lyrics, Songs, Albums - LyricsWeb`}
        keywords={searchQuery}
        description={searchQuery}
        page={ROUTES.COMMON.SEARCH_RESULT}
      />
      {/* Page Info Header */}
      <div className="my-2">
        <PageInfoHeader
          text={`Search Results for "${searchQuery.toUpperCase()}"`}
          imageUrl={StaticImages.Banner.News}
        />
      </div>

      {/* Display Search Results */}
      <div className="container">
        {isLoading && (
          <div className="my-5 d-flex justify-content-center align-items-center">
            <AppSpinner size={60} />
          </div>
        )}
        {error && <ErrorAlert error={error} />}
        <div className="my-3">
          {tracks && tracks.length > 0 && (
            <>
              <h3 className="text-appColor1">Tracks</h3>
              <Row>
                <SearchTrackList songs={tracks} />
              </Row>
            </>
          )}
        </div>

        <div className="my-3">
          {artists && artists.length > 0 && (
            <>
              <h3 className="text-appColor1">Artists</h3>
              <Row>
                {artists.map((artist, index) => (
                  <Col lg={4} md={4} sm={6} xs={12} key={index}>
                    <SearchItemCard
                      key={artist.id}
                      item={artist}
                      type="artist"
                    />
                  </Col>
                ))}
              </Row>
            </>
          )}
        </div>

        <div className="my-3">
          {albums && albums.length > 0 && (
            <>
              <h3 className="text-appColor1">Albums</h3>
              <Row>
                {albums.map((album, index) => (
                  <Col lg={4} md={4} sm={6} xs={12} key={index}>
                    <SearchItemCard key={album.id} item={album} type="album" />
                  </Col>
                ))}
              </Row>
            </>
          )}
        </div>
      </div>
    </CommonLayout>
  );
}

export default SearchResultPage;
