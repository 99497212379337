import React from "react";

import CommonLayout from "../../../layouts/CommonLayout";
import PageInfoHeader from "../../../components/PageInfoHeader/PageInfoHeader";
import { StaticImages } from "../../../utils/constants";
import appStyles from "../../../App.module.css";
import SEOManager from "../../../utils/SEOManager";

function PrivacyPolicyPage() {
  return (
    <>
      {/* Common layout for consistent UI */}
      <CommonLayout>
        <SEOManager
          title="Privacy Policy - LyricsWeb"
          ogUrl="www.lyricsweb.com/privacy-policy"
        />
        {/* Page Info Header with title and banner */}
        <div className="my-2">
          <PageInfoHeader
            text="Privacy Policy"
            imageUrl={StaticImages.Banner.News}
          />
        </div>
        {/* Main Content Section */}
        <div className="terms-content p-4">
          <h1 className="fw-bold my-4">
            Privacy <span className={appStyles.textColorHeading}>Policy</span>
          </h1>
          {/* Introduction */}
          <p className="fw-bold">
            Last Updated:{" "}
            <span className={appStyles.textColorHeading}>21 May 2024</span>
          </p>
          <p>
            Welcome to Lyrics Web! This Privacy Policy explains how we collect,
            use, and protect your personal information. By accessing or using
            our website (the "Site") and related services, you agree to the
            terms of this Privacy Policy.
          </p>

          {/* Information Collection Section */}
          <h3 className={appStyles.textColorHeading}>Information We Collect</h3>
          <p>
            We collect personal information such as your name, email address,
            and phone number when you register an account or interact with our
            services. Additionally, we automatically collect device information
            like IP address, browser type, and operating system.
          </p>

          {/* Use of Information Section */}
          <h3 className={appStyles.textColorHeading}>
            How We Use Your Information
          </h3>
          <p>
            We use the collected information to provide, maintain, and improve
            our services. This includes communicating with you, personalizing
            your experience, and sending promotional content.
          </p>

          {/* Information Sharing Section */}
          <h3 className={appStyles.textColorHeading}>
            Information Sharing and Disclosure
          </h3>
          <p>
            We may share your personal information with trusted third-party
            service providers to assist us in delivering our services. We may
            also disclose information in response to legal requests or to
            protect our rights and interests.
          </p>

          {/* Data Retention Section */}
          <h3 className={appStyles.textColorHeading}>Data Retention</h3>
          <p>
            We retain your personal information only for as long as necessary to
            fulfill the purposes outlined in this Privacy Policy, unless a
            longer retention period is required by law.
          </p>

          {/* Security Measures Section */}
          <h3 className={appStyles.textColorHeading}>Security Measures</h3>
          <p>
            We employ industry-standard security measures to protect your
            personal information from unauthorized access, disclosure,
            alteration, or destruction.
          </p>

          {/* Your Choices Section */}
          <h3 className={appStyles.textColorHeading}>Your Choices</h3>
          <p>
            You have the right to access, correct, or delete your personal
            information. You can also opt-out of receiving promotional
            communications from us.
          </p>

          {/* Updates to this Policy Section */}
          <h3 className={appStyles.textColorHeading}>Updates to this Policy</h3>
          <p>
            We may update this Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            Please review this Privacy Policy periodically for updates.
          </p>

          {/* Contact Information Section */}
          <h3 className={appStyles.textColorHeading}>Contact Information</h3>
          <p>
            If you have any questions or concerns about this Privacy Policy,
            please contact us at [your contact information].
          </p>
        </div>
      </CommonLayout>
    </>
  );
}

export default PrivacyPolicyPage;
