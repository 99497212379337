import React from "react";
import { Card } from "react-bootstrap";
import { StaticImages } from "../../utils/constants";
import AppDefaultBtn from "../AppButtons/AppDefaultBtn";
import AppLazyImage from "../AppLazyImage/AppLazyImage";

function AddToChromeCard() {
  const styles = {
    addToChromeCard: {
      backgroundImage: `url(${StaticImages.App.AppAddToChromeBg})`,
      padding: "20px", // Add padding here as part of the style object
      backgroundSize: "cover", // Ensure the background image covers the card
      backgroundPosition: "center", // Center the background image
      color: "white", // Add text color if needed for better visibility
      fontWeight: "bold",
    },
  };

  return (
    <div>
      <Card style={styles.addToChromeCard}>
        <div className="d-flex justify-content-center align-items-center">
          <Card.Text className="fs-6">FREE NO SIGN UP REQUIRED</Card.Text>
          <AppLazyImage
            src={StaticImages.App.ChromeIcon}
            height={30}
            width={30}
          />
        </div>
        <Card.Text className="fs-5">ADD TO CHROME</Card.Text>
        <AppDefaultBtn
          btnTxt="TWO CLICK INSTALL >>"
          size={"sm"}
          className={"fw-bold"}
        />
      </Card>
    </div>
  );
}

export default AddToChromeCard;
