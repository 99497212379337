import React from "react";
import "./AppSpinner.css"; // Import the styles

const AppSpinner = ({ size = 40 }) => {
  return (
    <div className="sk-chase" style={{ width: size, height: size }}>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
    </div>
  );
};

export default AppSpinner;
