// components/ErrorBoundary.jsx
import React, { useEffect } from "react";
import ErrorPage from "./ErrorPage";
import useErrorBoundary from "../../hooks/useErrorBoundary";

const AppErrorBoundary = ({ children }) => {
  const { hasError, handleError } = useErrorBoundary();

  useEffect(() => {
    const errorHandler = (error, errorInfo) => {
      handleError(error, errorInfo);
    };

    // Attach global error handler
    window.addEventListener("error", errorHandler);
    window.addEventListener("unhandledrejection", errorHandler);

    // Clean up event listeners on unmount
    return () => {
      window.removeEventListener("error", errorHandler);
      window.removeEventListener("unhandledrejection", errorHandler);
    };
  }, [handleError]);

  if (hasError) {
    // Fallback UI
    return (
      <div className="error-ui">
        {/* Something went wrong. Please try again later. */}
        <ErrorPage />
      </div>
    );
  }

  return children;
};

export default AppErrorBoundary;
