import React from "react";
import { Image } from "react-bootstrap";
import PropTypes from "prop-types";

function SpotifyPoweredLogo({
  poweredByText = "Powered by", // Default text
  logoSrc,
  logoAlt = "Logo", // Default alt text
  logoHeight = 20, // Default logo height
  customClass = "", // Optional custom class
}) {
  return (
    <>
      <div className={`d-flex align-items-center ${customClass}`}>
        <span className="me-2">{poweredByText}</span>
        <Image
          src={logoSrc}
          alt={logoAlt}
          height={logoHeight}
          //   className="img-fluid"
        />
      </div>
    </>
  );
}

SpotifyPoweredLogo.propTypes = {
  poweredByText: PropTypes.string,
  logoSrc: PropTypes.string.isRequired, // Make logo source required
  logoAlt: PropTypes.string,
  logoHeight: PropTypes.number,
  customClass: PropTypes.string,
};

export default SpotifyPoweredLogo;
