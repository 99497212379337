import React from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { format } from "date-fns";
import { motion } from "framer-motion";

import AppDefaultBtn from "../AppButtons/AppDefaultBtn";
import appStyles from "../../App.module.css";

function NewsCard({
  imageUrl = "https://via.placeholder.com/150",
  title = "Default Title",
  description = "Default description text that will be truncated after two lines...",
  artistName = "Default Artist",
  publishedDate = "N/A",
  buttonText = "Click Here",
  onButtonClick = null,
  news = null,
}) {
  const formattedDate = publishedDate
    ? format(new Date(publishedDate), "MMM d, yyyy")
    : "N/A";

  const cardVariants = {
    initial: { opacity: 0.8, scale: 1 },
    hover: { opacity: 1, scale: 1.05 },
    tap: { scale: 0.98 },
  };

  return (
    <motion.div
      variants={cardVariants}
      initial="initial"
      whileHover="hover"
      whileTap="tap"
      className="p-2"
    >
      <Card
        className="p-2"
        style={{
          minHeight: "40vh",
          height: "100%",
          fontSize: "14px",
          backgroundColor: "var(--appColor6)",
        }}
      >
        <div
          style={{
            height: "20vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <Image
            src={news?.coverImg || imageUrl}
            alt={news?.title || title}
            className="card-img-top"
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
        </div>
        <Card.Body className="d-flex flex-column">
          <Card.Title className={`my-3 ${appStyles.truncatedTitle}`}>
            {title}
          </Card.Title>
          <div className="my-3 fw-bold">
            <Row>
              <Col>
                <span className={`${appStyles.textColorHeading}`}>
                  {artistName}
                </span>
              </Col>
              <Col>
                <span className={`${appStyles.textColorHeading}`}>
                  {formattedDate}
                </span>
              </Col>
            </Row>
          </div>
          <div
            className={`my-3 ${appStyles.truncatedDescription}`}
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
          <div className="mt-auto">
            <AppDefaultBtn
              btnTxt={buttonText}
              onClick={onButtonClick}
              className={`${appStyles.appDefaultBtnDark} my-3`}
            />
          </div>
        </Card.Body>
      </Card>
    </motion.div>
  );
}

// Define prop types for the component
NewsCard.propTypes = {
  imageUrl: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  artistName: PropTypes.string,
  publishedDate: PropTypes.string,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
  news: PropTypes.shape({
    coverImg: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    author: PropTypes.string,
    publishDate: PropTypes.string,
  }),
};

export default NewsCard;
