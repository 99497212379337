import React from "react";
import * as yup from "yup";
import { Col, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import CommonLayout from "../../../layouts/CommonLayout";
import PageInfoHeader from "../../../components/PageInfoHeader/PageInfoHeader";
import { StaticImages } from "../../../utils/constants";
import appStyles from "../../../App.module.css";
import AppForm from "../../../components/AppForm/AppForm";
import { getAuthConfig, getConfig } from "../../../services/apiUtils";
import { feedbackController } from "../../../features/feedback/feedbackSlice";
import SEOManager from "../../../utils/SEOManager";

const contactFormFields = [
  { name: "name", label: "Name", type: "text", placeholder: "Enter your name" },
  {
    name: "email",
    label: "Email",
    type: "email",
    placeholder: "Enter your email",
  },
  {
    name: "subject",
    label: "Subject",
    type: "select",
    placeholder: "Select an option",
    options: [
      { value: "suggestions", label: "Suggestions" },
      { value: "comments", label: "Comments" },
      { value: "concerns", label: "Concerns" },
    ],
  },
  {
    name: "message",
    label: "Message",
    as: "textarea",
    rows: 7,
    placeholder: "Enter your message",
  },
];

const contactFormSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  subject: yup
    .string()
    .required("Subject is required")
    .oneOf(
      ["suggestions", "comments", "concerns"],
      "Please select a valid option"
    ),
  message: yup.string().required("Message is required"),
});

function ContactUsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { feedbackList, isLoading, error } = useSelector(
    (state) => state.feedback
  );

  const onSubmit = async (data, resetForm) => {
    try {
      const { status } = await dispatch(
        feedbackController.submitUserFeedback({
          payload: data,
          config: getAuthConfig(),
          resetForm,
          navigate,
        })
      ).unwrap();
    } catch (error) {
      console.error("Error Submitting Feedback User:", error);
    } finally {
    }
  };

  const submitButtonProps = {
    btnTxt: "Send",
    className: `${appStyles.appDefaultBtn} ${
      isLoading ? appStyles.appDefaultBtnDisabled : ""
    } w-50`,
    isLoading: isLoading,
    // isLoadingText: "Loading...",
  };

  const cancelButtonProps = {
    btnTxt: "Cancel",
    className: `${appStyles.appDefaultBtnDark} w-50`,
  };

  return (
    <>
      <CommonLayout>
        <SEOManager
          title="Contact Us - LyricsWeb"
          ogUrl="www.lyricsweb.com/contact-us"
        />
        {/* Page Info Header */}
        <div className="mt-2">
          <PageInfoHeader
            text="Get In Touch"
            imageUrl={StaticImages.Banner.ContactUs}
          />
        </div>
        {/* Main Content */}
        <div className="p-4">
          <h1 className="fw-bold my-1">
            Have{" "}
            <span className={appStyles.textColorHeading}>
              Something to Share?
            </span>
          </h1>
          <div>
            Fill out the form below to send us your suggestions, comments, or
            concerns. We meticulously review every message we receive and
            endeavor to respond promptly to each inquiry.
          </div>

          <div className="mt-5">
            <Row className="m-0 p-1">
              <Col lg={8} md={8} sm={12} xs={12} className="mb-4">
                <AppForm
                  fields={contactFormFields}
                  validationSchema={contactFormSchema}
                  onSubmit={onSubmit}
                  submitButtonProps={submitButtonProps}
                  cancelButtonProps={cancelButtonProps}
                />
              </Col>
              <Col lg={4} md={4} sm={12} xs={12}>
                <Image
                  src={StaticImages.Pages.GetInTouchImg}
                  height={"100%"}
                  width={"100%"}
                  alt="Get in Touch Image"
                  style={{ objectFit: "cover" }}
                />
              </Col>
            </Row>
          </div>
        </div>
      </CommonLayout>
    </>
  );
}

export default ContactUsPage;
