// src/routes/authRoutes.js

import React from "react";
import { Route } from "react-router-dom";
import { ROUTES } from "./constants";

/* Auth Pages */
import LoginPage from "../pages/auth/LoginPage/LoginPage";
import SignupPage from "../pages/auth/SignupPage/SignupPage";
import ForgotPasswordPage from "../pages/auth/ForgotPasswordPage/ForgotPasswordPage";
import ResetPasswordPage from "../pages/auth/ResetPasswordPage/ResetPasswordPage";
import ProfilePage from "../pages/auth/ProfilePage/ProfilePage";
import ChangePasswordPage from "../pages/auth/ChangePasswordPage/ChangePasswordPage";
import ProtectedRoute from "./ProtectedRoute";
import VerifyUserPage from "../pages/auth/VerifyUserPage/VerifyUserPage";

const authRoutes = [
  <Route key="login" path={ROUTES.AUTH.LOGIN} element={<LoginPage />} />,
  <Route key="signup" path={ROUTES.AUTH.SIGNUP} element={<SignupPage />} />,
  <Route key="verify" path={ROUTES.AUTH.VERIFY} element={<VerifyUserPage />} />,
  <Route
    key="forgot-password"
    path={ROUTES.AUTH.FORGOT_PASSWORD}
    element={<ForgotPasswordPage />}
  />,
  <Route
    key="reset-password"
    path={ROUTES.AUTH.RESET_PASSWORD}
    element={<ResetPasswordPage />}
  />,
  <Route
    key="profile"
    path={ROUTES.AUTH.PROFILE}
    element={<ProtectedRoute element={ProfilePage} />}
  />,
  <Route
    key="change-password"
    path={ROUTES.AUTH.CHANGE_PASSWORD}
    element={<ProtectedRoute element={ChangePasswordPage} />}
  />,
];

export default authRoutes;
