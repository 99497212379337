import React, { useEffect, useState } from "react";
import CommonLayout from "../../../layouts/CommonLayout";
import PageInfoHeader from "../../../components/PageInfoHeader/PageInfoHeader";
import { BannerNews, StaticImages } from "../../../utils/constants";
import { Card, Spinner } from "react-bootstrap";
import appStyles from "../../../App.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { getConfig } from "@testing-library/react";
import { useDispatch, useSelector } from "react-redux";
import { authController } from "../../../features/auth/authSlice";
import SEOManager from "../../../utils/SEOManager";

function VerifyUserPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, error } = useSelector((state) => state.auth);

  const { verifyToken } = useParams();
  const [verificationResult, setVerificationResult] = useState(null);

  useEffect(() => {
    const verifyUser = async () => {
      try {
        let { status } = await dispatch(
          authController.verifyUser({
            payload: { token: verifyToken },
            config: getConfig(),
            navigate,
          })
        ).unwrap();

        if (status === 200) {
          setVerificationResult(true);
        } else {
          setVerificationResult(false);
        }
      } catch (error) {
        setVerificationResult(false);
      } finally {
      }
    };

    verifyUser();
  }, [verifyToken, navigate, dispatch]);

  return (
    <CommonLayout>
      <SEOManager
        title="Verify Your Account - LyricsWeb"
        ogUrl={`www.lyricsweb.com/auth/verify/${verifyToken}`}
      />
      {/* Page Info Header */}
      <div className="my-2">
        <PageInfoHeader
          text="Verification Page"
          imageUrl={StaticImages.Banner.News}
        />
      </div>
      <div className="my-5">
        <Card
          className={`p-5 text-center`}
          style={{ backgroundColor: "var(--appColor5)" }}
        >
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <Spinner animation="border" />
            </div>
          ) : (
            <>
              {verificationResult === true ? (
                <Card.Text className="fw-bold fs-2 text-success">
                  <div>
                    Your account has been successfully verified.
                    <span className="text-black">Welcome to the </span>
                    <span className={`${appStyles.textColorHeading}`}>
                      Lyrics Web
                    </span>
                  </div>
                </Card.Text>
              ) : (
                <Card.Text className="fw-bold fs-2 text-danger">
                  <div>
                    Verification failed. Please check the link or try again
                    later.
                  </div>
                </Card.Text>
              )}
            </>
          )}
        </Card>
      </div>
    </CommonLayout>
  );
}

export default VerifyUserPage;
