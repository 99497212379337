import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  Tab,
  Tabs,
  Image,
  Row,
  Col,
  Badge,
  Button,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import appStyles from "../../App.module.css";
import { commentController } from "../../features/comment/commentSlice";
import { getAuthConfig, getConfig } from "../../services/apiUtils";
import { convertDate } from "../../utils/helpers";
import AppForm from "../AppForm/AppForm";
import AppSpinner from "../Spinners/AppSpinner";
import AppDefaultBtn from "../AppButtons/AppDefaultBtn";
import { ROUTES } from "../../routes/constants";

const commentFormFields = [
  {
    name: "comment",
    label: "Comment",
    as: "textarea",
    rows: 2,
    placeholder: "Add Comment Here...",
  },
];

const commentFormSchema = yup.object().shape({
  comment: yup
    .string()
    .required("Message is required")
    .max(500, "Comment cannot be more than 500 characters"),
});

function CommentsList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isrcKey } = useParams();

  const {
    commentList,
    isFetchingComment,
    isModifyingComment,
    error,
    currentPage,
    totalPages,
    totalComments,
  } = useSelector((state) => state.comment);

  const loggedInUserId = useSelector((state) => state.auth.user?.id);

  const [key, setKey] = useState("newest");
  const [isSubmitting, setIsSubmitting] = useState(false); // Loading state for form submission

  const fetchUserComments = useCallback(
    async (page = 1) => {
      let payload = { isrc: isrcKey, page };
      try {
        await dispatch(
          commentController.getUserCommentsList({
            payload,
            config: getConfig(),
          })
        ).unwrap();
      } catch (error) {
        console.error("Error Fetching Comments:", error);
      }
    },
    [dispatch, isrcKey]
  );

  useEffect(() => {
    fetchUserComments();
  }, [fetchUserComments, isrcKey]);

  const onSubmit = async (data, resetForm) => {
    setIsSubmitting(true); // Start loading
    try {
      await dispatch(
        commentController.addUserComment({
          payload: { ...data, isrc: isrcKey },
          config: getAuthConfig(),
          resetForm,
          navigate,
        })
      ).unwrap();
      fetchUserComments(); // Refetch comments after submission
    } catch (error) {
      console.error("Error Submitting Comment:", error);
    } finally {
      setIsSubmitting(false); // Stop loading
    }
  };

  const onDeleteComment = async (commentId) => {
    try {
      await dispatch(
        commentController.deleteUserComment({
          payload: { commentId },
          config: getAuthConfig(),
        })
      ).unwrap();
      fetchUserComments(); // Refetch comments after deletion
    } catch (error) {
      console.error("Error Deleting Comment:", error);
    }
  };

  const submitButtonProps = {
    btnTxt: "Publish",
    className: `${appStyles.appDefaultBtn} ${
      isSubmitting || isModifyingComment ? appStyles.appDefaultBtnDisabled : ""
    } w-50`,
    isLoading: isSubmitting, // Handle loading state here
  };

  const cancelButtonProps = {
    btnTxt: "Cancel",
    className: `${appStyles.appDefaultBtnDark} w-50`,
  };

  const loadMoreComments = () => {
    if (currentPage < totalPages) {
      fetchUserComments(currentPage + 1);
    }
  };

  const handleLoginRedirect = () => {
    navigate(ROUTES.AUTH.LOGIN);
  };

  return (
    <>
      <div>
        <h3 className="text-uppercase fw-bold text-center mb-5">
          User <span className={appStyles.textColorHeading}>Comments</span>
        </h3>
        <div>
          <Card
            style={{
              position: "relative",
              backgroundColor: "var(--appColor6)",
              borderRadius: "15px",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            }}
          >
            <Card.Body className={`p-5`}>
              <div className="mb-3">
                {loggedInUserId ? (
                  <AppForm
                    fields={commentFormFields}
                    validationSchema={commentFormSchema}
                    onSubmit={onSubmit}
                    submitButtonProps={submitButtonProps}
                    cancelButtonProps={cancelButtonProps}
                  />
                ) : (
                  <Button
                    className={`${appStyles.appDefaultBtn} w-100`}
                    onClick={handleLoginRedirect}
                  >
                    Login to Comment
                  </Button>
                )}
              </div>

              {isFetchingComment && (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "80vh" }}
                >
                  <AppSpinner size={60} />
                </div>
              )}

              {error && (
                <div
                  className="fw-bold text-center text-appColor1 my-4"
                  role="alert"
                >
                  {error.message}
                </div>
              )}

              {!isFetchingComment && !error && (
                <>
                  <div>
                    <div className={`${appStyles.textColorHeading} fw-bold`}>
                      {totalComments} Comments
                    </div>
                  </div>
                  <div className="my-3">
                    <Tabs
                      id="controlled-tab-comments"
                      activeKey={key}
                      onSelect={(k) => setKey(k)}
                      className="m-0 p-0"
                    >
                      <Tab eventKey="newest" title="Newest">
                        {commentList &&
                          commentList
                            .slice()
                            .sort(
                              (a, b) =>
                                new Date(b.createdAt) - new Date(a.createdAt)
                            )
                            .map((comment, index) => (
                              <div key={index} className="mt-4">
                                <Card
                                  className="p-2"
                                  style={{
                                    borderRadius: "15px",
                                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                                  }}
                                >
                                  <Card.Body>
                                    <Row>
                                      <Col lg={2}>
                                        {comment.user && (
                                          <Image
                                            src={comment.user.avatar}
                                            height={"80px"}
                                            width={"80px"}
                                            alt="Comment User Avatar"
                                            fluid
                                            rounded
                                          />
                                        )}
                                      </Col>
                                      <Col lg={10}>
                                        <div className="d-flex justify-content-between align-items-center mb-2">
                                          <div>
                                            <h6 className="mb-1">
                                              {`${comment.user.firstName} ${comment.user.lastName}`}
                                            </h6>
                                            <small className="text-muted">
                                              {convertDate(comment.createdAt)}
                                            </small>
                                          </div>
                                          <Badge bg="appColor1">User</Badge>
                                          {loggedInUserId &&
                                            comment.user._id ===
                                              loggedInUserId && (
                                              <AppDefaultBtn
                                                btnTxt="delete"
                                                size="sm"
                                                variant="danger"
                                                onClick={() =>
                                                  onDeleteComment(comment._id)
                                                }
                                              />
                                            )}
                                        </div>
                                        <p>{comment.comment}</p>
                                      </Col>
                                    </Row>
                                  </Card.Body>
                                </Card>
                              </div>
                            ))}
                      </Tab>
                      <Tab eventKey="oldest" title="Oldest">
                        {commentList &&
                          commentList
                            .slice()
                            .sort(
                              (a, b) =>
                                new Date(a.createdAt) - new Date(b.createdAt)
                            )
                            .map((comment, index) => (
                              <div key={index} className="mt-4">
                                <Card
                                  className="p-2"
                                  style={{
                                    borderRadius: "15px",
                                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                                  }}
                                >
                                  <Card.Body>
                                    <Row>
                                      <Col lg={2}>
                                        {comment.user && (
                                          <Image
                                            src={comment.user.avatar}
                                            height={"80px"}
                                            width={"80px"}
                                            alt="Comment User Avatar"
                                            fluid
                                            rounded
                                          />
                                        )}
                                      </Col>
                                      <Col lg={10}>
                                        <div className="d-flex justify-content-between align-items-center mb-2">
                                          <div>
                                            <h6 className="mb-1">
                                              {`${comment.user.firstName} ${comment.user.lastName}`}
                                            </h6>
                                            <small className="text-muted">
                                              {convertDate(comment.createdAt)}
                                            </small>
                                          </div>
                                          <Badge bg="appColor1">User</Badge>
                                          {loggedInUserId &&
                                            comment.user._id ===
                                              loggedInUserId && (
                                              <AppDefaultBtn
                                                btnTxt="delete"
                                                size="sm"
                                                variant="danger"
                                                onClick={() =>
                                                  onDeleteComment(comment._id)
                                                }
                                              />
                                            )}
                                        </div>
                                        <p>{comment.comment}</p>
                                      </Col>
                                    </Row>
                                  </Card.Body>
                                </Card>
                              </div>
                            ))}
                      </Tab>
                    </Tabs>
                  </div>
                  {currentPage < totalPages && (
                    <div className="text-center mt-4">
                      <Button
                        className={`${appStyles.appDefaultBtn}`}
                        onClick={loadMoreComments}
                      >
                        Load More Comments
                      </Button>
                    </div>
                  )}
                </>
              )}
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}

export default CommentsList;
