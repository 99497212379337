import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import "react-toastify/dist/ReactToastify.min.css";

import AppRoutes from "./routes/routes";
import useScrollToTop from "./hooks/useScrollToTop";
import withSessionTimeout from "./utils/withSessionTimeoutHOC";
import { removeAuthToken, setAuthToken } from "./utils/authHelpers";
import { lyricsController } from "./features/lyrics/lyricsSlice";
import { getConfig } from "./services/apiUtils";

const ScrollToTopWrapper = ({ children }) => {
  useScrollToTop();
  return children;
};

function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  const { user, isLoading, error } = useSelector((state) => state.auth);
  const { hotAlbums, topCharts } = useSelector((state) => state.lyrics);
  const [isPasscodeValid, setIsPasscodeValid] = useState(false);
  const [isPasscodeChecked, setIsPasscodeChecked] = useState(false);

  useEffect(() => {
    if (user) {
      setAuthToken(user.token);
    } else {
      removeAuthToken();
    }
  }, [user, isLoading, error]);

  useEffect(() => {
    dispatch(lyricsController.getTopCharts({ config: getConfig() }));
    dispatch(lyricsController.getHotAlbums({ config: getConfig() }));
  }, [dispatch]);

  const handlePasscodeSubmit = (isValid) => {
    setIsPasscodeValid(isValid);
    setIsPasscodeChecked(true);
  };

  useEffect(() => {
    // This sends a pageview event to Google Analytics
    window.gtag("config", "G-7P88QXT9CV", {
      page_path: location.pathname + location.search,
    });
  }, [location]);

  return (
    <div>
      <ScrollToTopWrapper>
        {/* {!isPasscodeChecked ? (
            <AuthorizationCheck onPasscodeSubmit={handlePasscodeSubmit} />
          ) : isPasscodeValid ? (
            <AppRoutes />
          ) : (
            <Unauthorized />
          )} */}
        <AppRoutes />
      </ScrollToTopWrapper>

      <ToastContainer
        stacked
        closeOnClick
        position="top-center"
        autoClose="2000"
        theme="colored"
        transition={Slide}
        hideProgressBar={true}
        newestOnTop={false}
        rtl={false}
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default withSessionTimeout(App);
