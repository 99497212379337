import React from "react";

import CommonLayout from "../../../layouts/CommonLayout";
import PageInfoHeader from "../../../components/PageInfoHeader/PageInfoHeader";
import appStyles from "../../../App.module.css";
import SEOManager from "../../../utils/SEOManager";
import { StaticImages } from "../../../utils/constants";

// Reusable component for section headers
const SectionHeader = ({ text }) => (
  <div className={`${appStyles.textColorHeading} fw-bold fs-4`}>{text}</div>
);

// Reusable component for section content
const SectionContent = ({ children }) => <p>{children}</p>;

// Reusable component for list items
const ListItem = ({ text }) => <li>{text}</li>;

// Reusable component for list sections
const ListSection = ({ items }) => (
  <ul>
    {items.map((item, index) => (
      <ListItem key={index} text={item} />
    ))}
  </ul>
);

// Main component
function TermsOfUsePage() {
  // Terms content
  const termsContent = {
    title: "Terms of Use",
    lastUpdated: "21 May 2024",
    sections: [
      {
        heading: "Acceptance of Terms",
        content:
          "By using the Site, you signify your acceptance of these Terms and our Privacy Policy. If you do not agree to these Terms, please do not use our Site. Your continued use of the Site following the posting of changes to these Terms will mean you accept those changes.",
      },
      {
        heading: "Changes to Terms",
        content:
          "We reserve the right to modify these Terms at any time, and we will notify you of any changes by updating the 'Last Updated' date at the top of this page. It is your responsibility to review these Terms periodically. Your continued use of the Site after any modifications indicates your acceptance of the new Terms.",
      },
      {
        heading: "Use of the Site",
        content:
          "You may use the Site for personal and non-commercial purposes only. You agree not to use the Site for any unlawful purpose or any purpose prohibited under this clause. You agree not to use the Site in any way that could damage the Site, its content, or its availability.",
      },
      {
        heading: "User Content",
        content:
          "You may be able to post content, including lyrics, comments, and other materials (collectively, 'User Content') on the Site. You retain ownership of your User Content, but by posting, you grant us a worldwide, non-exclusive, royalty-free, transferable license to use, reproduce, distribute, prepare derivative works of, display, and perform the User Content in connection with the Site and our business, including for promoting and redistributing part or all of the Site in any media formats and through any media channels.",
      },
      {
        heading: "Intellectual Property",
        content:
          "All content on the Site, including text, graphics, logos, images, and software, is the property of Lyrics Web or its content suppliers and is protected by intellectual property laws. Unauthorized use of any materials on the Site is strictly prohibited. You may not reproduce, distribute, display, sell, lease, transmit, create derivative works from, or otherwise exploit any content on the Site for any purpose without our express written consent.",
      },
      {
        heading: "Copyright Policy",
        content:
          "If you believe that your copyrighted work has been copied in a way that constitutes copyright infringement, please contact us at [your contact information] with the following information:",
      },
      {
        heading: "Third-Party Links",
        content:
          "The Site may contain links to third-party websites that are not owned or controlled by Lyrics Web. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites. By using the Site, you expressly release us from any and all liability arising from your use of any third-party website.",
      },
      {
        heading: "Disclaimers",
        content:
          "The Site is provided on an 'as is' and 'as available' basis. We make no warranties, expressed or implied, and hereby disclaim and negate all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.",
      },
      {
        heading: "Limitation of Liability",
        content:
          "In no event shall Lyrics Web, its directors, employees, or agents be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the Site; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the Site; (iv) any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Site by any third party; (v) any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Site; and/or (vi) the defamatory, offensive, or illegal conduct of any third party.",
      },
      {
        heading: "Indemnification",
        content:
          "You agree to indemnify, defend, and hold harmless Lyrics Web, its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorney's fees, arising out of or in any way connected with (i) your access to or use of the Site; (ii) your violation of these Terms; (iii) your violation of any third-party right, including without limitation any intellectual property right, publicity, confidentiality, property, or privacy right; or (iv) any claim that your User Content caused damage to a third party.",
      },
      {
        heading: "Governing Law",
        content:
          "These Terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law principles. You agree to submit to the personal and exclusive jurisdiction of the courts located in [Your Jurisdiction] to resolve any dispute or claim arising from these Terms.",
      },
      {
        heading: "Termination",
        content:
          "We reserve the right to terminate your access to the Site, without any advance notice, for conduct that we believe violates these Terms or is harmful to other users of the Site, us, or third parties, or for any other reason in our sole discretion.",
      },
      {
        heading: "Contact Us",
        content:
          "If you have any questions about these Terms, please contact us at:",
      },
    ],
    listSections: [
      {
        heading: "Use Restrictions",
        items: [
          "Use the Site to engage in any illegal activities or conduct that is fraudulent, abusive, or harmful.",
          "Upload, post, or otherwise transmit any content that infringes any patent, trademark, trade secret, copyright, or other proprietary rights of any party.",
          "Disrupt or interfere with the security of, or otherwise cause harm to, the Site, systems resources, accounts, passwords, servers, or networks connected to or accessible through the Site.",
          "Create a false identity or impersonate another person or entity in any way.",
          "Use any automated means to access the Site for any purpose without our express written permission.",
        ],
      },
    ],
  };

  return (
    <CommonLayout>
      <SEOManager
        title="Terms of Use - LyricsWeb"
        ogUrl="www.lyricsweb.com/terms-of-use"
      />

      {/* Page Info Header */}
      <div className="my-2">
        <PageInfoHeader
          text={termsContent.title}
          imageUrl={StaticImages.Banner.News}
        />
      </div>

      {/* Main Content */}
      <div className="p-4">
        <h1 className="fw-bold my-4">
          Terms of <span className={appStyles.textColorHeading}>Use</span>
        </h1>
        <div className="terms-content">
          <p className="fw-bold">
            Last Updated:{" "}
            <span className={appStyles.textColorHeading}>
              {termsContent.lastUpdated}
            </span>
          </p>
          {termsContent.sections.map((section, index) => (
            <div key={index}>
              <SectionHeader text={section.heading} />
              <SectionContent>{section.content}</SectionContent>
            </div>
          ))}
          {termsContent.listSections.map((section, index) => (
            <div key={index}>
              <SectionHeader text={section.heading} />
              <ListSection items={section.items} />
            </div>
          ))}
        </div>
      </div>
    </CommonLayout>
  );
}

export default TermsOfUsePage;
