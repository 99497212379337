import React from "react";
import { Col, Image, Row } from "react-bootstrap";

import CommonLayout from "../../../layouts/CommonLayout";
import PageInfoHeader from "../../../components/PageInfoHeader/PageInfoHeader";
import { StaticImages } from "../../../utils/constants";
import appStyles from "../../../App.module.css";
import SEOManager from "../../../utils/SEOManager";

function AboutPage() {
  return (
    <>
      <CommonLayout>
        <SEOManager
          title="About Us - LyricsWeb"
          ogUrl="www.lyricsweb.com/about"
        />

        {/* Page Info Header */}
        <div className="my-2">
          <PageInfoHeader
            text="About Us"
            imageUrl={StaticImages.Banner.AboutUs}
          />
        </div>
        {/* Main Content */}
        <div className="p-4">
          <div>
            <Row className="m-0 p-0">
              <Col lg={9} md={9} sm={12} xs={12} className="pe-5">
                <h1 className="my-2">
                  <span className={`fw-bold`}>
                    About Us{" "}
                    <span className={appStyles.textColorHeading}>
                      Lyrics Web
                    </span>
                  </span>
                </h1>
              </Col>
            </Row>
            <Row className="m-0 p-0">
              <Col lg={9} md={9} sm={12} xs={12} className="pe-5">
                <div>
                  <div className="fw-bold">
                    Welcome to LyricsWeb - the world's leading music database!
                  </div>
                  <br />
                  LyricsWeb is a premier online destination dedicated to
                  providing comprehensive and up-to-date information on a wide
                  range of songs from various genres. Established with the aim
                  of offering users convenient and high-quality access to the
                  world's top musical content, LyricsWeb boasts impressive
                  databases and advanced technological features that enable
                  quick and efficient searches for desired information.
                  <br />
                  <br />
                  Our extensive databases are updated daily and include lyrics
                  to songs from over a million artists and tens of millions of
                  songs. With our commitment to quality and accuracy, every word
                  is carefully curated for proper usage, ensuring an excellent
                  and reliable user experience.
                </div>
                <div>
                  <Row className="m-0 p-0">
                    <Col lg={8} md={8} sm={12} xs={12} className="my-4">
                      <Image
                        src={StaticImages.Pages.AboutAboutUsImg1}
                        className="w-100 h-100"
                        alt="Above Us Image"
                      />
                    </Col>
                    <Col lg={4} md={4} sm={12} xs={12} className="my-4">
                      <Image
                        src={StaticImages.Pages.AboutAboutUsImg2}
                        className="w-100 h-100"
                        alt="Above Us Image"
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={3} md={3} sm={12} xs={12}></Col>
            </Row>
          </div>

          <div className="my-5">
            <Row className="m-0 p-0">
              <Col lg={3} md={3} sm={12} xs={12}>
                ads
              </Col>
              <Col lg={9} md={9} sm={12} xs={12} className="m-0 pe-5">
                <div>
                  <div className="mb-4">
                    We place great emphasis on our valued visitors and focus on
                    creating a seamless and easy user experience. Navigating our
                    site is simple and user-friendly, with users enjoying a
                    friendly interface and a variety of advanced search options
                    that help them find the songs they're looking for quickly
                    and easily.
                  </div>
                  <div>
                    Our goal at LyricsWeb is to distribute information freely to
                    music-loving audiences and to make it accessible in the best
                    and highest quality manner possible. We are committed to
                    providing an invaluable resource to music enthusiasts by
                    offering a vast collection of song lyrics, all available at
                    no cost. Through our platform, we aim to foster a community
                    where music aficionados can explore, discover, and engage
                    with their favorite songs and artists. Our dedication lies
                    in ensuring that music remains a universal language, easily
                    accessible to all who seek it.
                  </div>
                  <br />
                  <div className={`fw-bold`}>
                    Don't hesitate to reach out to us if you have any questions,
                    suggestions, or requests for specific songs. We're here to
                    listen and help!
                    <br />
                    <br />
                    Thank you for your support and visit,
                    <br />
                    <br />
                    The LyricsWeb Team"
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </CommonLayout>
    </>
  );
}

export default AboutPage;
