import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { makeRequest } from "../../services/apiRequests";
import { handleApiError } from "../../services/apiUtils";

/* Artist Section */
/* Async thunk to get artist list */
const getArtistList = createAsyncThunk(
  "artist/getArtistList",
  async ({ payload, config }, { rejectWithValue }) => {
    try {
      const { data, status } = await makeRequest(
        "GET",
        "/user/artist-list",
        payload,
        config
      );

      if (status === 200) {
        return data.data;
      } else {
        toast.error(data.message);
        return rejectWithValue(data.message);
      }
    } catch (error) {
      const errorMessage = handleApiError(error);
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

/* Async thunk to get artist albums */
const getArtistAlbums = createAsyncThunk(
  "artist/getArtistAlbums",
  async ({ payload, config }, { rejectWithValue }) => {
    try {
      const { data, status } = await makeRequest(
        "GET",
        `/user/artist-albums`,
        payload,
        config
      );

      if (status === 200) {
        return data.data;
      } else {
        toast.error(data.message);
        return rejectWithValue(data.message);
      }
    } catch (error) {
      const errorMessage = handleApiError(error);
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

/* Async thunk to get artist by letters */
const getArtistByLetter = createAsyncThunk(
  "artist/getArtistByLetter",
  async ({ payload, config }, { rejectWithValue }) => {
    try {
      const { data, status } = await makeRequest(
        "POST",
        "/user/getArtistsByLetter",
        payload,
        config
      );

      if (status === 200) {
        // toast.success(data.message);
        // console.log(data.data);
        return data.data;
      } else {
        toast.error(data.message);
        return rejectWithValue(data.message);
      }
    } catch (error) {
      const errorMessage = handleApiError(error);
      //   toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

/* Async thunk to get artist albums by spotify */
const getArtistAlbumsSpotify = createAsyncThunk(
  "artist/getArtistAlbumsSpotify",
  async ({ payload, config }, { rejectWithValue }) => {
    try {
      const { data, status } = await makeRequest(
        "GET",
        `/admin/artist/song`,
        payload,
        config
      );

      if (status === 200) {
        return data.data;
      } else {
        toast.error(data.message);
        return rejectWithValue(data.message);
      }
    } catch (error) {
      const errorMessage = handleApiError(error);
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

/* Async thunk to get artist songs by spotify */
const getArtistSongsSpotify = createAsyncThunk(
  "artist/getArtistSongsSpotify",
  async ({ payload, config }, { rejectWithValue }) => {
    try {
      const { data, status } = await makeRequest(
        "GET",
        `/user/songs-of-artist`,
        payload,
        config
      );

      if (status === 200) {
        return data.data;
      } else {
        toast.error(data.message);
        return rejectWithValue(data.message);
      }
    } catch (error) {
      const errorMessage = handleApiError(error);
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

/* Async thunk to get artist details by spotify */
const getArtistDetailsSpotify = createAsyncThunk(
  "artist/getArtistDetailsSpotify",
  async ({ payload, config }, { rejectWithValue }) => {
    try {
      const { data, status } = await makeRequest(
        "GET",
        `/user/artistDetails`,
        payload,
        config
      );

      if (status === 200) {
        return data.data;
      } else {
        toast.error(data.message);
        return rejectWithValue(data.message);
      }
    } catch (error) {
      const errorMessage = handleApiError(error);
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

/* Async thunk to get artist details by spotify */
const getArtistBio = createAsyncThunk(
  "artist/getArtistBio",
  async ({ payload, config }, { rejectWithValue }) => {
    try {
      const { data, status } = await makeRequest(
        "GET",
        `/user/artist-biblio`,
        payload,
        config
      );

      if (status === 200) {
        return data.data;
      } else {
        toast.error(data.message);
        return rejectWithValue(data.message);
      }
    } catch (error) {
      const errorMessage = handleApiError(error);
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

const artistSlice = createSlice({
  name: "artist",
  initialState: {
    artistList: null,
    artistAlbums: null,
    artistSongs: null,
    artistDetails: null,
    artistBio: null,
    isLoading: false,
    isLoadingSongs: false,
    error: null,
  },
  reducers: {
    resetArtistList: (state) => {
      state.artistList = null;
    },
    resetArtistAlbums: (state) => {
      state.artistAlbums = null;
    },
    resetArtistSongs: (state) => {
      state.artistSongs = null;
    },
    resetArtistDetails: (state) => {
      state.artistDetails = null;
    },
    resetArtistBio: (state) => {
      state.artistBio = null;
    },
  },
  extraReducers: (builder) => {
    builder
      /* Get Artist List */
      .addCase(getArtistList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getArtistList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.artistList = action.payload;
      })
      .addCase(getArtistList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      /* Get Artist Albums */
      .addCase(getArtistAlbums.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getArtistAlbums.fulfilled, (state, action) => {
        state.isLoading = false;
        state.artistAlbums = action.payload;
      })
      .addCase(getArtistAlbums.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      /* Get Artist Albums Spotify */
      .addCase(getArtistAlbumsSpotify.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getArtistAlbumsSpotify.fulfilled, (state, action) => {
        state.isLoading = false;
        state.artistAlbums = action.payload;
      })
      .addCase(getArtistAlbumsSpotify.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      /* Get Artist Songs Spotify */
      .addCase(getArtistSongsSpotify.pending, (state) => {
        state.isLoadingSongs = true;
        state.error = null;
      })
      .addCase(getArtistSongsSpotify.fulfilled, (state, action) => {
        state.isLoadingSongs = false;
        state.artistSongs = action.payload;
      })
      .addCase(getArtistSongsSpotify.rejected, (state, action) => {
        state.isLoadingSongs = false;
        state.error = action.payload;
      })
      /* Get Artist By Letter */
      .addCase(getArtistByLetter.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getArtistByLetter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.artistList = action.payload;
      })
      .addCase(getArtistByLetter.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      /* Get Artist Details */
      .addCase(getArtistDetailsSpotify.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getArtistDetailsSpotify.fulfilled, (state, action) => {
        state.isLoading = false;
        state.artistDetails = action.payload;
      })
      .addCase(getArtistDetailsSpotify.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      /* Get Artist Bio */
      .addCase(getArtistBio.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getArtistBio.fulfilled, (state, action) => {
        state.isLoading = false;
        state.artistBio = action.payload;
      })
      .addCase(getArtistBio.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const {
  resetArtistList,
  resetArtistAlbums,
  resetArtistDetails,
  resetArtistBio,
} = artistSlice.actions;

export const artistController = {
  getArtistList,
  getArtistAlbums,
  getArtistAlbumsSpotify,
  getArtistSongsSpotify,
  getArtistByLetter,
  getArtistDetailsSpotify,
  getArtistBio,
};

export default artistSlice.reducer;
