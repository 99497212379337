import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { makeRequest } from "../../services/apiRequests";
import { handleApiError } from "../../services/apiUtils";

/* News Section */
const getNewsList = createAsyncThunk(
  "news/getNewsList",
  async ({ config, navigate }, { rejectWithValue }) => {
    try {
      const { data, status } = await makeRequest(
        "GET",
        "/user/get-news-list",
        config
      );
      if (status === 200) {
        // toast.success(data.message);
        return data.data;
      } else {
        toast.error(data.message);
        return rejectWithValue(data.message);
      }
    } catch (error) {
      const errorMessage = handleApiError(error);
      //   toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

const getNewsDetails = createAsyncThunk(
  "news/getNewsDetails",
  async ({ payload, config }, { rejectWithValue }) => {
    try {
      const { data, status } = await makeRequest(
        "GET",
        `/user/get-news?newsId=${payload.newsId}`,
        config
      );
      if (status === 200) {
        return data.data;
      } else {
        toast.error(data.message);
        return rejectWithValue(data.message);
      }
    } catch (error) {
      const errorMessage = handleApiError(error);
      return rejectWithValue(errorMessage);
    }
  }
);

const newsSlice = createSlice({
  name: "news",
  initialState: {
    newsList: null,
    specificNews: null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      /* Get News List */
      .addCase(getNewsList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getNewsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.newsList = action.payload;
      })
      .addCase(getNewsList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      /* Get Specific News */
      .addCase(getNewsDetails.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getNewsDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.specificNews = action.payload;
      })
      .addCase(getNewsDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const {} = newsSlice.actions;

export const newsController = { getNewsList, getNewsDetails };

export default newsSlice.reducer;
