import React from "react";

import appStyles from "../../../App.module.css";
import CommonLayout from "../../../layouts/CommonLayout";
import PageInfoHeader from "../../../components/PageInfoHeader/PageInfoHeader";
import { StaticImages } from "../../../utils/constants";

function PressPage() {
  return (
    <>
      <CommonLayout>
        {/* Page Info Header */}
        <div className="my-2">
          <PageInfoHeader text={`Press`} imageUrl={StaticImages.Banner.News} />
        </div>
        {/* Main Content */}
        <div className="fs-3 fw-bold my-5">
          Press <span className={appStyles.textColorHeading}>Release</span>
        </div>
      </CommonLayout>
    </>
  );
}

export default PressPage;
