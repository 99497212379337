import React from "react";
import PropTypes from "prop-types";
import { Carousel as ResponsiveCarousel } from "react-responsive-carousel";

import styles from "./CarouselSlider.module.css";
import { StaticImages } from "../../utils/constants";
import AppLazyImage from "../AppLazyImage/AppLazyImage";
import { Image } from "react-bootstrap";

function CarouselSlider({
  images = [
    { src: StaticImages.Banner.Home },
    { src: StaticImages.Banner.AboutUs },
    { src: StaticImages.Banner.Albums },
    { src: StaticImages.Banner.ContactUs },
    { src: StaticImages.Banner.News },
  ],
}) {
  return (
    <div className={styles.container}>
      <ResponsiveCarousel
        autoPlay
        interval={2000}
        infiniteLoop
        showThumbs={false}
        showStatus={false}
        showIndicators
        swipeable
        emulateTouch
        stopOnHover
        animationHandler={"fade"}
      >
        {images &&
          images.map((image, index) => (
            <div key={index} className={styles.slide}>
              <Image
                src={image.src}
                alt={`Slide ${index + 1}`}
                className={styles.image}
              />
            </div>
          ))}
      </ResponsiveCarousel>
    </div>
  );
}

CarouselSlider.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
    })
  ),
};

export default CarouselSlider;
