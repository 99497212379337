import React from "react";
import { Card } from "react-bootstrap";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import CommonLayout from "../../../layouts/CommonLayout";
import PageInfoHeader from "../../../components/PageInfoHeader/PageInfoHeader";
import { StaticImages } from "../../../utils/constants";
import appStyles from "../../../App.module.css";
import AppForm from "../../../components/AppForm/AppForm";
import { ROUTES } from "../../../routes/constants";
import { getConfig } from "../../../services/apiUtils";
import { authController } from "../../../features/auth/authSlice";
import SEOManager from "../../../utils/SEOManager";

const forgotPassFormFields = [
  {
    name: "email",
    label: "Email Address",
    type: "text",
    placeholder: "Enter your Email Address",
  },
];

const forgotPassFormSchema = yup.object().shape({
  email: yup.string().required("Email Address is Required"),
});

function ForgotPasswordPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, error } = useSelector((state) => state.auth);

  const onSubmit = async (data, resetForm) => {
    try {
      await dispatch(
        authController.forgetPassword({
          payload: data,
          config: getConfig(),
          resetForm,
          navigate,
        })
      ).unwrap();
    } catch (error) {
      console.error("Error Forgetting Password:", error);
    } finally {
    }
  };

  const submitButtonProps = {
    btnTxt: isLoading ? "Signing Up..." : "Forget Password",
    className: `${appStyles.appDefaultBtn} ${
      isLoading ? appStyles.appDefaultBtnDisabled : ""
    } w-50`,
    isLoading: isLoading,
    isLoadingText: "Loading...",
  };

  const cancelButtonProps = {
    btnTxt: "Login",
    className: `${appStyles.appDefaultBtnDark} w-50`,
    onClick: () => navigate(ROUTES.AUTH.LOGIN),
  };

  return (
    <>
      <CommonLayout>
        <SEOManager
          title="Forgot Password - LyricsWeb"
          ogUrl="www.lyricsweb.com/auth/forgot-password"
        />
        {/* Page Info Header */}
        <div className="my-2">
          <PageInfoHeader
            text="Password Recovery"
            imageUrl={StaticImages.Banner.News}
          />
        </div>{" "}
        <div className="my-5">
          <Card
            className={`p-5`}
            style={{ backgroundColor: "var(--appColor5)" }}
          >
            <Card.Text as={"h1"} className="fw-bold text-uppercase">
              <div>
                Password{" "}
                <span className={`${appStyles.textColorHeading}`}>
                  Recovery
                </span>
              </div>
            </Card.Text>
            <Card.Subtitle as={"h6"}>
              Lost access? No worries. Simply provide your email address below,
              and we'll send you a secure link to reset your password and regain
              control of your account.
            </Card.Subtitle>
            <div className="my-5">
              <AppForm
                fields={forgotPassFormFields}
                validationSchema={forgotPassFormSchema}
                onSubmit={onSubmit}
                submitButtonProps={submitButtonProps}
                cancelButtonProps={cancelButtonProps}
              />
            </div>
          </Card>
        </div>
      </CommonLayout>
    </>
  );
}

export default ForgotPasswordPage;
