import React from "react";
import { Card, Image } from "react-bootstrap";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { generateDynamicRoute } from "../../utils/generateDynamicRoute";
import { ROUTES } from "../../routes/constants";
import { motion } from "framer-motion";
import { StaticImages } from "../../utils/constants";
import SpotifyPoweredLogo from "../Attributions/SpotifyPoweredLogo";
import { formatForUrl } from "../../utils/helpers";

const SearchItemCard = ({ item, type }) => {
  const navigate = useNavigate();

  const getTitle = () => item.name || "Unknown";

  const getSubtitle = () => {
    if (type === "track" || type === "album") {
      return (
        item.artists?.map((artist) => artist.name).join(", ") ||
        "Unknown Artist"
      );
    }
    return type === "artist" ? "Artist" : "";
  };

  const getImageUrl = () =>
    item.images?.[0]?.url || "https://via.placeholder.com/150";

  const handleClick = () => {
    let itemId = item.id;
    let itemName = item.name;
    if (type === "track") {
      navigate(
        `${generateDynamicRoute(ROUTES.COMMON.LYRICS_DETAILS, {
          songName: formatForUrl(itemName),
          isrcKey: itemId,
        })}`
      );
    } else if (type === "album") {
      navigate(
        `${generateDynamicRoute(ROUTES.COMMON.ALBUMS, {
          albumName: formatForUrl(itemName),
          albumId: itemId,
        })}`
      );
    } else if (type === "artist") {
      navigate(
        `${generateDynamicRoute(ROUTES.COMMON.ARTISTS, {
          artistName: formatForUrl(itemName),
          artistId: itemId,
        })}`
      );
    }
  };

  const cardVariants = {
    initial: { opacity: 0.75, scale: 1 },
    hover: { opacity: 1, scale: 1.05 },
    tap: { scale: 0.98 },
  };

  const renderContent = () => (
    <div className="d-flex flex-column">
      <Card.Text className="text-truncate fw-bold">{getTitle()}</Card.Text>
      {type !== "artist" && (
        <Card.Text className="text-muted text-truncate">
          {getSubtitle()}
        </Card.Text>
      )}
      <div>
        <SpotifyPoweredLogo logoSrc={StaticImages.Spotify.Logo} />
      </div>
    </div>
  );

  return (
    <motion.div
      variants={cardVariants}
      initial="initial"
      whileHover="hover"
      whileTap="tap"
      className="my-3"
    >
      <Card className="my-3" onClick={handleClick}>
        <Card.Body className="bg-appColor6">
          <Image
            src={getImageUrl()}
            alt={getTitle()}
            style={{ aspectRatio: "1 / 1", objectFit: "cover" }}
            rounded
            fluid
            className="mt-2"
          />
          <div className="my-2">{renderContent()}</div>
        </Card.Body>
      </Card>
    </motion.div>
  );
};

SearchItemCard.propTypes = {
  item: PropTypes.object.isRequired,
  type: PropTypes.oneOf(["artist", "album", "track"]).isRequired,
};

export default SearchItemCard;
