import React from "react";
import { Card } from "react-bootstrap";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import CommonLayout from "../../../layouts/CommonLayout";
import PageInfoHeader from "../../../components/PageInfoHeader/PageInfoHeader";
import {
  BannerNews,
  passwordRegex,
  StaticImages,
} from "../../../utils/constants";
import appStyles from "../../../App.module.css";
import AppForm from "../../../components/AppForm/AppForm";
import { ROUTES } from "../../../routes/constants";
import { getConfig } from "../../../services/apiUtils";
import { authController } from "../../../features/auth/authSlice";
import SEOManager from "../../../utils/SEOManager";

const resetPassFormFields = [
  {
    name: "newPassword",
    label: "New Password",
    type: "password",
    placeholder: "Enter New Password",
  },
  {
    name: "confirmPassword",
    label: "Confirm Password",
    type: "password",
    placeholder: "Confirm your Password",
  },
];

const resetPassFormSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required("New Password is Required")
    .matches(
      passwordRegex,
      "Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character"
    ),
  confirmPassword: yup
    .string()
    .required("Confirm Password is Required")
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

function ResetPasswordPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, error } = useSelector((state) => state.auth);

  const { resetToken } = useParams();

  const onSubmit = async (data, resetForm) => {
    try {
      const { confirmPassword, ...formData } = data;
      await dispatch(
        authController.resetPassword({
          payload: { ...formData, resetToken },
          config: getConfig(),
          resetForm,
          navigate,
        })
      ).unwrap();
    } catch (error) {
      console.error("Error Resetting Password:", error);
    } finally {
    }
  };

  const submitButtonProps = {
    btnTxt: "Reset Password",
    className: `${appStyles.appDefaultBtn} ${
      isLoading ? appStyles.appDefaultBtnDisabled : ""
    } w-50`,
    isLoading: isLoading,
    isLoadingText: "Loading...",
  };

  const cancelButtonProps = {
    btnTxt: "Login",
    className: `${appStyles.appDefaultBtnDark} w-50`,
    onClick: () => navigate(ROUTES.AUTH.LOGIN),
  };

  return (
    <>
      <CommonLayout>
        <SEOManager
          title="Reset Password - LyricsWeb"
          ogUrl={`www.lyricsweb.com/auth/reset-password/${resetToken}`}
        />
        {/* Page Info Header */}
        <div className="my-2">
          <PageInfoHeader
            text="Reset Password"
            imageUrl={StaticImages.Banner.News}
          />
        </div>{" "}
        <div className="my-5">
          <Card
            className={`p-5`}
            style={{ backgroundColor: "var(--appColor5)" }}
          >
            <Card.Text className="fw-bold text-uppercase fs-2">
              <div>
                Reset{" "}
                <span className={`${appStyles.textColorHeading}`}>
                  Password
                </span>
              </div>
            </Card.Text>
            <Card.Subtitle>
              Lost access? No worries. Simply provide your email address below,
              and we'll send you a secure link to reset your password and regain
              control of your account.
            </Card.Subtitle>
            <div className="my-5">
              <AppForm
                fields={resetPassFormFields}
                validationSchema={resetPassFormSchema}
                onSubmit={onSubmit}
                submitButtonProps={submitButtonProps}
                cancelButtonProps={cancelButtonProps}
              />
            </div>
          </Card>
        </div>
      </CommonLayout>
    </>
  );
}

export default ResetPasswordPage;
