import React from "react";
import { Card, Col, Image, Row, Badge } from "react-bootstrap";

const ArtistDetailsCard = ({ artist }) => {
  return (
    <Card className="bg-appColor7 shadow-sm" style={{ margin: "10px" }}>
      <Card.Body>
        <Row>
          <Col lg={4} md={4} sm={12} xs={12}>
            <Image
              src={artist?.images[0]?.url}
              alt={artist?.name}
              rounded
              fluid
            />
          </Col>
          <Col lg={8} md={8} sm={12} xs={12} className="my-2">
            <Card.Title as={"h3"} className="text-appColor1">
              {artist?.name}
            </Card.Title>
            <Card.Text>{artist?.description}</Card.Text>
            <Card.Text>
              {/* <strong>Genres: </strong> */}
              {artist?.genres?.map((genre, index) => (
                <Badge key={index} pill bg="appColor1" className="me-1">
                  {genre.toUpperCase()}
                </Badge>
              ))}
            </Card.Text>
            <Card.Text className="fw-bold">
              <small className="mx-2">
                Followers: {artist?.followers?.total?.toLocaleString()}
              </small>
              <small className="mx-2">Popularity: {artist?.popularity}</small>
            </Card.Text>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ArtistDetailsCard;
