import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { makeRequest } from "../../services/apiRequests";
import { handleApiError } from "../../services/apiUtils";

/* Feedback Section */
const submitUserFeedback = createAsyncThunk(
  "feedback/submitUserFeedback",
  async ({ payload, config, resetForm, navigate }, { rejectWithValue }) => {
    try {
      const { data, status } = await makeRequest(
        "POST",
        "/user/submit-user-feedback",
        payload,
        config
      );
      if (status === 201) {
        toast.success(data.message);
        resetForm();
        return data;
      } else {
        toast.error(data.message);
        return rejectWithValue(data.message);
      }
    } catch (error) {
      const errorMessage = handleApiError(error);
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

const feedbackSlice = createSlice({
  name: "feedback",
  initialState: {
    feedbackList: null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      /* Submit User Feedback */
      .addCase(submitUserFeedback.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(submitUserFeedback.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(submitUserFeedback.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const {} = feedbackSlice.actions;

export const feedbackController = {
  submitUserFeedback,
};

export default feedbackSlice.reducer;
