// src/utils/fileUtils.js

import axios from "axios";
import { getAuthConfig } from "../services/apiUtils";

const baseURL = `${process.env.REACT_APP_API_BASE_URL}`;

/**
 * Upload a file using FormData.
 *
 * @param {File} file - The file to upload.
 * @param {string} endpoint - The API endpoint for uploading the file.
 * @returns {Promise<string>} - The URL of the uploaded file.
 */
export const uploadFile = async (file, endpoint, fieldName) => {
  const formData = new FormData();
  formData.append(fieldName, file);

  try {
    const { data } = await axios.post(
      `${baseURL}${endpoint}`,
      formData,
      getAuthConfig("multipart/form-data")
    );
    return data.data.path; // Adjust based on your API response
  } catch (error) {
    console.error("Error Uploading File:", error);
    throw new Error("File upload failed");
  }
};
