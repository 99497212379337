import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { Image } from "react-bootstrap";
import styles from "./PageInfoHeader.module.css";
import { StaticImages } from "../../utils/constants";

const PageInfoHeader = ({
  text = "Default Header Text",
  imageUrl = StaticImages.Banner.News,
}) => {
  // Animation for text
  const textVariants = {
    hidden: { opacity: 0, x: -30 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 1, ease: "easeOut" },
    },
  };

  // Animation for image (fade-in with slight zoom-in)
  const imageVariants = {
    hidden: { opacity: 0, scale: 1.05 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 1.5, ease: "easeOut" },
    },
  };

  return (
    <div className={styles.pageInfoHeaderContainer}>
      <motion.div
        className={styles.pageInfoHeaderBgImgWrapper}
        initial="hidden"
        animate="visible"
        variants={imageVariants}
      >
        <Image
          src={imageUrl}
          alt={text}
          effect="blur"
          className={styles.pageInfoHeaderBgImg}
        />
      </motion.div>

      <div className={`${styles.overlay}`}>
        <motion.h1
          className={`text-appColor6`}
          initial="hidden"
          animate="visible"
          variants={textVariants}
        >
          {text}
        </motion.h1>
      </div>
    </div>
  );
};

PageInfoHeader.propTypes = {
  text: PropTypes.string,
  imageUrl: PropTypes.string,
};

export default PageInfoHeader;
