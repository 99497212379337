import { FaFacebookF, FaInstagram, FaXTwitter } from "react-icons/fa6";
import { NavLink } from "react-router-dom";

import React from "react";
import { StaticLinks } from "../../utils/constants";

function SocialLinks() {
  return (
    <div className="mx-1">
      <NavLink to={StaticLinks.App.LWFBUrl} target="_blank" className={`mx-2`}>
        <FaFacebookF color="white" />
      </NavLink>
      <NavLink
        to={StaticLinks.App.LWInstaUrl}
        target="_blank"
        className={`mx-2`}
      >
        <FaInstagram color="white" />
      </NavLink>
      <NavLink to="/" className={`mx-2`}>
        <FaXTwitter color="white" />
      </NavLink>
    </div>
  );
}

export default SocialLinks;
