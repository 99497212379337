import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Row,
} from "react-bootstrap";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { StaticImages, mainNavLinks } from "../../utils/constants";
import styles from "./MainNavigation.module.css";
import { ROUTES } from "../../routes/constants";
import {
  getAuthToken,
  isTokenValid,
  removeAuthToken,
} from "../../utils/authHelpers";
import { lyricsController } from "../../features/lyrics/lyricsSlice";
import { getConfig } from "../../services/apiUtils";
import debounce from "lodash/debounce";
import AppSpinner from "../Spinners/AppSpinner";
import { generateDynamicRoute } from "../../utils/generateDynamicRoute";
import { searchController } from "../../features/search/searchSlice";

import AuthDropdown from "./AuthDropdown";
import SearchResultItem from "./SearchResultItem";
import { FaSearch } from "react-icons/fa";
import SocialLinks from "./SocialLinks";
import SearchRadioGroup from "./SearchRadioGroup";
import SearchResultSection from "./SearchResultSection";
import { formatForUrl } from "../../utils/helpers";

function MainNavigation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { artistInitial } = useParams();

  const { lyricsList, isLoading, isSearching, error } = useSelector(
    (state) => state.lyrics
  );

  const searchResultsRef = useRef(null);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchType, setSearchType] = useState("all");
  const [placeholderText, setPlaceholderText] = useState(
    "Search your query..."
  );

  const debouncedSearch = useCallback(
    debounce(async (query) => {
      if (query.trim().length > 0) {
        try {
          if (searchType === "all") {
            const payload = { query };
            let response = await dispatch(
              searchController.getSearchResultsQuery({
                payload,
                config: getConfig(),
              })
            ).unwrap();
            let searchAllArray = Object.entries(response);

            setSearchResults(response);
          } else {
            // Handle individual search types (track, artist, or album)
            const payload = {
              type: searchType,
              query,
            };
            const { data, status } = await dispatch(
              lyricsController.getSearchResult({
                payload,
                config: getConfig(),
                navigate,
              })
            ).unwrap();

            if (status === 200) {
              const filteredData =
                searchType === "track"
                  ? data?.tracks?.items || []
                  : searchType === "artist"
                  ? data?.artists?.items || []
                  : searchType === "album"
                  ? data?.albums?.items || []
                  : [];
              setSearchResults(filteredData);
            } else {
              setSearchResults([]);
            }
          }
        } catch (error) {
          console.log("Error fetching search results:", error);
          setSearchResults([]);
        }
      } else {
        setSearchResults([]);
      }
    }, 700),
    [dispatch, searchType, navigate]
  );

  useEffect(() => {
    debouncedSearch(searchQuery);
  }, [debouncedSearch, searchQuery]);

  useEffect(() => {
    const authToken = getAuthToken();
    if (authToken && isTokenValid(authToken)) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [navigate]);

  useEffect(() => {
    // Update placeholder based on searchType
    switch (searchType) {
      case "track":
        setPlaceholderText("Search for Songs");
        break;
      case "artist":
        setPlaceholderText("Search for Artists");
        break;
      case "album":
        setPlaceholderText("Search for Albums");
        break;
      default:
        setPlaceholderText("Search your query...");
    }
  }, [searchType]);

  const handleLogout = () => {
    removeAuthToken();
    setIsAuthenticated(false);
    window.location.href = ROUTES.AUTH.LOGIN;
    navigate(ROUTES.AUTH.LOGIN, { replace: true });
  };

  const handleDropdownItemClick = (path) => {
    if (path === "/auth/logout") {
      handleLogout();
    } else {
      navigate(path);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchTypeChange = (id) => {
    setSearchType(id);
    setSearchQuery("");
    setSearchResults([]);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchResultsRef.current &&
        !searchResultsRef.current.contains(event.target)
      ) {
        setSearchResults([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleNavigateToSearchPage = () => {
    if (searchQuery.trim().length > 0) {
      navigate(
        `${generateDynamicRoute(ROUTES.COMMON.SEARCH_RESULT, {
          searchQuery: formatForUrl(searchQuery),
        })}`
      );
      setSearchResults([]);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleNavigateToSearchPage();
    }
  };

  const radioOptions = [
    { id: "all", label: "All" },
    { id: "track", label: "In Lyrics" },
    { id: "artist", label: "By Artist" },
    { id: "album", label: "By Album" },
  ];

  return (
    <div>
      <Container fluid className={`${styles.AppHeader} m-0 p-0`}>
        <div className={styles.AppNavigation}>
          <NavLink to={ROUTES.COMMON.HOME}>
            <Image
              src={StaticImages.App.AppLogoLyrics}
              height={"50px"}
              width={"auto"}
              alt="App Logo"
              className="mx-2 my-2"
            />
          </NavLink>
          <div className={`${styles.navContainer} mx-1`}>
            {mainNavLinks.map((link, index) => (
              <NavLink
                key={index}
                to={generateDynamicRoute(ROUTES.COMMON.ARTISTS, {
                  artistId: link.label,
                })}
                className="text-decoration-none"
              >
                <div
                  className={
                    artistInitial?.toUpperCase() === link.label
                      ? `${styles.AppNavLinkArtistActive}`
                      : `${styles.AppNavLinkArtist}`
                  }
                >
                  {link.label}
                </div>
              </NavLink>
            ))}
          </div>
        </div>
        <div className={`${styles.AppSearchBar} p-2`}>
          <Row className="align-items-center m-0 p-0">
            <Col
              lg={2}
              md={2}
              sm={12}
              xs={12}
              className="d-flex fs-6 justify-content-center justify-content-md-start my-2 my-md-0 mobilenavcol1"
            >
              <SocialLinks />
            </Col>
            <Col
              lg={4}
              md={4}
              sm={12}
              xs={12}
              className="my-2 my-md-0 order-3 order-md-2"
            >
              <div>
                <InputGroup>
                  <Form.Control
                    placeholder={placeholderText}
                    aria-label={placeholderText}
                    aria-describedby="basic-addon-search-lyrics"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    onKeyPress={handleKeyPress}
                  />
                  <Button
                    variant="appColor1"
                    id="basic-addon-search-lyrics"
                    onClick={handleNavigateToSearchPage}
                    disabled={isLoading}
                  >
                    <FaSearch />
                  </Button>
                </InputGroup>
              </div>
              {searchQuery.length > 0 && (
                <div className={styles.searchResults} ref={searchResultsRef}>
                  {isSearching ? (
                    <div
                      className="d-flex justify-content-center align-items-center my-2"
                      style={{ minHeight: "50vh" }}
                    >
                      <AppSpinner size={40} />
                    </div>
                  ) : Array.isArray(searchResults) &&
                    searchResults.length > 0 ? (
                    // Render results when searchResults is an array
                    searchResults.map((result) => (
                      <SearchResultItem
                        key={result?.id}
                        result={result}
                        searchType={searchType}
                        setSearchResults={setSearchResults}
                        dispatch={dispatch}
                      />
                    ))
                  ) : (typeof searchResults === "object" &&
                      searchResults !== null) ||
                    searchResults?.artists ||
                    searchResults?.albums ||
                    searchResults?.tracks ? (
                    <div className="d-flex flex-column">
                      <SearchResultSection
                        title="Artists"
                        items={searchResults?.artists}
                        searchType={searchType}
                        setSearchResults={setSearchResults}
                        dispatch={dispatch}
                        routeing={"/artist"}
                      />
                      <SearchResultSection
                        title="Albums"
                        items={searchResults?.albums}
                        searchType={searchType}
                        setSearchResults={setSearchResults}
                        dispatch={dispatch}
                        routeing={"/album"}
                      />
                      <SearchResultSection
                        title="Songs"
                        items={searchResults?.tracks}
                        searchType={searchType}
                        setSearchResults={setSearchResults}
                        dispatch={dispatch}
                        routeing={"/lyrics"}
                      />
                    </div>
                  ) : (
                    // No results found
                    <div className={styles.searchResultItem}>
                      <div className="d-flex justify-content-center align-items-center">
                        <span
                          className={`${styles.searchResultItemTitle} text-appColor1`}
                        >
                          No Song / Artist / Album Found
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Col>
            <Col
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={`d-flex justify-content-center justify-content-md-end my-2 my-md-0 order-2 order-md-3`}
            >
              <SearchRadioGroup
                options={radioOptions}
                selectedOption={searchType}
                onChange={handleSearchTypeChange}
              />
              <AuthDropdown
                isAuthenticated={isAuthenticated}
                handleDropdownItemClick={handleDropdownItemClick}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default MainNavigation;
