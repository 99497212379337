import React, { useEffect } from "react";
import PropTypes from "prop-types";

const GoogleAd = ({
  client,
  slot,
  format = "auto",
  fullWidthResponsive = true,
  style = {},
  className = "",
}) => {
  useEffect(() => {
    // Ensure the AdSense script is loaded only once
    if (window.adsbygoogle && typeof window.adsbygoogle.push === "function") {
      window.adsbygoogle.push({});
    } else {
      const script = document.createElement("script");
      script.src =
        "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
      script.async = true;
      script.onload = () => {
        window.adsbygoogle = window.adsbygoogle || [];
        window.adsbygoogle.push({});
      };
      document.head.appendChild(script);
    }
  }, []);

  return (
    <ins
      className={`my-2 adsbygoogle ${className}`}
      style={{ display: "block", ...style }}
      data-ad-client={client}
      data-ad-slot={slot}
      data-ad-format={format}
      data-full-width-responsive={fullWidthResponsive.toString()}
    ></ins>
  );
};

GoogleAd.propTypes = {
  client: PropTypes.string.isRequired,
  slot: PropTypes.string.isRequired,
  format: PropTypes.string,
  fullWidthResponsive: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default GoogleAd;
