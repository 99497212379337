import React, { useCallback, useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import CommonLayout from "../../../layouts/CommonLayout";
import PageInfoHeader from "../../../components/PageInfoHeader/PageInfoHeader";
import { StaticImages } from "../../../utils/constants";
import appStyles from "../../../App.module.css";
import { ROUTES } from "../../../routes/constants";
import { getAuthConfig } from "../../../services/apiUtils";
import AppFormUpdate from "../../../components/AppForm/AppFormUpdate";
import { authController } from "../../../features/auth/authSlice";
import AppSpinner from "../../../components/Spinners/AppSpinner";
import SEOManager from "../../../utils/SEOManager";
import { uploadFile } from "../../../utils/fileUtils";

const formFields = [
  {
    name: "firstName",
    label: "First Name",
    type: "text",
    placeholder: "Enter your first name",
  },
  {
    name: "lastName",
    label: "Last Name",
    type: "text",
    placeholder: "Enter your last name",
  },
  {
    name: "mobile",
    label: "Mobile",
    type: "text",
    placeholder: "Enter your mobile number",
  },
];

const validationSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  mobile: yup.string().required("Mobile is required"),
});

function ProfilePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, error } = useSelector((state) => state.auth);

  const [userProfile, setUserProfile] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [avatarFile, setAvatarFile] = useState(null);

  const fetchUserProfile = useCallback(async () => {
    try {
      const { status, data } = await dispatch(
        authController.getUserProfile({ config: getAuthConfig(), navigate })
      ).unwrap();
      if (status === 200) {
        setUserProfile(data);
      }
    } catch (error) {
      console.error("Error Fetching User Profile:", error);
    }
  }, [dispatch, navigate]);

  const onSubmit = async (payload, resetForm) => {
    setIsUpdating(true);
    try {
      let avatarUrl = userProfile?.avatar || ""; // Default to existing avatar URL

      if (avatarFile) {
        avatarUrl = await uploadFile(
          avatarFile,
          `/user/upload-profile-picture`,
          "image"
        );
      }

      const { status, data } = await dispatch(
        authController.editUserProfile({
          payload: { ...payload, avatar: avatarUrl },
          config: getAuthConfig(),
        })
      ).unwrap();

      if (status === 200) {
        setUserProfile(data);
      }
      setIsUpdating(false);
    } catch (error) {
      console.error("Error Updating Profile:", error);
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, [fetchUserProfile]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setAvatarFile(file);
  };

  const submitButtonProps = {
    btnTxt: "Update Profile",
    className: `${appStyles.appDefaultBtn} ${
      isLoading || isUpdating ? appStyles.appDefaultBtnDisabled : ""
    } w-50`,
    isLoading: isLoading || isUpdating,
    isLoadingText: "Loading...",
  };

  const cancelButtonProps = {
    btnTxt: "Home",
    className: `${appStyles.appDefaultBtnDark} w-50`,
    onClick: () => navigate(ROUTES.COMMON.HOME),
  };

  return (
    <CommonLayout>
      <SEOManager
        title="Profile - LyricsWeb"
        ogUrl="www.lyricsweb.com/auth/profile"
      />
      <div className="my-2">
        <PageInfoHeader text="Profile" imageUrl={StaticImages.Banner.News} />
      </div>
      <div className="my-5">
        <Card className={`p-5`} style={{ backgroundColor: "var(--appColor5)" }}>
          <div
            className="my-5"
            style={{
              height: "40vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {(isLoading || isUpdating) && <AppSpinner size={60} />}
            {!isLoading && !isUpdating && userProfile && (
              <div className="w-100">
                <Form.Group controlId="formFile" className="mb-3 fw-bold">
                  <Form.Label>Upload Avatar</Form.Label>
                  <Form.Control type="file" onChange={handleFileChange} />
                </Form.Group>

                <AppFormUpdate
                  fields={formFields}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                  submitButtonProps={submitButtonProps}
                  cancelButtonProps={cancelButtonProps}
                  initialValues={{
                    firstName: userProfile.firstName || "",
                    lastName: userProfile.lastName || "",
                    mobile: userProfile.mobile || "",
                  }}
                  showSubmitButton={true}
                  showCancelButton={true}
                />
              </div>
            )}
          </div>
        </Card>
      </div>
    </CommonLayout>
  );
}

export default ProfilePage;
