import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { makeRequest } from "../../services/apiRequests";
import { handleApiError } from "../../services/apiUtils";

/* Comment Section */
const getUserCommentsList = createAsyncThunk(
  "comment/getUserCommentsList",
  async ({ payload, config }, { rejectWithValue }) => {
    try {
      const { data, status } = await makeRequest(
        "GET",
        `/user/get-user-comments-list`,
        payload,
        config
      );
      if (status === 200) {
        return {
          comments: data.data.comments,
          page: payload.page,
          totalComments: data.data.totalComments,
          totalPages: data.data.totalPages,
        };
      } else {
        toast.error(data.message);
        return rejectWithValue(data.message);
      }
    } catch (error) {
      const errorMessage = handleApiError(error);
      return rejectWithValue(errorMessage);
    }
  }
);

const addUserComment = createAsyncThunk(
  "comment/addUserComment",
  async ({ payload, config, resetForm, navigate }, { rejectWithValue }) => {
    try {
      const { data, status } = await makeRequest(
        "POST",
        "/user/add-comment",
        payload,
        config
      );
      if (status === 201) {
        toast.success(data.message);
        resetForm();
        return data.data;
      } else {
        toast.error(data.message);
        return rejectWithValue(data.message);
      }
    } catch (error) {
      const errorMessage = handleApiError(error);
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

const deleteUserComment = createAsyncThunk(
  "comment/deleteUserComment",
  async ({ payload, config }, { rejectWithValue }) => {
    try {
      const { data, status } = await makeRequest(
        "DELETE",
        `/user/delete-user-comment`,
        payload,
        config
      );
      if (status === 200) {
        toast.success(data.message);
        return { commentId: payload.commentId };
      } else {
        toast.error(data.message);
        return rejectWithValue(data.message);
      }
    } catch (error) {
      const errorMessage = handleApiError(error);
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

const commentSlice = createSlice({
  name: "comment",
  initialState: {
    commentList: [],
    isFetchingComment: false,
    isModifyingComment: false,
    error: null,
    currentPage: 0,
    totalPages: 1,
    totalComments: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserCommentsList.pending, (state) => {
        state.isFetchingComment = true;
        state.error = null;
      })
      .addCase(getUserCommentsList.fulfilled, (state, action) => {
        state.isFetchingComment = false;
        if (action.payload.page === 1) {
          state.commentList = action.payload.comments;
        } else {
          state.commentList = [
            ...state.commentList,
            ...action.payload.comments,
          ];
        }
        state.currentPage = action.payload.page;
        state.totalPages = action.payload.totalPages;
        state.totalComments = action.payload.totalComments;
      })
      .addCase(getUserCommentsList.rejected, (state, action) => {
        state.isFetchingComment = false;
        state.error = action.payload;
      })
      .addCase(addUserComment.pending, (state) => {
        state.isModifyingComment = true;
        state.error = null;
      })
      .addCase(addUserComment.fulfilled, (state, action) => {
        state.isModifyingComment = false;
        state.commentList = [action.payload, ...state.commentList];
        state.totalComments += 1; // Increment total comments count
      })
      .addCase(addUserComment.rejected, (state, action) => {
        state.isModifyingComment = false;
        state.error = action.payload;
      })
      .addCase(deleteUserComment.pending, (state) => {
        state.isModifyingComment = true;
        state.error = null;
      })
      .addCase(deleteUserComment.fulfilled, (state, action) => {
        state.isModifyingComment = false;
        state.commentList = state.commentList.filter(
          (comment) => comment._id !== action.payload.commentId
        );
        state.totalComments -= 1; // Decrement total comments count
      })
      .addCase(deleteUserComment.rejected, (state, action) => {
        state.isModifyingComment = false;
        state.error = action.payload;
      });
  },
});

export const {} = commentSlice.actions;

export const commentController = {
  getUserCommentsList,
  addUserComment,
  deleteUserComment,
};

export default commentSlice.reducer;
