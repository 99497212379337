import React from "react";
import { Card, Image } from "react-bootstrap";
import PropTypes from "prop-types";
import { format } from "date-fns";
import _ from "lodash";

import appStyles from "../../App.module.css";

function NewsDetailCard({
  imageUrl = "https://via.placeholder.com/150",
  title = "Default Title",
  description = "Default description",
  artistName = "Default Artist",
  publishedDate = new Date().toLocaleDateString(),
}) {
  const formattedDate = publishedDate
    ? format(new Date(publishedDate), "MMM d, yyyy")
    : "N/A";

  return (
    <div>
      <Card style={{ backgroundColor: "var(--appColor5)" }}>
        <Image
          src={imageUrl}
          alt={title}
          effect="blur"
          className="card-img-top"
          style={{ height: "40vh ", objectFit: "cover" }}
        />
        <Card.Body className="p-5">
          <Card.Title className="my-3">{title}</Card.Title>
          <div className="my-3 fw-bold">
            <span className={`${appStyles.textColorHeading} me-3`}>
              {artistName}
            </span>
            <span className={`${appStyles.textColorHeading} ms-3`}>
              {formattedDate}
            </span>
          </div>
          <div
            className={`my-3`}
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
        </Card.Body>
      </Card>
    </div>
  );
}

NewsDetailCard.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  artistName: PropTypes.string.isRequired,
  publishedDate: PropTypes.string.isRequired,
};

export default NewsDetailCard;
