import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { makeRequest } from "../../services/apiRequests";
import { handleApiError } from "../../services/apiUtils";

/* Album Section */
/* Async thunk to get albums list */
const getAlbumsList = createAsyncThunk(
  "album/getArtistList",
  async ({ payload, config }, { rejectWithValue }) => {
    try {
      const { data, status } = await makeRequest(
        "GET",
        "/admin/album/songs",
        payload,
        config
      );

      if (status === 200) {
        return data.data;
      } else {
        toast.error(data.message);
        return rejectWithValue(data.message);
      }
    } catch (error) {
      const errorMessage = handleApiError(error);
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

/* Async thunk to get albums */
const getAlbums = createAsyncThunk(
  "album/getArtistAlbums",
  async ({ payload, config }, { rejectWithValue }) => {
    try {
      const { data, status } = await makeRequest(
        "GET",
        `/user/artist-albums`,
        payload,
        config
      );

      if (status === 200) {
        return data.data;
      } else {
        toast.error(data.message);
        return rejectWithValue(data.message);
      }
    } catch (error) {
      const errorMessage = handleApiError(error);
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

/* Async thunk to get album details */
const getAlbumDetails = createAsyncThunk(
  "album/getAlbumDetails",
  async ({ payload, config }, { rejectWithValue }) => {
    try {
      const { data, status } = await makeRequest(
        "GET",
        `/user/albumDetails`,
        payload,
        config
      );

      if (status === 200) {
        return data.data;
      } else {
        toast.error(data.message);
        return rejectWithValue(data.message);
      }
    } catch (error) {
      const errorMessage = handleApiError(error);
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

const albumSlice = createSlice({
  name: "album",
  initialState: {
    albumList: null,
    album: null,
    albumDetails: null,
    isLoading: false,
    isAlbumLoading: false,
    error: null,
  },
  reducers: {
    resetAlbumList: (state) => {
      state.albumList = null;
    },
    resetAlbum: (state) => {
      state.album = null;
    },
    resetAlbumDetails: (state) => {
      state.albumDetails = null;
    },
  },
  extraReducers: (builder) => {
    builder
      /* Get Artist List */
      .addCase(getAlbumsList.pending, (state) => {
        state.isAlbumLoading = true;
        state.error = null;
      })
      .addCase(getAlbumsList.fulfilled, (state, action) => {
        state.isAlbumLoading = false;
        state.albumList = action.payload;
      })
      .addCase(getAlbumsList.rejected, (state, action) => {
        state.isAlbumLoading = false;
        state.error = action.payload;
      })
      /* Get Artist Albums */
      .addCase(getAlbums.pending, (state) => {
        state.isAlbumLoading = true;
        state.error = null;
      })
      .addCase(getAlbums.fulfilled, (state, action) => {
        state.isAlbumLoading = false;
        state.album = action.payload;
      })
      .addCase(getAlbums.rejected, (state, action) => {
        state.isAlbumLoading = false;
        state.error = action.payload;
      })
      /* Get Album Details */
      .addCase(getAlbumDetails.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAlbumDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.albumDetails = action.payload;
      })
      .addCase(getAlbumDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { resetAlbumList, resetAlbum, resetAlbumDetails } =
  albumSlice.actions;

export const albumController = {
  getAlbumsList,
  getAlbums,
  getAlbumDetails,
};

export default albumSlice.reducer;
