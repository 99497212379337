import React from "react";

import CommonLayout from "../../../layouts/CommonLayout";
import { StaticImages } from "../../../utils/constants";
import { Image } from "react-bootstrap";

function MaintenancePage() {
  return (
    <>
      <CommonLayout>
        {/* Page Info Header */}
        <div className="my-2">
          <div className="p-2 text-center">
            <div className="fw-bold text-appColor1 fs-5">
              Sorry for the inconvenience application is currently undergoing
              maintenance.
            </div>
            <Image
              src={StaticImages.App.AppMaintenance}
              height={"50%"}
              width={"50%"}
              alt="App Maintenance Image"
            />
          </div>
        </div>
      </CommonLayout>
    </>
  );
}

export default MaintenancePage;
