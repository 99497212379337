import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { makeRequest } from "../../services/apiRequests";
import { handleApiError } from "../../services/apiUtils";

/* Lyrics Section */
const getSearchResult = createAsyncThunk(
  "lyrics/getSearchResult",
  async ({ payload, config }, { rejectWithValue }) => {
    try {
      const { data, status } = await makeRequest(
        "POST",
        "/user/search",
        payload,
        config
      );

      if (status === 200) {
        // toast.success(data.message);
        // console.log(data.data);
        return data;
      } else {
        toast.error(data.message);
        return rejectWithValue(data.message);
      }
    } catch (error) {
      const errorMessage = handleApiError(error);
      //   toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

const getTrackLyrics = createAsyncThunk(
  "lyrics/getTrackLyrics",
  async ({ payload, config, resetForm, navigate }, { rejectWithValue }) => {
    try {
      const { data, status } = await makeRequest(
        "POST",
        "/admin/get-admin-lyrics",
        payload,
        config
      );
      if (status === 200) {
        // toast.success(data.message);
        return data.data;
      } else {
        // toast.error(data.message);
        return rejectWithValue(data.message);
      }
    } catch (error) {
      const errorMessage = handleApiError(error);
      //   toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

const getTopCharts = createAsyncThunk(
  "lyrics/getTopCharts",
  async ({ config }, { rejectWithValue }) => {
    try {
      const { data, status } = await makeRequest(
        "GET",
        "/admin/top-chart-list",
        {},
        config
      );

      if (status === 200) {
        // toast.success(data.message);
        return data.data;
      } else {
        toast.error(data.message);
        return rejectWithValue(data.message);
      }
    } catch (error) {
      const errorMessage = handleApiError(error);
      //   toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

const getHotAlbums = createAsyncThunk(
  "lyrics/getHotAlbums",
  async ({ config }, { rejectWithValue }) => {
    try {
      const { data, status } = await makeRequest(
        "GET",
        "/admin/get-actual-hot-album",
        {},
        config
      );

      if (status === 200) {
        // toast.success(data.message);
        return data.data;
      } else {
        toast.error(data.message);
        return rejectWithValue(data.message);
      }
    } catch (error) {
      const errorMessage = handleApiError(error);
      //   toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

const lyricsSlice = createSlice({
  name: "lyrics",
  initialState: {
    lyricsList: null,
    specificLyrics: null,
    topCharts: null,
    hotAlbums: null,
    isSearching: false,
    isLoading: false,
    error: null,
  },
  reducers: {
    resetLyricsList: (state) => {
      state.lyricsList = null;
    },
    resetSpecificLyrics: (state) => {
      state.specificLyrics = null;
    },
  },
  extraReducers: (builder) => {
    builder
      /* Get Search Result */
      .addCase(getSearchResult.pending, (state) => {
        state.isSearching = true;
        state.error = null;
      })
      .addCase(getSearchResult.fulfilled, (state, action) => {
        state.isSearching = false;
        state.lyricsList = action.payload;
      })
      .addCase(getSearchResult.rejected, (state, action) => {
        state.isSearching = false;
        state.error = action.payload;
      })
      /* Get Lyrics Info */
      .addCase(getTrackLyrics.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getTrackLyrics.fulfilled, (state, action) => {
        state.isLoading = false;
        state.specificLyrics = action.payload;
      })
      .addCase(getTrackLyrics.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      /* Get Top Charts */
      .addCase(getTopCharts.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getTopCharts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.topCharts = action.payload;
      })
      .addCase(getTopCharts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      /* Get Hot Albums */
      .addCase(getHotAlbums.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getHotAlbums.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hotAlbums = action.payload;
      })
      .addCase(getHotAlbums.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { resetLyricsList, resetSpecificLyrics } = lyricsSlice.actions;

export const lyricsController = {
  getSearchResult,
  getTrackLyrics,
  getTopCharts,
  getHotAlbums,
};

export default lyricsSlice.reducer;
